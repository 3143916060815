import { Component } from '@angular/core';

/**
 * Chartsコンポネント
 */
@Component({
  selector: 'ngx-charts',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class ChartsComponent {
}
