import { NgModule } from '@angular/core';
import { ThemeModule } from '../../@theme/theme.module';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { ReportComponent } from './report.component';
import { ReportResultComponent } from './report-result/report-result.component';
import { ReportTableComponent } from './report-table/report-table.component';
import { ReportScreenCaptureComponent } from './report-screen-capture/report-screen-capture.component';

@NgModule({
  imports: [
    ThemeModule,
    LoadingBarModule.forRoot(),
    Ng2SmartTableModule,
  ],
  providers: [
  ],
  declarations: [
    ReportComponent,
    ReportResultComponent,
    ReportTableComponent,
    ReportScreenCaptureComponent,
  ],
})
export class ReportModule { }
