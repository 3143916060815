import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { OverviewModule } from './overview/overview.module';
import { AnalysisModule } from './analysis/analysis.module';
import { ReportModule } from './report/report.module';
import { PagesRoutingModule } from './pages-routing.module';
import { ThemeModule } from '../@theme/theme.module';
import { ScenarioConfigsModule } from './scenario-configs/scenario-configs.module';
import { UserActivitiesModule } from './user-activities/user-activities.module';
import { AccountConfigModule } from './account-config/account-config.module';
import { UserConfigsModule } from './user-configs/user-configs.module';
import { GroupConfigsModule } from './group-configs/group-configs.module';
import { GroupConfigModule } from './group-config/group-config.module';
import { EngineerConfigsModule } from './engineer-configs/engineer-configs.module';
import { EngineerGroupConfigModule } from './engineer-group-config/engineer-group-config.module';
import { ScenarioSummaryModule } from './scenario-summary/scenario-summary.module';

const PAGES_COMPONENTS = [
  PagesComponent,
];

@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    DashboardModule,
    OverviewModule,
    AnalysisModule,
    ReportModule,
    ScenarioConfigsModule,
    UserActivitiesModule,
    AccountConfigModule,
    EngineerConfigsModule,
    EngineerGroupConfigModule,
    UserConfigsModule,
    GroupConfigsModule,
    GroupConfigModule,
    ScenarioSummaryModule,
  ],
  declarations: [
    ...PAGES_COMPONENTS,
  ],
})
export class PagesModule {
}
