import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountConfigService } from '../../@core/data/account-config.service';

@Component({
  selector: 'ngx-group-configs',
  templateUrl: './account-config.component.html',
  styleUrls: ['./account-config.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class AccountConfigComponent implements OnInit {
  isLoading: boolean = true; // ページローディングフラグ
  loadingConfig: any = {}; // ローディング処理設定
  iconUrl: string;// 表示するアイコンURL
  // アカウント情報
  accountConfig: any = {
    name: '',
    email: '',
    group: [],
  };

  isSubmitted = false; // モーダルのSubmitボタン押下のフラグ
  modalMessage: string = ''; // モーダルに表示するメッセージ
  addAccountData = new FormData(); // モーダルの登録・更新時に送信するフォームデータ

  modalDisabled: boolean = false; // モーダルのオープンの制御フラグ
  submitDisabled: boolean = false; // モーダルの登録・更新ボタンの制御フラグ

  // パスワード変更フォーム
  changePasswordForm;

  userImageUrl: string;

  constructor(
    protected configService: AccountConfigService,
    public formBuilder: FormBuilder,
    config: NgbModalConfig,
    private modalService: NgbModal
  ) {
    // モーダルの設定
    config.backdrop = 'static';
    config.keyboard = false;
    config.windowClass = 'configs-modal';
  }

  ngOnInit() {
    // ローディングのエフェクト開始
    this.isLoading = true;
    // アカウント情報初期表示
    this.configService.getAccountConfig().subscribe((response) => {
      this.accountConfig = response;
      this.iconUrl = this.accountConfig.usericon;

      // ローディングのエフェクト終了
      this.isLoading = false;

      // get the user image URL from a service
      this.userImageUrl = 'assets/img/icons/avatar.jpg';
    });
  }

  /**
   * パスワード変更モーダル表示
   * @param editPassword
   */
  openEditPassword(changePassword): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.addAccountData = new FormData();

    // パスワード変更フォーム　Validation設定
    this.changePasswordForm = this.formBuilder.group({
      editPassword: ['', [Validators.required, Validators.maxLength(20)]],
    });

    this.modalService.open(changePassword);
    this.modalDisabled = false;
  }

  /**
   * パスワード変更モーダル
   */
  onEditPasswordSubmit(): boolean {
    this.submitDisabled = true;
    this.isSubmitted = true;

    // バリデーションのチェックに違反する場合は処理を中断する
    if (this.changePasswordForm.invalid) {
      this.modalMessage = '入力内容が正しくありません。';
      this.submitDisabled = false;
      return false;
    }

    // 入力内容をフォームデータに設定する
    this.addAccountData.append('editPassword', this.editPassword.value); // パスワード

    // フォームデータをサーバに送信する
    this.configService
      .editPasswordConfigs(this.addAccountData)
      .subscribe((response) => {
        if (response.errors) {
          this.modalMessage = response.errors.message;
        } else {
          // モーダルを閉じる
          this.modalService.dismissAll('Submit Success');
        }
        this.submitDisabled = false;
      });

    this.isSubmitted = false;
  }

  /**
   * getter パスワード編集フォーム
   */
  // パスワード
  get editPassword() {
    return this.changePasswordForm.get('editPassword');
  }
  /**
   *アイコン変更モーダル表示
   * @param editPassword
   */
  openEditIcon(changeIcon): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.modalService.open(changeIcon);
    this.modalDisabled = false;
  }
  // アイコン一覧
  imageUrls = [
    'assets/img/icons/rs_icon_1.png',
    'assets/img/icons/rs_icon_2.png',
    'assets/img/icons/rs_icon_3.png',
    'assets/img/icons/rs_icon_4.png',
    'assets/img/icons/rs_icon_5.png',
    'assets/img/icons/rs_icon_6.png',
    'assets/img/icons/rs_icon_7.png',
    'assets/img/icons/rs_icon_8.png',
    'assets/img/icons/rs_icon_9.png',
    'assets/img/icons/rs_icon_10.png',
    'assets/img/icons/rs_icon_11.png',
    'assets/img/icons/rs_icon_12.png',
    'assets/img/icons/rs_crab_1.png',
    'assets/img/icons/rs_dog_1.png',
    'assets/img/icons/rs_dolphin_1.png',
    'assets/img/icons/rs_elephant_1.png',
    'assets/img/icons/rs_fish_1.png',
    'assets/img/icons/rs_koara_1.png',
    'assets/img/icons/rs_man_1.png',
    'assets/img/icons/rs_man_2.png',
    'assets/img/icons/rs_man_3.png',
    'assets/img/icons/rs_man_4.png',
    'assets/img/icons/rs_man_5.png',
    'assets/img/icons/rs_mouse_1.png',
    'assets/img/icons/rs_panda_1.png',
    'assets/img/icons/rs_rabbit_1.png',
    'assets/img/icons/rs_seabird_1.png',
    'assets/img/icons/rs_sheep_1.png',
    'assets/img/icons/rs_ship_1.png',
    'assets/img/icons/rs_tiger_1.png',
    'assets/img/icons/rs_tropical_fish_1.png',
    'assets/img/icons/rs_turtle_1.png',
    'assets/img/icons/rs_wheel_1.png',
    'assets/img/icons/rs_worman_1.png',
    'assets/img/icons/rs_worman_2.png',
    'assets/img/icons/rs_worman_3.png',
  ];

  selectedImageUrl = null;

  // アイコンリンクを登録する
  selectImage(imageURL) :void{
    this.selectedImageUrl = imageURL;
    this.submitDisabled = true;
    this.isSubmitted = true;
    this.iconUrl=this.selectedImageUrl;
    // 入力内容をフォームデータに設定する
    const headerLogo = document.getElementById('usericon') as HTMLImageElement;
    this.addAccountData.append('editIcon', this.selectedImageUrl); // パスワード
    headerLogo.src = this.selectedImageUrl;
    // フォームデータをサーバに送信する
    // ローディングのエフェクト開始
    this.isLoading = true;    
    this.configService
      .editIconConfigs(this.addAccountData)
      .subscribe((response) => {
        if (response.errors) {
          this.modalMessage = response.errors.message;
        } else {
          // モーダルを閉じる
          this.modalService.dismissAll('Submit Success');
        }
        // ローディングのエフェクト終了
        this.isLoading = false;
        this.submitDisabled = false;
      });
  }
}
