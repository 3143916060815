import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment';

import { SearchService } from '../../@core/data/search.service';
import { ReportService } from '../../@core/data/report.service';
import { ConfigService } from '../../@core/data/config.service';

import { map } from 'rxjs/operators';

/**
 * レポート作成処理を行うコンポネント
 */
@Component({
    selector: 'ngx-report',
    styleUrls: ['./report.component.scss'],
    templateUrl: './report.component.html',
})
export class ReportComponent implements OnInit, OnDestroy {
    /** 検索条件 */
    condition: any = {};
    /** 全サイト名マップ */
    allSiteNameMap: any = {};
    /** 選択後対象サイト */
    selectedItem: string;
    /** レポート結果の表示フラグ */
    showResult: boolean = false;
    /** レポート検索用DropDown内容 */
    options: any[] = [];
    /** Mobileオプションチェック結果 */
    m_check: boolean = true;
    /** Desktopオプションチェック結果 */
    d_check: boolean = true;
    /** テーマ用Subscribeオブジェクト */
    themeSubscription: any;
    /** 検索サービス用Subscribeオブジェクト */
    searchServiceSubscription: any;
    /** レポートサービス用Subscribeオブジェクト */
    reportServiceSubscription: any;
    /** レポート結果用Subscribeオブジェクト */
    reportResultSubscription: any;
    /** Temp対象フィルタサイト */
    tempFilterSite: any = [];

    constructor(
        private theme: NbThemeService,
        private loadingBar: LoadingBarService,
        protected searchService: SearchService,
        protected reportService: ReportService,
        protected configService: ConfigService,
    ) {}

    /**
     * コンポネント初期処理
     */
    ngOnInit() {
        this.themeSubscription = this.theme.getJsTheme()
            .subscribe(config => {
                this.condition = this.searchService.getCondition();
                this.tempConditionSiteList(this);
                this.allSiteNameMap = this.searchService.getAllSiteNameMap();
                this.getOptionList();

                const vm = this;
                this.searchServiceSubscription = this.searchService.getSiteListConditionStatus().subscribe(res => {
                    vm.condition = vm.searchService.getCondition();
                    vm.getOptionList();
                });
            });
    }

    /**
     * コンポネント破棄時処理
     */
    ngOnDestroy() {
        this.searchService.clearSiteList();
        if (this.searchServiceSubscription) {
            this.searchServiceSubscription.unsubscribe();
        }
        if (this.reportServiceSubscription) {
            this.reportServiceSubscription.unsubscribe();
        }
        if (this.reportResultSubscription) {
            this.reportResultSubscription.unsubscribe();
        }
        if (this.tempFilterSite.length !== 0) {
            this.searchService.setSiteSelectCondition(this.tempFilterSite);
        }
        this.themeSubscription.unsubscribe();
    }

    /**
     * 対象監視サイトのレポート内容を作成する
     */
    createReport() {
        this.showResult = false;
        const targetUrl = this.allSiteNameMap[this.selectedItem];

        if (!this.m_check) {
            if (!this.d_check) {
                alert('[Mobile]及び[Desktop]のチェックボックスをチェックしてください！');
                return;
            }
        }

        if (!targetUrl) {
            alert('対象サイトを選択してください。');
            return;
        }

        this.searchService.setSiteSelectCondition([targetUrl]);
        this.loadingBar.start();
        const vm = this;
        this.reportResultSubscription = this.reportService.getShowResultStatus()
                                            .subscribe(showResult => {
                                                vm.showResult = true;
                                                vm.loadingBar.complete();
                                            });
        const range_from = moment(vm.condition.start).format('YYYY-MM-DDThh:mm:ssZ');
        const range_to = moment(vm.condition.end).format('YYYY-MM-DDThh:mm:ssZ');

        this.reportServiceSubscription = this.reportService.requestReport(targetUrl, range_from, range_to)
            .pipe(map(response => ({ ...response, m_check: vm.m_check, d_check: vm.d_check }))).subscribe({
                next(response) {
                    vm.reportService.setResponseStatus(response);
                },
            });
    }

    /**
     * レポート検索用DropDown内容が選択時の処理
     * @param value 対象監視サイト
     */
    onChange(value) {
        this.selectedItem = value;
    }

    /**
     * レポート検索用DropDown内容を作成
     */
    private getOptionList() {
        this.options = [];
        this.configService.getConfig().subscribe(response => {
          const dbMonitoringConfigList: any = response;
          this.options = this.options.concat(dbMonitoringConfigList.map(config => config.page_name));
      });
    }

    /**
     * 検索条件のサイトリストが存在した場合、サイトリストをTempへ保存。
     * @param vm 本クラスのインスタンス
     */
    private tempConditionSiteList(vm) {
        if (vm.condition.siteList.length > 0) {
            vm.tempFilterSite = this.condition.siteList;
            vm.searchService.clearSiteList();
        }
    }
}
