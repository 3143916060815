import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import screenshotFilesJson from 'assets/query/pages/dashboard/screen-capture/screenshot_files.json';

import { SearchService } from '../../../@core/data/search.service';
import { ReportService } from '../../../@core/data/report.service';
import { ElasticsearchService } from '../../../@core/data/elasticsearch.service';

/**
 * レポートのスクリーンキャプチャコンポネント
 */
@Component({
  selector: 'ngx-report-screen-capture',
  templateUrl: './report-screen-capture.component.html',
  styleUrls: ['./report-screen-capture.component.scss'],
})
export class ReportScreenCaptureComponent implements OnInit, OnDestroy {
  /** 対象サイトのストラテジー */
  @Input() strategy: string;
  /** テーマ用Subscribeオブジェクト */
  themeSubscription: any;
  /** Elasticsearch用Subscribeオブジェクト */
  elasticSubscription: any;
  /** 検索条件 */
  condition: any = {};
  /** 対象サイト一覧 */
  targetSites: any[] = [];
  /** 表示用対象サイト一覧 */
  displayTargetSites: any[] = [];
  /** 画像表示時系列 */
  seconds: any[] = [];
  /** 画像表示ステップ */
  step: number = 5;
  /** Thumbnails用スクリーンショットのサイズ */
  screenshotSize: string = 'small';
  /** 選択後スクリーンショット */
  selectedScreenshot: any;
  /** 画像表示ステップ設定 */
  stepOptions: any[] = [{ 'value': 1, 'description': '0.1秒間隔' },
                        { 'value': 5, 'description': '0.5秒間隔' },
                        { 'value': 10, 'description': '1秒間隔' }];

  constructor(
    private theme: NbThemeService,
    protected searchService: SearchService,
    protected reportService: ReportService,
    protected elasticsearchService: ElasticsearchService,
  ) {  }

  /**
   * コンポネント初期処理
   */
  ngOnInit(): void {
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      this.condition = this.searchService.getCondition();
      this.search(this.condition);
    });
  }

  /**
   * コンポーネント破棄時処理
   */
  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
    this.stopElasticRequest();
  }

  /**
   * Elasticsearchリクエストを中止
   */
  stopElasticRequest(): void {
    if (this.elasticSubscription) {
      this.elasticSubscription.unsubscribe();
    }
  }

  /**
   * スクリーンショットのレンダリング処理
   */
  showScreenshots(): void {
    let numScreenshots: number = 0;

    this.displayTargetSites.forEach((targetSite, targetSiteIndex) => {
      this.displayTargetSites[targetSiteIndex].displayedScreenshots = [];
      targetSite.screenshotSize = this.screenshotSize;
      targetSite.screenshots.forEach((screenshotUrl, screenshotIndex) => {
        if (screenshotIndex % this.step === 0) {
          this.displayTargetSites[targetSiteIndex].displayedScreenshots.push({'source': screenshotUrl.source});
        } else if (targetSite.numOfScreen - 1 === screenshotIndex) { // 最後のキャプチャが格納されるようにする。
          this.displayTargetSites[targetSiteIndex].displayedScreenshots.push({ 'source': screenshotUrl.source });
        }
      });
      this.displayTargetSites[targetSiteIndex].elapsedTime = (0.1 * (targetSite.numOfScreen - 1)).toFixed(1);
      numScreenshots = Math.max(numScreenshots, targetSite.displayedScreenshots.length);
      this.reportService.setShowResultStatus(true);
    });

    this.seconds = [];
    let elapsedTime;
    for (let i = 0; i < numScreenshots; i++) {
      elapsedTime = (i * 0.1 * this.step).toFixed(1);
      this.seconds.push({'value': `${elapsedTime}s`});
    }

    if (this.displayTargetSites.length <= 0) {
      this.reportService.setShowResultStatus(true);
    }
  }

  /**
   * サーバ側でクエリを検索する処理
   * @param condition 検索条件
   */
  private search(condition) {
    // ESにクエリを発行
    const comp = this;
    const replacePgNameFlag = false;
    const reportSearchFlag = true;
    let jsonConfig;
    jsonConfig = screenshotFilesJson;

    if (jsonConfig.length !== 0) {
      const query: any = jsonConfig[0] ;
      query.body.query.bool.must[0].range = {
        '@timestamp': {
          'gte': condition.start,
          'lte': condition.end,
          'format': 'epoch_millis',
        },
      };

      comp.elasticSubscription = comp.elasticsearchService.searchScreenshotsWithProxy
      (query, replacePgNameFlag, reportSearchFlag).subscribe(response => {
            comp.parseSearchData(comp, response);
          },
      );
    }
  }

  /**
   * 検索結果のデータを利用し、スクリーンショットを表示
   * @param resp 検索結果レスポンス
   */
  private parseSearchData(comp, resp: any) {
    // 結果がない場合は処理を行わない。
    if (!resp) {
      this.reportService.setShowResultStatus(true);
      return;
    }
    comp.targetSites = [];
    let pageTitle;
    let targetSite: any;
    let reportTagetSite: any;
    for (const [url, strategies] of Object.entries(resp)) {
      for (const [strategy, source] of Object.entries(strategies)) {
        let pageName: string = '';
        for (const [key, value] of Object.entries(source)) {
          if (key === 'additionals.pageName') {
            pageName = <string> value;
          }
        }
        pageTitle = `${pageName}(${strategy})`;
        targetSite = {
          'id': `${url}_${strategy}`,
          'url': url,
          'pageName': pageName,
          'pageTitle': pageTitle,
          'numOfScreen': source['presignedUrls'].length,
          'strategy': strategy,
          'screenshots': [],
        };
        source['presignedUrls'].forEach((screenshotUrl) => {
          targetSite.screenshots.push({'source': screenshotUrl});
        });
        if (comp.strategy === targetSite.strategy) {
            comp.targetSites.push(targetSite);
            if (comp.condition.siteList[0] === url) {
              reportTagetSite = targetSite;
            }
        }
      }
    }

    // サイト一覧を昇順でソートする。
    const sortedTargetList = JSON.parse(JSON.stringify(comp.targetSites));
    sortedTargetList.sort( function(target1, target2) {
        if ( target1.numOfScreen < target2.numOfScreen ) {
            return 1;
        } else if ( target1.numOfScreen > target2.numOfScreen ) {
            return -1;
        } else {
            return 0;
        }
    });

    if (reportTagetSite) {
      this.displayTargetSites.push(reportTagetSite);
      // トップサイトから4つを選択し表示対象にする
      const limit = 4;
      let index = 0;
      let count = 0;
      let isLimit: boolean = false;
      while (!isLimit) {
        if (comp.condition.siteList[0] !== sortedTargetList[index].url) {
          this.displayTargetSites.push(sortedTargetList[index]);
          count++;
        }
        index++;
        if (count === limit) {
          isLimit = true;
        } else if (index === sortedTargetList.length) {
          isLimit = true;
        }
      }
    }

    // this.displayTargetSites = sortedTargetList;
    this.showScreenshots();
  }
}
