import { FormBuilder } from '@angular/forms';
import { UserService } from '../../@core/data/users.service';
import { Component, OnInit, Injectable } from '@angular/core';
import { UserActivityService } from '../../@core/data/user-activity.service';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
  ja: {
    weekdays: ['月', '火', '水', '木', '金', '土', '日'],
    months: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
  },
};

@Injectable()
export class I18n {
  language = 'ja';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }
  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'ngx-user-activities',
  templateUrl: './user-activities.component.html',
  styleUrls: ['./user-activities.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})

export class UserActivitiesComponent implements OnInit {
  page = 1; // ページャーのページ番号
  pageSize = 10; // ページャーが１ページに表示するデータの数

  loadingConfig: any = {}; // ローディング処理設定
  isLoading: boolean = true; // ページローディングフラグ

  role_id; // ログインユーザーの権限
  userGroups: any = []; // 検索エリアのグループを格納するリスト
  userActivities: any = []; // シナリオ一覧のデータを格納するリスト
  activityTypes: any = []; // シナリオ一覧のデータを格納するリスト

  // 検索フォーム　Validation設定
  searchActivityForm = this.formBuilder.group({
    searchUserName: [''],
    searchGroupId: [''],
    searchActivityId: [''],
    searchActivityStart: [''],
    searchActivityEnd: [''],
  });

  constructor(
    protected activityService: UserActivityService,
    protected userService: UserService,
    public formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.userService.getUsers().subscribe((user) => {
      this.role_id = user.role_id;
      if (this.role_id > 2) {
        // ログインしているユーザーの権限が管理者以外の場合はダッシュボードに遷移する
        location.href = '/dashboard/synthetic#/pages/scenario-summary';
      } else {
        // ログインしているユーザーの権限が管理者の場合は画面の表示に必要な情報を取得する

        // 検索エリアのグループのリストを取得
        this.activityService.getUserGroups().subscribe((userGroups) => {
          this.userGroups = userGroups;
        });

        // ユーザーの行動履歴の初期表示
        this.searchUserActivities();
      }
    });
  }

  /**
   * ユーザーの行動履歴に表示するデータを取得する
   */
  searchUserActivities(): void {
    // ローディングのエフェクト開始
    this.isLoading = true;

    let startDate = this.searchActivityStart.value;
    if (startDate) {
      if (Object.prototype.toString.call(startDate) === '[object Object]') {
        startDate = startDate.year + '-' + startDate.month + '-' + startDate.day + ' 00:00:00';
      } else {
        // 日付が正しく取得できない場合、
        startDate = '';
      }
    }

    let endDate = this.searchActivityEnd.value;
    if (endDate) {
      if (Object.prototype.toString.call(endDate) === '[object Object]') {
        endDate = endDate.year + '-' + endDate.month + '-' + endDate.day + ' 23:59:59';
      } else {
        // 日付が正しく取得できない場合、
        endDate = '';
      }
    }

    this.activityService
      .getUserActivities(
        this.searchUserName.value,
        this.searchGroupId.value,
        this.searchActivityId.value,
        startDate,
        endDate,
      )
      .subscribe((response) => {
        this.userActivities = response.activityList;

        this.activityTypes = Object.keys(response.activityTypes).map(function(id) {
          return { id: id, type: response.activityTypes[id] };
        });

        // ローディングのエフェクト終了
        this.isLoading = false;
      });
  }

  /**
   * 検索エリア
   */
  // ユーザー
  get searchUserName() {
    return this.searchActivityForm.get('searchUserName');
  }

  // グループ
  get searchGroupId() {
    return this.searchActivityForm.get('searchGroupId');
  }

  // 操作種別
  get searchActivityId() {
    return this.searchActivityForm.get('searchActivityId');
  }

  // 操作開始日付
  get searchActivityStart() {
    return this.searchActivityForm.get('searchActivityStart');
  }

  // 操作終了日付
  get searchActivityEnd() {
    return this.searchActivityForm.get('searchActivityEnd');
  }
}
