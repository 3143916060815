import { NgModule } from '@angular/core';
import { ChartModule } from 'angular2-chartjs';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { LoadingModule } from 'ngx-loading';

import { ThemeModule } from '../../@theme/theme.module';
import { OverviewComponent } from './overview.component';
import { HeaderComponent } from '../../@theme/components/header/header.component';

@NgModule({
  imports: [
    ThemeModule,
    ChartModule,
    Ng2SmartTableModule,
    LoadingModule,
  ],
  providers: [
    HeaderComponent,
  ],
  declarations: [
    OverviewComponent,
  ],
})
export class OverviewModule { }
