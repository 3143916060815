import { Component, OnInit, OnDestroy } from '@angular/core';
import { ANIMATION_TYPES } from 'ngx-loading';
import { SearchService } from '../../@core/data/search.service';
import { LoadingService } from '../../@core/data/loading.service';

/**
 * ダッシュボードコンポーネント
 */
@Component({
  selector: 'ngx-analysis',
  styleUrls: ['./analysis.component.scss'],
  templateUrl: './analysis.component.html',
})
export class AnalysisComponent implements OnInit, OnDestroy {
  /** 検索条件 */
  condition: any;
  /** ページローディングフラグ */
  isLoading: boolean = true;
  /** 解析対象ページ選択フラグ */
  isPageSelected: boolean = false;
  /** ローディング処理設定 */
  loadingConfig: any = {};
  /** Subscribeオブジェクト一覧 */
  subscriptions: any = [];
  /** 検索用Subscribeオブジェクト */
  diviceDispChangeSubscription: any;
  /** 検索用Subscribeオブジェクト */
  isShowTimeDetail: boolean = false;


  constructor(
    protected searchService: SearchService,
    protected loadingService: LoadingService,
  ) {
      this.condition = this.searchService.getCondition();
      this.loadingService.reset();
      this.loadingConfig = {
        animationType: ANIMATION_TYPES.threeBounce,
      };
      this.isPageSelected = this.condition.siteList.length === 1;

      const vm = this;
      this.subscriptions.push(this.loadingService.getLoadConditionStatus().subscribe(res => {
        vm.isLoading = false;
      }));

      this.subscriptions.push(this.searchService.getConditionState().subscribe(res => {
        vm.isPageSelected = this.searchService.getCondition().siteList.length === 1; // 絞り込み条件が変わったら更新
        vm.isLoading = true;
        vm.loadingService.reset();
      }));
  }

  /**
   * コンポーネント初期処理
   */
  ngOnInit() {
    // this.condition = this.searchService.getCondition();
  }

  ngOnDestroy() {
    if (this.diviceDispChangeSubscription) {
      this.diviceDispChangeSubscription.unsubscribe();
    }

    this.subscriptions.forEach(function(value) {
      value.unsubscribe();
    });

    this.subscriptions = [];
  }

  isPC(): boolean {
    return this.searchService.isPC();
  }

  isMobile(): boolean {
    return this.searchService.isMobile();
  }

  // 解析対象ページが選択されているかどうか
  setPageSelected(): boolean {
    return this.searchService.getAllSiteList().length === 1;
  }

  // 解析対象ページが選択されているかどうか
  showTimeDetail() {
    this.isShowTimeDetail = true;
  }
}
