import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { ChartComponent } from 'angular2-chartjs';

@Component({
  selector: 'ngx-pageloadtime-chart',
  templateUrl: './pageloadtime-chart.component.html',
  styleUrls: ['./pageloadtime-chart.component.scss']
})
export class PageloadtimeChartComponent implements OnInit {

  @Input() pageLoadTime: any;

  // Chartコンポネントオオブジェクト
  @ViewChild(ChartComponent, { static: false }) chart: ChartComponent;

  option: Object;

  colors: any;

  themeSubscription: any;

  type = 'line';

  data: any

  options: any

  constructor(private theme: NbThemeService,) { }

  ngOnInit() {
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      this.colors = config.variables.temperature;
      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: { align: "end", display: true },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                return value + ' ms';
              }
            }
          }],
          xAxes: [{ ticks: { beginAtZero: true } }]
        }
      },
        this.data = {
          labels: Object.keys(this.pageLoadTime.value).reverse(),
          datasets: [
            {
              label: "Page Load Time",
              borderColor: "#2ec7fe",
              data: Object.values(this.pageLoadTime.value).reverse(),
              fill: false
            }
          ]
        };
    })
  }

}
