import { Component, OnInit } from '@angular/core';
import multiTimeScreenshotFilesJson from 'assets/query/pages/dashboard/screen-capture/mulittime_screenshot_files.json';
import { ScreenCaptureComponent } from './screen-capture.component';
import * as moment from 'moment';

/**
 * 複数時系列のスクリーンキャプチャのレンダリングを行う拡張コンポネント
 */
@Component({
  selector: 'ngx-screen-capture-multi',
  templateUrl: './screen-capture-multi.component.html',
  styleUrls: ['./screen-capture.component.scss'],
})
export class ScreenCaptureMultiComponent extends ScreenCaptureComponent implements OnInit {

  /** 複数時系列スクリーンショット表示時の表示時系列数 */
  multiScreenshotNum: number = 5;
  /** 対象時系列一覧 */
  targetSequs: any[] = [];
  /** 表示用対象時系列一覧 */
  displayTargetSequs: any[] = [];
  /** Thumbnails用スクリーンショットのサイズ */
  screenshotSize: string = 'xsmall';
  /**
   * コンポネント初期処理
   */
  ngOnInit(): void {
    // 検索条件が変わった際のイベントを登録
    this.searchSubscription = this.searchService.getConditionState().subscribe(res => {
      this.stopElasticRequest();
      this.condition = this.searchService.getCondition();
      this.multiSearch(this.condition);
    });

    // 表示ボタンがクリックされた際のイベントを登録
    this.screenSubscription = this.screencaptureFilterService.getDisplayTargetSitesState()
    .subscribe(displayTargetSites => {
      this.stopElasticRequest();
      this.displayTargetSites = displayTargetSites;
      this.showScreenshots();
    });

    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      this.condition = this.searchService.getCondition();
      // ヘッダ初期化されていなければ初期化を待つ。
      const allSiteNameMap = this.searchService.getAllSiteNameMap();
      if (Object.getOwnPropertyNames(allSiteNameMap).length !== 0) {
        this.multiSearch(this.condition);
      } else {
        setTimeout(() => {
          if (!this.loadingService.getLoadingStatus()) {
            this.loadingService.setLoadConditionStatus();
          }
        });
      }
    });
  }

  /**
   * サーバ側でクエリを検索する処理
   * @param condition 検索条件
   */
  private multiSearch(condition) {
    // ESにクエリを発行
    const comp = this;
    let jsonConfig;
    let query: any;
      jsonConfig = multiTimeScreenshotFilesJson;
      if (jsonConfig.length !== 0) {
        query = JSON.parse(JSON.stringify(jsonConfig[0])); // deep copy
        query.body.query.bool.must[0].range = {
          '@timestamp': {
            'gte': condition.start,
            'lte': condition.end,
            'format': 'epoch_millis',
          },
        };
        const strategyQuery = { 'term': { 'strategy': condition.device } };
        query.body.query.bool.must.push(strategyQuery);

        // urlを１つ絞り込みしたときのみを想定
        const urlQuery = { 'term': { 'url': condition.siteList[0] } };
        query.body.query.bool.must.push(urlQuery);

        // 期間を分割してそれぞれ集計する
        const interval = Math.floor((condition.end - condition.start) / this.multiScreenshotNum);
        for (let i = 1; i < this.multiScreenshotNum + 1; i++) {
          const startInterval = i * interval;
          const endInterval = (i - 1) * interval;
          // eval(
          //   "query.body.aggs.hits_aggs"+i+".filter.range = {'@timestamp':
          // {'gte': condition.end - startInterval,'lte': condition.end - endInterval,'format': 'epoch_millis',},};"
          // )
          query.body.aggs['hits_aggs' + i.toString()]['filter'] = JSON.parse(JSON.stringify(
            {'range': {'@timestamp':
            {'gte': condition.end - startInterval, 'lte': condition.end - endInterval, 'format': 'epoch_millis'}}}),
          );
        }
        /* 以下と同じ
        "query.body.aggs.hits_aggs1~5.filter.range" = {
          '@timestamp': {
            'gte': condition.end - interval*1~5,
            'lte': condition.end - interval*0~4,
            'format': 'epoch_millis',
          },
        };
        */
      }
    this.elasticSubscription = comp.elasticsearchService.searchScreenshotsWithProxy(query)
    .subscribe(response => {
      // 結果がない場合は処理を行わない。
      if (!response) {
        if (!comp.loadingService.getLoadingStatus()) {
          comp.loadingService.setLoadConditionStatus();
        }
        return;
      }
      comp.parseMultiSearchData(response);
      if (!comp.loadingService.getLoadingStatus()) {
        comp.loadingService.setLoadConditionStatus();
      }
    },
  );
  }

  /**
   * 検索結果のデータを利用し、スクリーンショットを表示
   * @param resp 検索結果レスポンス
   */
  private parseMultiSearchData(resp: any) {
    this.targetSequs = [];
    let targetSequ: any;
    let beforeTime: any = 0;

    for (const hits_aggsi of Object.keys(resp)) {
      const record = resp[hits_aggsi];
      const recordTime = record['@timestamp'];
        // 同じ時刻の取得結果は除外する。
        if ( recordTime === beforeTime) {
          continue;
        }
        beforeTime = recordTime;
        targetSequ = {
          'dateTime': moment(record['@timestamp']).format('M/D\n HH:00'),
          'numOfScreen': record.presignedUrls.length,
          'screenshots': [],
        };
        record.presignedUrls.forEach((screenshotUrl) => {
          targetSequ.screenshots.push({ 'source': screenshotUrl });
        });
        this.targetSequs.push(targetSequ);


    }

    this.screencaptureFilterService.setSortedTargetSitesList(this.targetSequs);
    this.screencaptureFilterService.setTargetSitesState(this.targetSequs);
  }

}
