import { NgModule } from '@angular/core';
import { NgxGalleryModule } from 'ngx-gallery';
import { ThemeModule } from '../../@theme/theme.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ScreenCaptureComponent } from './screen-capture.component';
import { ScreenCaptureMultiComponent } from './screen-capture-multi.component';
import { ScreenCaptureModalComponent } from './screen-capture-modal/screen-capture-modal.component';
import { ScreenCaptureFilterComponent } from './screen-capture-filter/screen-capture-filter.component';

const components = [
  ScreenCaptureComponent,
  ScreenCaptureMultiComponent,
  ScreenCaptureModalComponent,
  ScreenCaptureFilterComponent,
];

@NgModule({
  imports: [
    ThemeModule,
    NgxGalleryModule,
    AngularMultiSelectModule,
    DragScrollModule,
  ],
  exports: [...components],
  declarations: [...components],
  entryComponents: [
    ScreenCaptureModalComponent,
  ],
})
export class ScreenCaptureModule { }
