import { Component, OnInit, OnDestroy } from '@angular/core';

import { ReportService } from '../../../@core/data/report.service';

// 暫定対処
const PAGE_TYPE_NAME = {
    'top': 'トップページ',
    'search': '検索ページ',
    'list': '一覧ページ',
    'detail': '商品詳細情報ページ',
    'overview': '商品概要情報ページ',
    // 'eum': 'エンドユーザ体感ページ',
  };

/**
 * 監視対象サイトのレポートコンポネント
 */
@Component({
    selector: 'ngx-report-result',
    styleUrls: ['./report-result.component.scss'],
    templateUrl: './report-result.component.html',
})
export class ReportResultComponent implements OnInit, OnDestroy {
    /** ストラテジーチェックボックス値 */
    checkedStrategy: string;
    /** レポート内容 */
    displayMessages: any = [];
    /** レポート結果用Subscribeオブジェクト */
    reportResultSubscription: any;

    constructor(
        protected reportService: ReportService,
    ) { }

    /**
     * コンポネント初期処理
     */
    ngOnInit() {
        const vm = this;
        this.reportResultSubscription = this.reportService.getResponseStatus().subscribe(res => {
            const response: any = res;
            this.parseReportData(vm, response);
        });
    }

    /**
     * 検索結果のデータを利用し、レポートデータを作成
     * @param vm 本コンポネントのオブジェクト
     * @param response 検索結果のレスポンス
     */
    private parseReportData(vm, response: any) {
        const messages = response.messages;
        vm.checkedStrategy = '';
        vm.displayMessages = [];

        if (response.m_check) {
            if (!response.d_check) {
                vm.checkedStrategy = 'mobile';
            }
        } else {
            if (response.d_check) {
                vm.checkedStrategy = 'pc';
            }
        }
        let plan;
        let planDetail;
        let summary;
        let title = '';
        let strategy_type = '';
        for (const [strategy, reports] of Object.entries(messages)) {
            plan = [];
            planDetail = [];
            summary = [];
            if (strategy !== 'url') {
                for (const [report_type, reportList] of Object.entries(reports)) {
                    if (report_type === 'plan') {
                        this.parseFormat(reportList);
                        plan = reportList;
                    } else if (report_type === 'plan_detail') {
                        planDetail = this.skipMinusPlanDetails(reportList);
                    } else {
                        this.parseFormat(reportList);
                        summary = reportList;
                    }
                }

                title = '';
                strategy_type = '';
                if (strategy === 'pc') {
                    strategy_type = 'desktop';
                } else {
                    strategy_type = strategy;
                }

                if (vm.checkedStrategy) {
                    if (vm.checkedStrategy === strategy) {
                        title = this.titleMessage(strategy);
                        vm.displayMessages.push({
                            'title': title,
                            'plan': plan,
                            'plan_detail': planDetail,
                            'summary': summary,
                            'strategy': strategy_type,
                        });
                    }
                } else {
                    title = this.titleMessage(strategy);
                    vm.displayMessages.push({
                        'title': title,
                        'plan': plan,
                        'plan_detail': planDetail,
                        'summary': summary,
                        'strategy': strategy_type,
                    });
                }
            }
        }
    }

    /**
     * 対象のレポートフォーマットの編集処理
     * @param reports 対象レポート
     */
    private parseFormat(reports: any) {
        let re;
        let unit = 'ms';
        for (const report of reports) {
            if (report.args.length > 0) {
                for (const arg of report.args) {
                    if (arg.key === 'THRESHOLD') {
                        unit = '秒';
                    } else if (arg.key === 'PAGE_TYPE') {
                        unit = '';
                        // ページ種別名を日本語に変換する。
                        arg.value = PAGE_TYPE_NAME[arg.value];
                    } else if (arg.key === 'RANK') {
                        unit = '';
                    } else {
                        unit = 'ms';
                    }
                    re = new RegExp('(\{)+' + arg.key + '(\})+(ms|秒)*', 'g');
                    report.format = report.format.replace(re,
                        '<span style="color: red;font-weight: bold;">'
                        + arg.value + unit + '</span>');
                }
            }
        }
    }

    /**
     * 対象のレポートの詳細値がMinusの場合、スキップ処理を行う
     * @param reports 対象レポート
     */
    private skipMinusPlanDetails(reports: any): any {
        const planDetails = [];
        for (const detail of reports) {
            if (detail.improve >= 0) {
                planDetails.push(detail);
            } else {

            }
        }
        return planDetails;
    }

    /**
     * レポート内容のタイトルを作成
     * @param strategy ストラテジー
     */
    private titleMessage(strategy: any): any {
        let title = '';
        if (strategy === 'pc') {
            title = 'PCページ分析結果';
        } else {
            title = 'モバイルページ分析結果';
        }
        return title;
    }

    /**
     * コンポネント破棄時処理
     */
    ngOnDestroy() {
        this.reportResultSubscription.unsubscribe();
    }
}
