import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EngineerConfigService } from '../../@core/data/engineer-config.service';

@Component({
  selector: 'ngx-engineer-group-config',
  templateUrl: './engineer-group-config.component.html',
  styleUrls: ['./engineer-group-config.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class EngineerGroupConfigComponent implements OnInit {
  isLoading: boolean = true;              // ページローディングフラグ
  loadingConfig: any = {};                // ローディング処理設定

  plans: any = [];                        // 検索エリアのプランを格納するリスト
  engineerConfig: any = {};               // エンジニア情報
  engineerGroups: any = [];               // 担当グループ一覧のデータを格納するリスト
  page = 1;                               // ページャーのページ番号
  pageSize = 10;                          // ページャーが１ページに表示するデータの数

  isSubmitted = false;                    // モーダルのSubmitボタン押下のフラグ
  modalMessage: string = '';              // モーダルに表示するメッセージ
  searchConditionData = new FormData();   // モーダルの検索時に送信するフォームデータ

  modalDisabled: boolean = false;         // モーダルのオープンの制御フラグ
  submitDisabled: boolean = false;        // モーダルの登録・更新ボタンの制御フラグ

  searchGroups: any = [];                 // 担当グループ登録モーダルのグループを格納するリスト

  // グループ検索フォーム
  searchGroupForm;

  constructor(protected configService: EngineerConfigService,
              public formBuilder: FormBuilder,
              config: NgbModalConfig,
              private modalService: NgbModal) {
    // モーダルの設定
    config.backdrop = 'static';
    config.keyboard = false;
    config.windowClass = 'configs-modal';
  }

  ngOnInit() {
    // グループIDが無い場合はグループ設定の画面に遷移する
    if (!this.configService.engineerId) {
      location.href = '/dashboard/synthetic#/pages/engineer-configs';
    }

    // プラン情報を取得
    this.configService.getPlans().subscribe(response => {
      this.plans = response;
    });

    // エンジニア情報取得
    this.configService.getEngineerConfig(this.configService.engineerId).subscribe(response => {
      this.engineerConfig = response;
    });

    // 担当グループ一覧初期表示
    this.searchEngineerGroups();
  }

  /**
   * グループ一覧に表示するデータを取得する
   */
  searchEngineerGroups(): void {
    // ローディングのエフェクト開始
    this.isLoading = true;

    this.configService.getEngineerGroups(this.configService.engineerId).subscribe(response => {
      this.engineerGroups = response;

      // ローディングのエフェクト終了
      this.isLoading = false;
    });
  }

  /**
   * 担当グループ登録モーダル表示
   * @param addEngineerGroup
   */
  openAddEngineerGroup(addEngineerGroup): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.searchGroupForm = new FormData();

    // グループ検索フォーム　Validation設定
    this.searchGroupForm = this.formBuilder.group({
      searchGroupName: [''],
      searchPlanId: [''],
    });

    this.searchConditionData.append('userId', this.configService.engineerId);
    this.searchConditionData.append('searchgroupName', this.searchGroupName.value);
    this.searchConditionData.append('searchPlanId', this.searchPlanId.value);

    // グループ情報を取得
    this.configService.getNotEngineerGroups(this.searchConditionData).subscribe(response => {
      // 担当グループ一覧をリロード
      this.searchGroups = response;

      this.modalService.open(addEngineerGroup);
      this.modalDisabled = false;
    });
  }

  /**
   * 担当グループ登録モーダル 検索ボタン押下処理
   */
  onSearchGroup(): void {
    this.searchConditionData.append('userId', this.configService.engineerId);
    this.searchConditionData.append('searchGroupName', this.searchGroupName.value);
    this.searchConditionData.append('searchPlanId', this.searchPlanId.value);

    // グループ情報を取得
    this.configService.getNotEngineerGroups(this.searchConditionData).subscribe(response => {
      // 一覧をリロード
      this.searchGroups = response;
    });
  }

  /**
   * 担当グループ登録モーダル 追加ボタン押下処理
   * @param groupId
   */
  onAddEngineerGroup(groupId): void {
    this.configService.addEngineerGroup(this.configService.engineerId, groupId).subscribe(response => {
      // 一覧をリロード
      this.onSearchGroup();
      this.searchEngineerGroups();
    });
  }

  /**
   * 担当グループ削除
   * @param groupId
   */
  deleteEngineerGroup(groupId): void {
    this.configService.deleteEngineerGroup(this.configService.engineerId, groupId).subscribe(response => {
      // 担当グループ一覧をリロード
      this.searchEngineerGroups();
    });
  }

  /**
   * getter エンジニア担当グループ登録モーダル　検索エリア
   */
  // グループ名
  get searchGroupName(){
    return this.searchGroupForm.get('searchGroupName');
  }

  // プラン
  get searchPlanId(){
    return this.searchGroupForm.get('searchPlanId');
  }
}
