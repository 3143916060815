import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'ngx-avarage-box',
  templateUrl: './avarage-box.component.html',
  styleUrls: ['./avarage-box.component.scss'],
})

export class AvarageBoxComponent implements OnInit, OnChanges {

  @Input() avarage: {metricName: string, value: number};

  @Input() selected: any;

  avarageBoxClass: string;
  tooltipText: string;

  constructor() { }

  ngOnChanges () {
    if (this.avarage.metricName === this.selected) {
      this.avarageBoxClass = this.selected + '-reverse';
    } else {
      this.avarageBoxClass = this.avarage.metricName;
    }
  }

  ngOnInit() {
    this.metricValueRound();    
    this.setTooltipText();
  }

  /**
   * 指標のスコアを丸める。
   * 丸めの桁数は指標によって異なる。
   */
  private metricValueRound(): void {
    switch (this.avarage.metricName) {
      // case 'FID':
      //   this.avarage.value = Math.round(this.avarage.value * 10) / 10;
      //   break;//FIDを削除(RS-890)
      case 'CLS':
        this.avarage.value = Math.round(this.avarage.value * 100) / 100;
        break;
      case 'LCP':
      case 'FCP':
      case 'TTFB':
        this.avarage.value = Math.round(this.avarage.value);
        break;
      default:
        break;
    }
  }
  
  /**
   * メトリック名に基づいてツールヒントのテキストを定義する
  **/
  private setTooltipText(): void {
    switch (this.avarage.metricName) {
      case 'LPS':
        this.tooltipText = 'Lighthouse Performance Score (LPS) は、指標スコアの加重平均です。当然のことながら、指標の重み付けが大きいほど、全体的なパフォーマンス スコアに対する影響が大きくなります。指標スコアはレポートには表示されませんが、内部で計算されます。';
        break;
      case 'LCP':
        this.tooltipText = 'Largest Contentful Paint (LCP) は、認識される読み込み速度を測定するための安定した Core Web Vital 指標です。これは、ページ読み込みタイムラインの、ページのメイン コンテンツが読み込まれたと思われる時点を示します。LCP が高速であれば、ユーザーはページが有用であることを保証できます。';
        break;
      case 'FCP':
        this.tooltipText = 'First Contentful Paint (FCP) は、認識される読み込み速度を測定するためのユーザー中心の重要な指標です。これは、ページ読み込みタイムラインで、画面上のすべての項目がユーザーに表示される最初のポイントです。FCP が高速の場合、ユーザーは、何かが起きていることを確認できます。';
        break;
      case 'TTFB':
        this.tooltipText = 'Time to First Byte(TTFB)は、ラボとフィールドの両方で接続の設定時間とウェブサーバーの応答性を測定するための基本的な指標です。リソースのリクエストから、レスポンスの最初のバイトの到着を開始するまでの時間を測定します。これにより、ウェブサーバーがリクエストへの応答が遅すぎるタイミングを特定できます。';
        break;
      case 'SI':
        this.tooltipText = 'Speed Index (SI) は、ページの読み込み中にコンテンツが視覚的に表示される速度を測定します。Lighthouse では、まずブラウザでページが読み込まれている動画をキャプチャし、フレーム間の視覚的な進行状況を計算します。';
        break;
      case 'TBT':
        this.tooltipText = 'Total Blocking Time (TBT) 指標は、First Contentful Paint(FCP)後、入力の応答を妨げるのに十分な時間、メインスレッドがブロックされた合計時間を測定します。';
        break;
      case 'CLS':
        this.tooltipText = 'Cumulative Layout Shift (CLS) は、安定した Core Web Vitals 指標です。ユーザーが予期しないレイアウト シフトが発生する頻度を定量化できるため、これは視覚的な安定性を測定するためのユーザー中心の重要な指標です。CLS を低くすると、ページを快適なものにすることができます。';
        break;
      case 'TTI':
        this.tooltipText = 'Time to Interactive (TTI) は、ページが完全にインタラクティブになるまでにかかる時間を測定します。TTI スコアは、HTTP アーカイブのデータに基づいて、ページの TTI と実際の Web サイトの TTI を比較したものです。';
        break;
      case 'CTS':
        this.tooltipText = 'Contect Transfer Size (CTS)は、Web ページの読み込み時にネットワーク上で転送されるすべてのリソースの合計サイズを指します。 これには、ページのコンテンツをレンダリングするために必要な HTML、CSS、JavaScript、画像、フォント、その他のメディア ファイルのサイズが含まれます。';
        break;
      case 'CBS':
        this.tooltipText = 'Content Body Size (CBS) は、Web ページのメイン コンテンツのサイズを指します。 これには、ページのメイン コンテンツをレンダリングするために必要なすべての HTML、CSS、JavaScript、画像、およびその他のリソースが含まれます。コンテンツの本文サイズは、Web パフォーマンスの最適化において重要な要素です。コンテンツ サイズが大きくなると、特に低速のネットワーク接続やリソースが限られているデバイスではページの読み込み時間が遅くなる可能性があるためです。';
        break;
      case 'PRN':
        this.tooltipText = 'Page Response Number(PRN)は、Web ページがそのコンテンツをロードするために行ったネットワーク要求の数を指します。 このメトリクスは、画像、スクリプト、スタイルシート、その他のファイルの取得など、ページが外部リソースとどの程度効率的に対話するかについての洞察を提供します。 一般に、応答数が低いほど、読み込みプロセスがより合理化され、効率的であることを示します。';
        break;
      default:
        this.tooltipText = '';
        break;
    }
  }

}
