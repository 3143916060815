import { Component, Input, OnDestroy, Output, EventEmitter, OnInit } from '@angular/core';
import { LoadingService } from 'app/@core/data/loading.service';
import { SearchService } from 'app/@core/data/search.service';
import { ScenarioSummary } from 'app/models/scenario.model';

import { ScenarioDetailService } from 'app/@core/data/scenario-detail.service';

import { WebVitalsConst } from 'app/app-const';

@Component({
  selector: 'ngx-page-transition',
  templateUrl: './page-transition.component.html',
  styleUrls: ['./page-transition.component.scss'],
})
export class PageTransitionComponent implements OnInit, OnDestroy {

  @Output() setLoading = new EventEmitter<boolean>();

  // サイト名
  @Input() siteName = '';

  @Input() defaultPeriodDifferencial: any;

  @Input() measurementStandard: any;

  // ページローディングフラグ
  isLoading: boolean = true;

  // シナリオ情報
  scenarioSummaries: Array<ScenarioSummary> = [];

  // ローディング処理設定
  loadingConfig: any = {};

  // Subscribeオブジェクト一覧
  subscriptions: any = [];

  // 時系列推移グラフ用の設定オブジェクト
  options: Object;

  // 時系列推移グラフ用のデータオブジェクト
  data: Object;

  /** グラフの線、レブル等用色 */
  colors: any;

  metricsPerPage: Array<number> = [];

  metricsAvarage: Array<number> = [];

  siteNames: Array<string> = [];

  barColor: Array<string> = [];

  selectedMetric: string = 'LPS';

  selectedStrategy: string = 'モバイル';

  metricBoxClass: string;

  viewSites: Array<string> = [];

  constructor(
    protected searchService: SearchService,
    protected loadingService: LoadingService,
    private scenarioDetailService: ScenarioDetailService,
  ) {
    /*
    this.loadingConfig = {
      animationType: ANIMATION_TYPES.threeBounce,
    };
    */
  }

  ngOnInit() {
    this.subscriptions.push(this.scenarioDetailService.getSelectedWebsites().subscribe(data => {
      this.viewSites = data;
    }));

    this.scenarioDetailService.getScenarioSummaries.subscribe(scenarioSummaries => {
      this.scenarioSummaries = scenarioSummaries;

      this.siteNames = this.scenarioSummaries.map(site => {
        return site.siteName;
      });

      this.barColor = this.setColor();

      this.changeMetric(this.selectedMetric);
    });
/*
    this.isLoading = false;
    this.subscriptions.push(this.scenarioDetailService.getSummaries().subscribe(data => {
      this.scenarioSummaries = data;
    }));
*/
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (value) {
      value.unsubscribe();
    });
  }

  get devices() {
    return this.scenarioDetailService.getAllDevices();
  }

  changeChart(metric: string, strategyType: string) {
    if (strategyType === 'デスクトップ') {
      strategyType = 'desktop';
    } else {
      strategyType = 'mobile';
    }

    this.metricsAvarage = [];
    const datasets = [];

    const siteNames = [];

    const barColor = this.barColor.slice();

    this.scenarioSummaries.forEach(site => {
      const strategyData = site.strategies.find(strategy => strategy.strategy === strategyType);
      if (strategyData && strategyData.pageTransitions) {
        this.metricsPerPage = strategyData.pageTransitions.map(pageTransition => {
          return pageTransition.metrics[metric];
        });
      } else {
        this.metricsPerPage = [];
      }

      let metricAvarage = 0;
      if (this.metricsPerPage.length) {
        const metricValues = this.metricsPerPage.filter(value => value);
        const metricSum = metricValues.reduce(
          function(cumulative, value){
            return cumulative + value;
        });

        metricAvarage = this.metricValueRound(metric, (metricSum / metricValues.length));
      }

      siteNames.push(site.siteName);

      datasets.push(metricAvarage);
    });

    this.data = {
      labels: siteNames,
      datasets: [{
        backgroundColor: barColor,
        data : datasets,
      }],
    };
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      scaleShowLabels : false,
      scales: {
        xAxes: [{
          id: 'x-axis',
          ticks: this.setScalesXAxes(metric),
        }],
        yAxes: [
          {
            id: 'y-axis',
            ticks: {
              display: true,
              callback: function(value, index, values) {
                return ('      ' );
              },
            },
            gridLines: {
              tickMarkLength: 0,
            },
          },
        ],
      },
      legend: {
        display: false,
        position: 'right',
      },
      gridLines: {
        display: false,
      },
      annotation: {
        annotations: this.setChartAnnotation(metric),
        drawTime: 'beforeDatasetsDraw',
      },
      onClick: function (event, element) {
        const siteName = element[0]._model.label;
        const titles = document.querySelectorAll('.scenario__title');
        const target = Array.from(titles).find(elem => elem.textContent === siteName);
        target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      },
    };
  }

  setScalesXAxes(metricName: string) {
    let ScalesXAxes: number = 0;
    // FIDを削除(RS-890)
    // else if (metricName === 'FID') {
    //   ScalesXAxes = WebVitalsConst.FID_BORDER_BAD * 3;
    // }
    if (metricName === 'LPS') {
      ScalesXAxes = (this.measurementStandard !== null ? this.measurementStandard.LPS_BORDER_BAD : WebVitalsConst.LPS_BORDER_BAD) * 3;
    } else if (metricName === 'LCP') {
      ScalesXAxes = (this.measurementStandard !== null ? this.measurementStandard.LCP_BORDER_BAD : WebVitalsConst.LCP_BORDER_BAD) * 3;
    } else if (metricName === 'FCP') {
      ScalesXAxes = (this.measurementStandard !== null ? this.measurementStandard.FCP_BORDER_BAD : WebVitalsConst.FCP_BORDER_BAD) * 3;
    } else if (metricName === 'TTFB') {
      ScalesXAxes = (this.measurementStandard !== null ? this.measurementStandard.TTFB_BORDER_BAD : WebVitalsConst.TTFB_BORDER_BAD) * 3;
    } else if (metricName === 'SI') {
      ScalesXAxes = (this.measurementStandard !== null ? this.measurementStandard.SI_BORDER_BAD : WebVitalsConst.SI_BORDER_BAD) * 3;
    } else if (metricName === 'TBT') {
      ScalesXAxes = (this.measurementStandard !== null ? this.measurementStandard.TBT_BORDER_BAD : WebVitalsConst.TBT_BORDER_BAD) * 3;
    } else if (metricName === 'CLS') {
      ScalesXAxes = (this.measurementStandard !== null ? this.measurementStandard.CLS_BORDER_BAD : WebVitalsConst.CLS_BORDER_BAD) * 3;
    } else if (metricName === 'TTI') {
      ScalesXAxes = (this.measurementStandard !== null ? this.measurementStandard.TTI_BORDER_BAD : WebVitalsConst.TTI_BORDER_BAD) * 3;
    }
    return {
      min: 0,
      suggestedMax: ScalesXAxes,
    };
  }

  changeStrategy(strategy: string) {
    this.selectedStrategy = strategy;
    this.changeChart(this.selectedMetric, this.selectedStrategy);
  }

  changeMetric(metric: string) {
    const metricBoxs = document.querySelectorAll('.metric-box');
    metricBoxs.forEach(function(box) {
      box.classList.remove('reverse');
    });

    const selectedBox = document.querySelector('.background-' + metric);
    if (selectedBox) {
      selectedBox.classList.add('reverse');
    }

    this.selectedMetric = metric;
    this.changeChart(this.selectedMetric, this.selectedStrategy);
  }

  setColor() {
    const colorMaine = '#cbd8ef';
    const colorList1 = ['#fadea8', '#fff799', '#d6e2a4', '#c3e1c1', '#cbf7f3'];
    const colorList2 = ['#eddeff', '#f0d1e4', '#e4afbd', '#d6c1c5', '#e5e5e5'];
    const colorList = colorList1.concat(colorList2);  // Lintの文字数制限によりこのように対処
    const setColor = [];
    this.siteNames.forEach( function(_, index) {
      if (index === 0) {
        setColor.push(colorMaine);
      } else {
        setColor.push(colorList[(index - 1) % 10]);
      }
    });
    return setColor;
  }

  setChartAnnotation(metric: string): any {
    let borderGood: number = 0;
    let borderBad: number = 0;
    // else if (metric === 'FID') {
    //   borderGood = WebVitalsConst.FID_BORDER_GOOD;
    //   borderBad = WebVitalsConst.FID_BORDER_BAD;
    // }
    if (metric === 'LPS') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.LPS_BORDER_GOOD : WebVitalsConst.LPS_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.LPS_BORDER_BAD : WebVitalsConst.LPS_BORDER_BAD);
    } else if (metric === 'LCP') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.LCP_BORDER_GOOD : WebVitalsConst.LCP_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.LCP_BORDER_BAD : WebVitalsConst.LCP_BORDER_BAD);
    } else if (metric === 'CLS') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.CLS_BORDER_GOOD : WebVitalsConst.CLS_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.CLS_BORDER_BAD : WebVitalsConst.CLS_BORDER_BAD);
    } else if (metric === 'FCP') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.FCP_BORDER_GOOD : WebVitalsConst.FCP_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.FCP_BORDER_BAD : WebVitalsConst.FCP_BORDER_BAD);
    } else if (metric === 'TTFB') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.TTFB_BORDER_GOOD : WebVitalsConst.TTFB_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.TTFB_BORDER_BAD : WebVitalsConst.TTFB_BORDER_BAD);
    } else if (metric === 'SI') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.SI_BORDER_GOOD : WebVitalsConst.SI_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.SI_BORDER_BAD : WebVitalsConst.SI_BORDER_BAD);
    } else if (metric === 'TBT') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.TBT_BORDER_GOOD : WebVitalsConst.TBT_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.TBT_BORDER_BAD : WebVitalsConst.TBT_BORDER_BAD);
    } else if (metric === 'TTI') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.TTI_BORDER_GOOD : WebVitalsConst.TTI_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.TTI_BORDER_BAD : WebVitalsConst.TTI_BORDER_BAD);
    }

    if (!borderGood && !borderBad) {
      return [];
    }
    if (metric === 'LPS') {
      return [
        {
          type: 'box',
          id: 'y-axis-good',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMax: 100,
          yMin: borderGood,
          backgroundColor: 'rgba(0, 255, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(255, 0, 0, 0)',
        },
        {
          type: 'box',
          id: 'y-axis-safe',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: borderBad,
          yMax: borderGood,
          backgroundColor: 'rgba(255, 192, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(255, 192, 0, 0)',
        },
        {
          type: 'box',
          id: 'y-axis-bad',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: 0,
          yMax: borderBad,
          backgroundColor: 'rgba(255, 0, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(0, 176, 80, 0)',
        },
      ];
    } else {
      return [
        {
          type: 'box',
          id: 'y-axis-good',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: 0,
          yMax: borderGood,
          backgroundColor: 'rgba(0, 176, 80, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(0, 176, 80, 0)',
        },
        {
          type: 'box',
          id: 'y-axis-safe',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: borderGood,
          yMax: borderBad,
          backgroundColor: 'rgba(255, 192, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(255, 192, 0, 0)',
        },
        {
          type: 'box',
          id: 'y-axis-bad',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: borderBad,
          backgroundColor: 'rgba(255, 0, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(255, 0, 0, 0)',
        },
      ];
    }
  }

  get metrics() {
    const metricOrder = this.scenarioDetailService.getMetricSortOrder;
    // 指標の並び替え
    this.scenarioDetailService.getMetricTypes().sort(function (a, b) {
      if (metricOrder.indexOf(a) > metricOrder.indexOf(b)) {
        return 1;
      } else {
        return -1;
      }
    });
    return this.scenarioDetailService.getMetricTypes();
  }

  setViewSites(viewSites) {
    this.viewSites = viewSites;
  }

  onGetLoading(isLoading) {
    this.setLoading.emit(isLoading);
  }

  /**
   * 指標のスコアを丸める。
   * 丸めの桁数は指標によって異なる。
   */
  private metricValueRound(metricName, value): number {
    let roundValue: number;
    switch (metricName) {
      // FIDを削除(RS-890)
      // case 'FID':
      //   roundValue = Math.round(value * 10) / 10;
      //   break;
      case 'CLS':
        roundValue = Math.round(value * 100) / 100;
        break;
      case 'LCP':
      case 'FCP':
      case 'TTFB':
        roundValue = Math.round(value);
        break;
      default:
        break;
    }

    return roundValue;
  }
}
