import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ANIMATION_TYPES } from 'ngx-loading';
import { SearchService } from 'app/@core/data/search.service';

import { ScenarioSummary, TimelineMetric } from 'app/models/scenario.model';
import { Subscription } from 'rxjs';

import { ElasticsearchService } from 'app/@core/data/elasticsearch.service';
import { ScenarioDetailService } from 'app/@core/data/scenario-detail.service';
import { ScenarioConfigService } from 'app/@core/data/scenario-config.service';

import { LoadingService } from 'app/@core/data/loading.service';

import * as moment from 'moment';

@Component({
  selector: 'ngx-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, OnDestroy {
  private queryParams: any;
  private selectedScenario: any;

  condition: any = {};

  pageLoadTimeCondition:any={};

  inheritCondition: any = {};
  // ページローディングフラグ
  isLoading: boolean = true;
  // ローディング処理設定
  loadingConfig: any = {};

  selectedSiteName: string = '';

  subscriptions: Array<Subscription> = [];

  scenarioSummaries: Array<ScenarioSummary> = [];

  // 指標ごとの時系列データ
  timelineMetrics: Array<TimelineMetric> = [];

  // デフォルトの表示期間(過去7日間）
  defaultPeriodDifferencial = '1440';

  pageLoadTimedefaultPeriodDifferencial = '10080';

  measurementStandard: any = null;

  pageDetailData: Object = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    protected loadingService: LoadingService,
    protected searchService: SearchService,
    protected elasticsearchService: ElasticsearchService,
    private scenarioDetailService: ScenarioDetailService,
    protected configService: ScenarioConfigService,
  ) {
    this.loadingConfig = {
      animationType: ANIMATION_TYPES.threeBounce,
    };
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.queryParams = params;
        this.selectedScenario = parseInt(this.queryParams.selectedScenario, 10);
        this.defaultPeriodDifferencial = this.queryParams.selectedCondition;
        if (history.state.condition) {
          this.inheritCondition = history.state.condition;
          sessionStorage.setItem('condition', JSON.stringify(history.state.condition));
        } else {
          this.inheritCondition = JSON.parse(sessionStorage.getItem('condition'));
          if (this.inheritCondition === null) {
            this.inheritCondition = {
              start: moment().subtract(parseInt('4320', 10), 'minute').valueOf(),
              end: moment().valueOf(),
            };
          }
        }
        this.configService.getMeasurementStandard([this.selectedScenario]).subscribe(m_data => {
          if (m_data[0].measurement_standards)
            this.measurementStandard = JSON.parse(m_data[0].measurement_standards);
        });
        this.isLoading = true;
        this.setScenarioSummaries();
      },
    );

    this.scenarioDetailService.getScenarioSummaries.subscribe(scenarioSummaries => {
      if (scenarioSummaries.length) {
        this.selectedSiteName = scenarioSummaries[0].siteName;
        this.isLoading = false;
        this.scenarioSummaries = scenarioSummaries;
      }
    });
  }

  private setScenarioSummaries() {
    if (this.defaultPeriodDifferencial === 'custom') {
      this.condition = {
        start: this.inheritCondition.start,
        end: moment().valueOf(),
      };
      this.pageLoadTimeCondition= {
        start: this.inheritCondition.start,
        end: moment().valueOf(),
      };
    } else {
      this.condition = {
        start: moment().subtract(parseInt(this.defaultPeriodDifferencial, 10), 'minute').valueOf(),
        end: moment().valueOf(),
      };
      this.pageLoadTimeCondition = {
        start: moment().subtract(parseInt(this.pageLoadTimedefaultPeriodDifferencial, 10), 'minute').valueOf(),
        end: moment().valueOf(),
      };
    }

    this.scenarioDetailService.setScenarioDetails(this.condition, this.selectedScenario);
    this.scenarioDetailService.setTimelineMetrics(this.condition, this.selectedScenario);
    this.scenarioDetailService.setPageLoadTime(this.pageLoadTimeCondition,this.selectedScenario);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
    this.scenarioDetailService.initializeScenarioDetailPageData();
  }

  onGetLoading(loadingState) {
    this.isLoading = loadingState;
  }

  setpageDetailData(value: object) {
    this.pageDetailData=value;
  }
}
