import { Component, OnDestroy, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { ANIMATION_TYPES } from 'ngx-loading';
import { Router } from '@angular/router';
import { LoadingService } from 'app/@core/data/loading.service';
import { SearchService } from 'app/@core/data/search.service';
import { ElasticsearchService } from 'app/@core/data/elasticsearch.service';
import * as lodash from 'lodash';

import { ScenarioDetailService } from 'app/@core/data/scenario-detail.service';
import { ScenarioConfigService } from '../../../@core/data/scenario-config.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { WebVitalsConst } from '../../../app-const';
import { ScenarioSummary } from '../../../models/scenario.model';
import * as moment from 'moment';
import { DatepickerService } from 'app/@core/data/datepicker.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailService } from 'app/@core/data/mail.service';
import { UserService } from 'app/@core/data/users.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';

declare var $: any;
@Component({
  selector: 'ngx-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnDestroy, OnInit, AfterViewInit {
  // ページローディングフラグ
  isLoading: boolean = true;
  // ローディング処理設定
  loadingConfig: any = {};

  // ページャーのページ番号
  page = 1;
  // ページャーが１ページに表示するデータの数
  pageSize = 5;

  // Elasticsearch用Subscribeオブジェクト一覧
  elasticSubscriptions = [];

  // Subscribeオブジェクト一覧
  subscriptions: any = [];

  condition: any = this.searchService.getCondition();

  // シナリオサマリー
  scenarioSummaries: Array<ScenarioSummary> = [];
  resultscenarioSummaries: Array<ScenarioSummary> = [];

  // ng-dropdown-setting
  dropdownSettings: IDropdownSettings;

  // measurement-result
  filteredMeasurementResults: any = [];
  measurementResultDropdownList = [];
  measurementResultSelectedItems: any = [];

  // site-name
  siteNames: any = [];
  siteNameDropdownList = [];
  siteNameSelectedItems: any = [];

  // category
  categories: any = [];
  categorySelectedItems: any = [];
  categoryDropdownList = [];

  // scenario names
  scenarioNames: any = [];
  scenarioSelectedItems: any = [];
  scenarioDropdownList = [];

  // 測定対象となるCore Web Vitals
  // FIDを削除(RS-890)
  coreWebVitals = ['LPS', 'FCP', 'TTFB', 'LCP', 'CLS', 'TBT', 'SI'];

  measurementStandard: any = [];

  /* 期間指定用 */
  selectedCondition: string = '';
  showDatepicker: string = 'close_datepicker';
  showPeriod: string = '';

  isSubmitted = false; // モーダルのSubmitボタン押下のフラグ
  modalMessage: string = ''; // モーダルに表示するメッセージ
  addRequestData = new FormData(); // モーダルの登録・更新時に送信するフォームデータ

  modalDisabled: boolean = false; // モーダルのオープンの制御フラグ
  submitDisabled: boolean = false; // モーダルの登録・更新ボタンの制御フラグ

  consultButtonDisplay: boolean = false; // 相談ボタンの表示
  user_role_id: Number; // ログインユーザーの権限

  // リクエストフォーム
  RequestForm;

  // メッセージ送信
  NotificationForm: FormGroup;
  selectedScenarioId: Number;
  selectedScenarioSummary: any = [];

  // ログインユーザ情報
  accountConfig: any = {
    name: '',
    email: '',
  };

  selectedItem: any;
  popupVisible: boolean = false;
  popupcursorX: number = 0;
  popupcursorY: number = 0;
  scenarioConfigId: number = 0;

  constructor(
    private router: Router,
    protected searchService: SearchService,
    protected elasticsearchService: ElasticsearchService,
    protected loadingService: LoadingService,
    private scenarioDetailService: ScenarioDetailService,
    protected configService: ScenarioConfigService,
    private datepickerService: DatepickerService,
    protected mailService: MailService,
    protected userService: UserService,
    public formBuilder: FormBuilder,
    config: NgbModalConfig,
    private modalService: NgbModal,
  ) {
    this.loadingConfig = {
      animationType: ANIMATION_TYPES.threeBounce,
    };
    // モーダルの設定
    config.backdrop = 'static';
    config.keyboard = false;
    config.windowClass = 'configs-modal';
    this.NotificationForm = this.formBuilder.group({
      notiRequest: ['この画面の測定が悪いですけど、問題があると思います。打合せにしませんか。'], // Set the initial value
    });
  }

  ngOnInit() {
    this.datepickerService.setShowDatepicker(true);
    this.datepickerService.setDatepickerStatus();

    // Retrieve search conditions from sessionStorage
    const savedConditions = JSON.parse(sessionStorage.getItem('searchConditions'));
    if (savedConditions) {
      this.condition = savedConditions;

      this.displaySelectionPeriod({
        start: moment(this.condition.start),
        end: moment(this.condition.end),
      });
      this.selectedCondition = JSON.parse(sessionStorage.getItem('selectedCondition'));
      this.siteNameSelectedItems = this.condition.scenarioSite;
      this.categorySelectedItems = this.condition.scenarioCategory;
      this.scenarioSelectedItems = this.condition.scenarioName;
    }
    else {
      sessionStorage.clear(); // clear session for datetime using in detail page

      this.displaySelectionPeriod({
        start: moment().subtract(1, 'day'),
        end: moment(),
      });
      this.selectedCondition = '1440';
      sessionStorage.setItem('selectedCondition', JSON.stringify(this.selectedCondition));
      this.condition = {
        start: moment()
          .subtract(parseInt(this.selectedCondition, 10), 'minute')
          .valueOf(),
        end: moment().valueOf(),
      };
    }

    this.userService.getUsers().subscribe(user => {
      this.user_role_id = user.role_id;
    });
    this.setScenarioSummaries();

    // get current user groups and data

    this.scenarioDetailService.getScenarioSummaries.subscribe(
      (scenarioSummaries) => {
        this.isLoading = true;
        this.scenarioSummaries = lodash.cloneDeep(scenarioSummaries);
        let m_index = 0; // ドロップダウンのインデックス
        const tmpsite = []; // サイトのリストを作成
        const tmpcategory = []; // カテゴリーのリストを作成
        const tmpscenarioName = []; // シナリオ名のリストを作成
        const tmpresult = []; // 計測結果のリストを作成
        if (this.scenarioSummaries.length === 0) {
          this.isLoading = false;
        }
        // 初期化されていない場合、以下の処理は行わない
        if (this.scenarioSummaries.length !== 0) {
          this.isLoading = false;
          /* カードに表示するデータを設定していく */
          const scenarioIds = this.scenarioSummaries.map(function (i) {
            return i.scenarioConfigId;
          });
          this.configService.getMeasurementStandard(scenarioIds).subscribe(m_data => {
            this.measurementStandard = m_data;
          });
          this.scenarioSummaries.forEach((scenarioSummary, i) => {
            // get measurement value of groups
            const result = this.measurementStandard.filter((obj) => {
              return obj.id === scenarioSummary.scenarioConfigId;
            });
            let measurement_st = null;
            if (result.length > 0 && result[0].measurement_standards) {
              measurement_st = JSON.parse(result[0].measurement_standards);
            }
            // mobileとdesktopの2パターンでループ処理
            scenarioSummary.strategies.forEach((scenario) => {
              if (scenario.strategy === 'desktop') {
                this.scenarioSummaries[
                  i
                ].warningMetrics.isMeasurementSubjectDesktop = true;
              }
              if (scenario.strategy === 'mobile') {
                this.scenarioSummaries[
                  i
                ].warningMetrics.isMeasurementSubjectMobile = true;
              }
              if (scenario.pageTransitions !== undefined && scenario.pageTransitions.length !== 0) {
                // すべての測定値の中から、問題あり・やや問題ありの値だけを端末・バイタル値ごとに配列にする
                scenario.pageTransitions.forEach((pageTransition) => {
                  // 問題あり
                  this.addBadPageByVitalArray(
                    pageTransition.metrics,
                    this.scenarioSummaries[i].warningMetrics.badPage,
                    scenario.strategy,
                    'LCP',
                    (measurement_st !== null ? measurement_st.LCP_BORDER_BAD : WebVitalsConst.LCP_BORDER_BAD),
                  );
                  this.addBadPageByVitalArray(
                    pageTransition.metrics,
                    this.scenarioSummaries[i].warningMetrics.badPage,
                    scenario.strategy,
                    'FCP',
                    (measurement_st !== null ? measurement_st.FCP_BORDER_BAD : WebVitalsConst.FCP_BORDER_BAD),
                  );
                  this.addBadPageByVitalArray(
                    pageTransition.metrics,
                    this.scenarioSummaries[i].warningMetrics.badPage,
                    scenario.strategy,
                    'TTFB',
                    (measurement_st !== null ? measurement_st.TTFB_BORDER_BAD : WebVitalsConst.TTFB_BORDER_BAD),
                  );
                  this.addBadPageByVitalArray(
                    pageTransition.metrics,
                    this.scenarioSummaries[i].warningMetrics.badPage,
                    scenario.strategy,
                    'SI',
                    (measurement_st !== null ? measurement_st.SI_BORDER_BAD : WebVitalsConst.SI_BORDER_BAD),
                  );
                  this.addBadPageByVitalArray(
                    pageTransition.metrics,
                    this.scenarioSummaries[i].warningMetrics.badPage,
                    scenario.strategy,
                    'TBT',
                    (measurement_st !== null ? measurement_st.TBT_BORDER_BAD : WebVitalsConst.TBT_BORDER_BAD),
                  );
                  // this.addBadPageByVitalArray(
                  //   pageTransition.metrics,
                  //   this.scenarioSummaries[i].warningMetrics.badPage,
                  //   scenario.strategy,
                  //   'FID',
                  //   WebVitalsConst.FID_BORDER_BAD,
                  // );//FIDを削除(RS-890)
                  this.addBadPageByVitalArray(
                    pageTransition.metrics,
                    this.scenarioSummaries[i].warningMetrics.badPage,
                    scenario.strategy,
                    'CLS',
                    (measurement_st !== null ? measurement_st.CLS_BORDER_BAD : WebVitalsConst.CLS_BORDER_BAD),
                  );
                  // やや問題あり
                  this.addNoGoodAndNoBadPageByVitalArray(
                    pageTransition.metrics,
                    this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage,
                    scenario.strategy,
                    'LCP',
                    (measurement_st !== null ? measurement_st.LCP_BORDER_BAD : WebVitalsConst.LCP_BORDER_BAD),
                    (measurement_st !== null ? measurement_st.LCP_BORDER_GOOD : WebVitalsConst.LCP_BORDER_GOOD),
                  );
                  this.addNoGoodAndNoBadPageByVitalArray(
                    pageTransition.metrics,
                    this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage,
                    scenario.strategy,
                    'FCP',
                    (measurement_st !== null ? measurement_st.FCP_BORDER_BAD : WebVitalsConst.FCP_BORDER_BAD),
                    (measurement_st !== null ? measurement_st.FCP_BORDER_GOOD : WebVitalsConst.FCP_BORDER_GOOD),
                  );
                  this.addNoGoodAndNoBadPageByVitalArray(
                    pageTransition.metrics,
                    this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage,
                    scenario.strategy,
                    'TTFB',
                    (measurement_st !== null ? measurement_st.TTFB_BORDER_BAD : WebVitalsConst.TTFB_BORDER_BAD),
                    (measurement_st !== null ? measurement_st.TTFB_BORDER_GOOD : WebVitalsConst.TTFB_BORDER_GOOD),
                  );
                  this.addNoGoodAndNoBadPageByVitalArray(
                    pageTransition.metrics,
                    this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage,
                    scenario.strategy,
                    'SI',
                    (measurement_st !== null ? measurement_st.SI_BORDER_BAD : WebVitalsConst.SI_BORDER_BAD),
                    (measurement_st !== null ? measurement_st.SI_BORDER_GOOD : WebVitalsConst.SI_BORDER_GOOD),
                  );
                  this.addNoGoodAndNoBadPageByVitalArray(
                    pageTransition.metrics,
                    this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage,
                    scenario.strategy,
                    'TBT',
                    (measurement_st !== null ? measurement_st.TBT_BORDER_BAD : WebVitalsConst.TBT_BORDER_BAD),
                    (measurement_st !== null ? measurement_st.TBT_BORDER_GOOD : WebVitalsConst.TBT_BORDER_GOOD),
                  );
                  // this.addNoGoodAndNoBadPageByVitalArray(
                  //   pageTransition.metrics,
                  //   this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage,
                  //   scenario.strategy,
                  //   'FID',
                  //   WebVitalsConst.FID_BORDER_BAD,
                  //   WebVitalsConst.FID_BORDER_GOOD,
                  // );//FIDを削除(RS-890)
                  this.addNoGoodAndNoBadPageByVitalArray(
                    pageTransition.metrics,
                    this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage,
                    scenario.strategy,
                    'CLS',
                    (measurement_st !== null ? measurement_st.CLS_BORDER_BAD : WebVitalsConst.CLS_BORDER_BAD),
                    (measurement_st !== null ? measurement_st.CLS_BORDER_GOOD : WebVitalsConst.CLS_BORDER_GOOD),
                  );
                });
              }

              // 問題あり・やや問題ありの件数を取得（計測結果列の表示絵文字とポップアップの制御用）
              const badPageValues = this.scenarioSummaries[
                i
              ].warningMetrics.badPage[scenario.strategy]['CLS']
                .concat(
                  this.scenarioSummaries[i].warningMetrics.badPage[
                  scenario.strategy
                  ]['LCP'],
                )
                .concat(
                  this.scenarioSummaries[i].warningMetrics.badPage[
                  scenario.strategy
                  ]['FCP'],
                )
                .concat(
                  this.scenarioSummaries[i].warningMetrics.badPage[
                  scenario.strategy
                  ]['TTFB'],
                )
                .concat(
                  this.scenarioSummaries[i].warningMetrics.badPage[
                  scenario.strategy
                  ]['SI'],
                )
                .concat(
                  this.scenarioSummaries[i].warningMetrics.badPage[
                  scenario.strategy
                  ]['TBT'],
                );
              // .concat(
              //   this.scenarioSummaries[i].warningMetrics.badPage[
              //     scenario.strategy
              //   ]['FID'],
              // );
              this.scenarioSummaries[i].warningMetrics.badPageCounter[
                scenario.strategy
              ] = badPageValues.length;
              const noGoodAndNoBadPageValues = this.scenarioSummaries[
                i
              ].warningMetrics.noGoodAndNoBadPage[scenario.strategy]['CLS']
                .concat(
                  this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage[
                  scenario.strategy
                  ]['LCP'],
                )
                .concat(
                  this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage[
                  scenario.strategy
                  ]['FCP'],
                )
                .concat(
                  this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage[
                  scenario.strategy
                  ]['TTFB'],
                )
                .concat(
                  this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage[
                  scenario.strategy
                  ]['SI'],
                )
                .concat(
                  this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage[
                  scenario.strategy
                  ]['TBT'],
                );
              // .concat(
              //   this.scenarioSummaries[i].warningMetrics.noGoodAndNoBadPage[
              //     scenario.strategy
              //   ]['FID'],
              // );//FIDを削除(RS-890)
              this.scenarioSummaries[
                i
              ].warningMetrics.noGoodAndNoBadPageCounter[scenario.strategy] =
                noGoodAndNoBadPageValues.length;
            });
            // 各バイタル値ごとの問題あり・やや問題ありの個数を数える

            const {
              badPageCounterByWebVital,
              badPage,
              noGoodAndNoBadPageCounterByWebVital,
              noGoodAndNoBadPage,
            } = this.scenarioSummaries[i].warningMetrics;

            this.coreWebVitals.forEach((vital) =>
              this.countByWebVital(
                badPageCounterByWebVital,
                badPage,
                'desktop',
                vital,
              ),
            );
            this.coreWebVitals.forEach((vital) =>
              this.countByWebVital(
                badPageCounterByWebVital,
                badPage,
                'mobile',
                vital,
              ),
            );
            this.coreWebVitals.forEach((vital) =>
              this.countByWebVital(
                noGoodAndNoBadPageCounterByWebVital,
                noGoodAndNoBadPage,
                'desktop',
                vital,
              ),
            );
            this.coreWebVitals.forEach((vital) =>
              this.countByWebVital(
                noGoodAndNoBadPageCounterByWebVital,
                noGoodAndNoBadPage,
                'mobile',
                vital,
              ),
            );
          });
          // プルダウンで指定した期間に存在しないフィルタ項目のアイテムが表示される
          this.resultscenarioSummaries = [];
          this.filteredMeasurementResults = this.measurementResultSelectedItems;
          if (this.filteredMeasurementResults.length !== 0) {
            this.scenarioSummaries.forEach((resultSummary, i) => {
              if (
                (this.filteredMeasurementResults.includes('問題あり') &&
                  (this.scenarioSummaries[i].warningMetrics.badPageCounter[
                    'desktop'
                  ] > 0 ||
                    this.scenarioSummaries[i].warningMetrics.badPageCounter[
                    'mobile'
                    ] > 0)) ||
                (this.filteredMeasurementResults.includes('やや問題あり') &&
                  !(
                    this.scenarioSummaries[i].warningMetrics.badPageCounter[
                    'desktop'
                    ] > 0
                  ) &&
                  !(
                    this.scenarioSummaries[i].warningMetrics.badPageCounter[
                    'mobile'
                    ] > 0
                  ) &&
                  (this.scenarioSummaries[i].warningMetrics
                    .noGoodAndNoBadPageCounter['desktop'] > 0 ||
                    this.scenarioSummaries[i].warningMetrics
                      .noGoodAndNoBadPageCounter['mobile'] > 0)) ||
                (this.filteredMeasurementResults.includes('問題なし') &&
                  !(
                    this.scenarioSummaries[i].warningMetrics.badPageCounter[
                    'desktop'
                    ] > 0
                  ) &&
                  !(
                    this.scenarioSummaries[i].warningMetrics.badPageCounter[
                    'mobile'
                    ] > 0
                  ) &&
                  !(
                    this.scenarioSummaries[i].warningMetrics
                      .noGoodAndNoBadPageCounter['desktop'] > 0
                  ) &&
                  !(
                    this.scenarioSummaries[i].warningMetrics
                      .noGoodAndNoBadPageCounter['mobile'] > 0
                  ))
              ) {
                // シナリオ概要に表示されている結果だけドロップダウンに入れる
                tmpsite[m_index] = this.scenarioSummaries[i].siteName;
                tmpcategory[m_index] = this.scenarioSummaries[i].category;
                tmpscenarioName[m_index] = this.scenarioSummaries[i].scenarioName;
                if (
                  this.scenarioSummaries[i].warningMetrics.badPageCounter[
                  'desktop'
                  ] > 0 ||
                  this.scenarioSummaries[i].warningMetrics.badPageCounter[
                  'mobile'
                  ] > 0
                ) {
                  tmpresult[m_index] = '問題あり';
                } else if (
                  !(
                    this.scenarioSummaries[i].warningMetrics.badPageCounter[
                    'desktop'
                    ] > 0
                  ) &&
                  !(
                    this.scenarioSummaries[i].warningMetrics.badPageCounter[
                    'mobile'
                    ] > 0
                  ) &&
                  (this.scenarioSummaries[i].warningMetrics
                    .noGoodAndNoBadPageCounter['desktop'] > 0 ||
                    this.scenarioSummaries[i].warningMetrics
                      .noGoodAndNoBadPageCounter['mobile'] > 0)
                ) {
                  tmpresult[m_index] = 'やや問題あり';
                } else if (
                  !(
                    this.scenarioSummaries[i].warningMetrics.badPageCounter[
                    'desktop'
                    ] > 0
                  ) &&
                  !(
                    this.scenarioSummaries[i].warningMetrics.badPageCounter[
                    'mobile'
                    ] > 0
                  ) &&
                  !(
                    this.scenarioSummaries[i].warningMetrics
                      .noGoodAndNoBadPageCounter['desktop'] > 0
                  ) &&
                  !(
                    this.scenarioSummaries[i].warningMetrics
                      .noGoodAndNoBadPageCounter['mobile'] > 0
                  )
                ) {
                  tmpresult[m_index] = '問題なし';
                }
                this.resultscenarioSummaries[m_index] = this.scenarioSummaries[i];
                m_index++;
              }
            });
            this.scenarioSummaries = this.resultscenarioSummaries;
          } else {
            this.scenarioSummaries.forEach((resultSummary, i) => {
              if (
                this.scenarioSummaries[i].warningMetrics.badPageCounter[
                'desktop'
                ] > 0 ||
                this.scenarioSummaries[i].warningMetrics.badPageCounter[
                'mobile'
                ] > 0
              ) {
                // シナリオ概要に表示されている結果だけドロップダウンに入れる
                tmpsite[m_index] = this.scenarioSummaries[i].siteName;
                tmpcategory[m_index] = this.scenarioSummaries[i].category;
                tmpscenarioName[m_index] = this.scenarioSummaries[i].scenarioName;
                tmpresult[m_index] = '問題あり';
              } else if (
                !(
                  this.scenarioSummaries[i].warningMetrics.badPageCounter[
                  'desktop'
                  ] > 0
                ) &&
                !(
                  this.scenarioSummaries[i].warningMetrics.badPageCounter[
                  'mobile'
                  ] > 0
                ) &&
                (this.scenarioSummaries[i].warningMetrics
                  .noGoodAndNoBadPageCounter['desktop'] > 0 ||
                  this.scenarioSummaries[i].warningMetrics
                    .noGoodAndNoBadPageCounter['mobile'] > 0)
              ) {
                // シナリオ概要に表示されている結果だけドロップダウンに入れる
                tmpsite[m_index] = this.scenarioSummaries[i].siteName;
                tmpcategory[m_index] = this.scenarioSummaries[i].category;
                tmpscenarioName[m_index] = this.scenarioSummaries[i].scenarioName;
                tmpresult[m_index] = 'やや問題あり';
              } else if (
                !(
                  this.scenarioSummaries[i].warningMetrics.badPageCounter[
                  'desktop'
                  ] > 0
                ) &&
                !(
                  this.scenarioSummaries[i].warningMetrics.badPageCounter[
                  'mobile'
                  ] > 0
                ) &&
                !(
                  this.scenarioSummaries[i].warningMetrics
                    .noGoodAndNoBadPageCounter['desktop'] > 0
                ) &&
                !(
                  this.scenarioSummaries[i].warningMetrics
                    .noGoodAndNoBadPageCounter['mobile'] > 0
                )
              ) {
                // シナリオ概要に表示されている結果だけドロップダウンに入れる
                tmpsite[m_index] = this.scenarioSummaries[i].siteName;
                tmpcategory[m_index] = this.scenarioSummaries[i].category;
                tmpscenarioName[m_index] = this.scenarioSummaries[i].scenarioName;
                tmpresult[m_index] = '問題なし';
              }
              m_index++;
            });
          }
          // 計測結果列のフィルタによって表示する-end
          // 相談ボタンの表示 -start
          this.consultButtonDisplay = false;
          for (const y in this.scenarioSummaries) {
            if (
              this.scenarioSummaries[y].warningMetrics.badPageCounter[
              'desktop'
              ] > 0 ||
              this.scenarioSummaries[y].warningMetrics.badPageCounter[
              'mobile'
              ] > 0
            ) {
              this.consultButtonDisplay = true;
            }
          }
          // 相談ボタンの表示 -end
        }
        // シナリオ概要に表示されている結果だけドロップダウンに入れる
        this.measurementResultDropdownList = Array.from(new Set(tmpresult.filter(value => value !== '')));
        this.siteNameDropdownList = Array.from(new Set(tmpsite.filter(value => value !== '')));
        this.categoryDropdownList = Array.from(new Set(tmpcategory.filter(value => value !== '')));
        this.scenarioDropdownList = Array.from(new Set(tmpscenarioName.filter(value => value !== '')));
      },
    );

    // kslm-added
    // this.getScenarioOptions(); // シナリオ概要に表示されている結果だけドロップダウンに入れるので、コマンドアウトしました。
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'すべて選択',
      unSelectAllText: 'すべて選択解除',
      itemsShowLimit: 0,
      allowSearchFilter: true,
    };

    (function () {
      document
        .querySelectorAll('.dropdown-list')
        .forEach((el) => el.setAttribute('style', 'width:max-content'));
      const measurementResult = document.querySelector(
        '#measurementResultDropdown > div > div:nth-child(1) > span',
      );
      const siteName = document.querySelector(
        '#siteNameDropdown > div > div:nth-child(1) > span',
      );
      const category = document.querySelector(
        '#categoryDropdown > div > div:nth-child(1) > span',
      );
      const scenario = document.querySelector(
        '#scenarioDropdown > div > div:nth-child(1) > span',
      );
      measurementResult.setAttribute(
        'style',
        'border:0px;font-weight:600;font-size:0.9375rem;color:white;text-align:left;',
      );
      measurementResult.innerHTML = `<span _ngcontent-tqn-c14='' class='ng-star-inserted'>計測結果
                </span><i class='nb-arrow-dropdown' style='padding:10px;color:white;'></i>`;
      siteName.setAttribute(
        'style',
        'border:0px;font-weight:600;font-size:0.9375rem;color:white;text-align:left;',
      );
      siteName.innerHTML = `<span _ngcontent-tqn-c14='' class='ng-star-inserted'>サイト
                </span><i class='nb-arrow-dropdown' style='padding:10px;color:white;'></i>`;
      category.setAttribute(
        'style',
        'border:0px;font-weight:600;font-size:0.9375rem;color:white;text-align:center;',
      );
      category.innerHTML = `<span _ngcontent-tqn-c14='' class='ng-star-inserted'>カテゴリー
                </span><i class='nb-arrow-dropdown' style='padding:10px;color:white;'></i>`;
      scenario.setAttribute(
        'style',
        'border:0px;font-weight:600;font-size:0.9375rem;color:white;text-align:center;',
      );
      scenario.innerHTML = `<span _ngcontent-tqn-c14='' class='ng-star-inserted'>シナリオ名
                </span><i class='nb-arrow-dropdown' style='padding:10px;color:white;'></i>`;
    })();
  }

  ngAfterViewInit() {
    this.isLoading = true;
  }

  private setScenarioSummaries() {
    this.isLoading = true;
    this.condition.scenarioSite = this.siteNameSelectedItems;
    this.condition.scenarioCategory = this.categorySelectedItems;
    this.condition.scenarioName = this.scenarioSelectedItems;
    this.scenarioDetailService.setScenarioSummaries(this.condition);
    // Save searchConditions to sessionStorage
    //this.setSessionStorageItem('searchConditions', this.condition);
    sessionStorage.setItem('searchConditions', JSON.stringify(this.condition));
  }

  selectedScenario(val) {
    this.ngOnDestroy();

    this.searchService.setScenarioSiteDrillDownOption([val]);
    this.searchService.setScenarioSiteDrillDownConditionStatus();
    this.router.navigate(['/pages/scenario-summary/detail'], {
      queryParams: { selectedScenario: val, selectedCondition: this.selectedCondition },
      state: { condition: { start: this.condition.start, end: this.condition.end } },
    });
  }

  exportdata() {
    const exportData = [
      {
        siteName: 'サイト',
        category: 'カテゴリー',
        scenarioName: 'シナリオ名',
        measurementDate: '最終測定日時',
        LPSMobile: 'LPS（モバイル）',
        LCPMobile: 'LCP（モバイル）',
        FCPMobile: 'FCP（モバイル）',
        TTFBMobile: 'TTFB（モバイル）',
        SIMobile: 'SI（モバイル）',
        TBTMobile: 'TBT（モバイル）',
        FIDMobile: 'FID（モバイル）',
        CLSMobile: 'CLS（モバイル）',
        CTSMobile: 'CTS（モバイル）',
        CBSMobile: 'CBS（モバイル）',
        PRNMobile: 'PRN（モバイル）',
        resultMobile: '診断結果（モバイル）',
        LPSDesktop: 'LPS（デスクトップ）',
        LCPDesktop: 'LCP（デスクトップ）',
        FCPDesktop: 'FCP（デスクトップ）',
        TTFBDesktop: 'TTFB（デスクトップ）',
        SIDesktop: 'SI（デスクトップ）',
        TBTDesktop: 'TBT（デスクトップ）',
        FIDDesktop: 'FID（デスクトップ）',
        CLSDesktop: 'CLS（デスクトップ）',
        CTSDesktop: 'CTS（デスクトップ）',
        CBSDesktop: 'CBS（デスクトップ）',
        PRNDesktop: 'PRN（デスクトップ）',
        resultDesktop: '診断結果（デスクトップ）',
      },
    ];

    if (this.scenarioSummaries.length > 0) {
      this.scenarioSummaries.forEach((scenarioSummary) => {
        const desktop = scenarioSummary.strategies.filter(st => st.strategy === 'desktop');
        const mobile = scenarioSummary.strategies.filter(st => st.strategy === 'mobile');
        let resultDesktop = '';

        if (!scenarioSummary.warningMetrics.isMeasurementSubjectDesktop) {
          resultDesktop += '※測定対象外';
        }

        if (scenarioSummary.warningMetrics.badPageCounter['desktop'] > 0) {
          resultDesktop += '【要改善点が' + scenarioSummary.warningMetrics.badPageCounter['desktop'] + '件あります】\n';
          for (const [key, value] of Object.entries(scenarioSummary.warningMetrics.badPage['desktop'])) {
            if (scenarioSummary.warningMetrics.badPageCounterByWebVital['desktop'][key] > 0) {
              resultDesktop += key + '\n';
              value.forEach(val => {
                resultDesktop += '   ' + val + (key !== 'CLS' ? 'ms \n' : '\n');
              });
            }
          }

          if (scenarioSummary.warningMetrics.noGoodAndNoBadPageCounter['desktop']) {
            resultDesktop += '【やや遅い点が' + scenarioSummary.warningMetrics.noGoodAndNoBadPageCounter['desktop'] + '件あります】\n';
            for (const [key1, value1] of Object.entries(scenarioSummary.warningMetrics.noGoodAndNoBadPage['desktop'])) {
              if (scenarioSummary.warningMetrics.noGoodAndNoBadPageCounterByWebVital['desktop'][key1] > 0) {
                resultDesktop += key1 + '\n';
                value1.forEach(val => {
                  resultDesktop += '   ' + val + (key1 !== 'CLS' ? 'ms \n' : '\n');
                });
              }
            }
          }

          if (scenarioSummary.warningMetrics.isMeasurementSubjectDesktop &&
            scenarioSummary.warningMetrics.badPageCounter['desktop'] < 0 &&
            !scenarioSummary.warningMetrics.noGoodAndNoBadPageCounter['desktop']
          ) {
            resultDesktop += '問題なし\n';
          }
        }

        let resultMobile = '';
        if (!scenarioSummary.warningMetrics.isMeasurementSubjectMobile) {
          resultMobile += '※測定対象外';
        }

        if (scenarioSummary.warningMetrics.badPageCounter['mobile'] > 0) {
          resultMobile += '【要改善点が' + scenarioSummary.warningMetrics.badPageCounter['mobile'] + '件あります】\n';
          for (const [key, value] of Object.entries(scenarioSummary.warningMetrics.badPage['mobile'])) {
            if (scenarioSummary.warningMetrics.badPageCounterByWebVital['mobile'][key] > 0) {
              resultMobile += key + '\n';
              value.forEach(val => {
                resultMobile += '   ' + val + (key !== 'CLS' ? 'ms \n' : '\n');
              });
            }
          }

          if (scenarioSummary.warningMetrics.noGoodAndNoBadPageCounter['mobile']) {
            resultMobile += '【やや遅い点が' + scenarioSummary.warningMetrics.noGoodAndNoBadPageCounter['mobile'] + '件あります】\n';
            for (const [key1, value1] of Object.entries(scenarioSummary.warningMetrics.noGoodAndNoBadPage['mobile'])) {
              if (scenarioSummary.warningMetrics.noGoodAndNoBadPageCounterByWebVital['mobile'][key1] > 0) {
                resultMobile += key1 + '\n';
                value1.forEach(val => {
                  resultMobile += '   ' + val + (key1 !== 'CLS' ? 'ms \n' : '\n');
                });
              }
            }
          }

          if (scenarioSummary.warningMetrics.isMeasurementSubjectMobile &&
            scenarioSummary.warningMetrics.badPageCounter['mobile'] < 0 &&
            !scenarioSummary.warningMetrics.noGoodAndNoBadPageCounter['mobile']
          ) {
            resultMobile += '問題なし\n';
          }
          resultMobile += '';
        }

        const mlength = (mobile.length > 0 && mobile[0].pageTransitions !== undefined ? mobile[0].pageTransitions.length : 0);
        const dlength = (desktop.length > 0 && desktop[0].pageTransitions !== undefined ? desktop[0].pageTransitions.length : 0);
        const count = (mlength > dlength ? mlength : dlength);
        for (let i = 0; i < count; i++) {
          const checkmobilepg = mobile[0] && mobile[0].pageTransitions && mobile[0].pageTransitions[i];
          const checkmobilect = mobile[0] && mobile[0].pageContents && mobile[0].pageContents[i];
          const checkdesktoppg = desktop[0] && desktop[0].pageTransitions && desktop[0].pageTransitions[i];
          const checkdesktopct = desktop[0] && desktop[0].pageContents && desktop[0].pageContents[i];
          const data = {
            siteName: scenarioSummary.siteName,
            category: scenarioSummary.category,
            scenarioName: scenarioSummary.scenarioName,
            measurementDate: scenarioSummary.lastMeasureDateTime.date + ' ' + scenarioSummary.lastMeasureDateTime.time,
            LPSMobile: checkmobilepg && mobile[0].pageTransitions[i].metrics['LPS'] ? parseFloat(mobile[0].pageTransitions[i].metrics['LPS']).toFixed() : '0',
            LCPMobile: checkmobilepg && mobile[0].pageTransitions[i].metrics['LCP'] ? parseFloat(mobile[0].pageTransitions[i].metrics['LCP']).toFixed() : '0',
            FCPMobile: checkmobilepg && mobile[0].pageTransitions[i].metrics['FCP'] ? parseFloat(mobile[0].pageTransitions[i].metrics['FCP']).toFixed() : '0',
            TTFBMobile: checkmobilepg && mobile[0].pageTransitions[i].metrics['TTFB'] ? parseFloat(mobile[0].pageTransitions[i].metrics['TTFB']).toFixed() : '0',
            SIMobile: checkmobilepg && mobile[0].pageTransitions[i].metrics['SI'] ? parseFloat(mobile[0].pageTransitions[i].metrics['SI']).toFixed() : '0',
            TBTMobile: checkmobilepg && mobile[0].pageTransitions[i].metrics['TBT'] ? parseFloat(mobile[0].pageTransitions[i].metrics['TBT']).toFixed() : '0',
            FIDMobile: checkmobilepg && mobile[0].pageTransitions[i].metrics['FID'] ? parseFloat(mobile[0].pageTransitions[i].metrics['FID']).toFixed() : '0',
            CLSMobile: checkmobilepg && mobile[0].pageTransitions[i].metrics['CLS'] ? mobile[0].pageTransitions[i].metrics['CLS'] : '0',
            CTSMobile: checkmobilect && mobile[0].pageContents[i].contents['CTS'] ? mobile[0].pageContents[i].contents['CTS'] : '0',
            CBSMobile: checkmobilect && mobile[0].pageContents[i].contents['CBS'] ? mobile[0].pageContents[i].contents['CBS'] : '0',
            PRNMobile: checkmobilect && mobile[0].pageContents[i].contents['PRN'] ? mobile[0].pageContents[i].contents['PRN'] : '0',
            resultMobile: resultMobile,
            LPSDesktop: checkdesktoppg && desktop[0].pageTransitions[i].metrics['LPS'] ? parseFloat(desktop[0].pageTransitions[i].metrics['LPS']).toFixed() : '0',
            LCPDesktop: checkdesktoppg && desktop[0].pageTransitions[i].metrics['LCP'] ? parseFloat(desktop[0].pageTransitions[i].metrics['LCP']).toFixed() : '0',
            FCPDesktop: checkdesktoppg && desktop[0].pageTransitions[i].metrics['FCP'] ? parseFloat(desktop[0].pageTransitions[i].metrics['FCP']).toFixed() : '0',
            TTFBDesktop: checkdesktoppg && desktop[0].pageTransitions[i].metrics['TTFB'] ? parseFloat(desktop[0].pageTransitions[i].metrics['TTFB']).toFixed() : '0',
            SIDesktop: checkdesktoppg && desktop[0].pageTransitions[i].metrics['SI'] ? parseFloat(desktop[0].pageTransitions[i].metrics['SI']).toFixed() : '0',
            TBTDesktop: checkdesktoppg && desktop[0].pageTransitions[i].metrics['TBT'] ? parseFloat(desktop[0].pageTransitions[i].metrics['TBT']).toFixed() : '0',
            FIDDesktop: checkdesktoppg && desktop[0].pageTransitions[i].metrics['FID'] ? parseFloat(desktop[0].pageTransitions[i].metrics['FID']).toFixed() : '0',
            CLSDesktop: checkdesktoppg && desktop[0].pageTransitions[i].metrics['CLS'] ? desktop[0].pageTransitions[i].metrics['CLS'] : '0',
            CTSDesktop: checkdesktopct && desktop[0].pageContents[i].contents['CTS'] ? desktop[0].pageContents[i].contents['CTS'] : '0',
            CBSDesktop: checkdesktopct && desktop[0].pageContents[i].contents['CBS'] ? desktop[0].pageContents[i].contents['CBS'] : '0',
            PRNDesktop: checkdesktopct && desktop[0].pageContents[i].contents['PRN'] ? desktop[0].pageContents[i].contents['PRN'] : '0',
            resultDesktop: resultDesktop,
          };
          exportData.push(data);
        }
      });
    }
    const date = new Date();
    new ngxCsv(exportData, 'scenario_result_' + (date.getMonth() + 1) + '.' + date.getDate() + '.' + date.getFullYear() + '_' + date.getHours() + '_' + date.getMinutes());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (value) {
      value.unsubscribe();
    });
  }

  /*
   * 各項目のフィルタで抽出後に、絞り込まれたフィルタアイテムを返す
   */
  dropdownOk(column: string): void {
    const selector = column !== 'site' ? `#${column}Dropdown > div > div` : '#siteNameDropdown > div > div';
    document
      .querySelector(`${selector}.dropdown-list`)
      .setAttribute('id', `${column}-dropdown`);
    document.getElementById(`${column}-dropdown`).hidden = true;

    // ドロップダウンリストに存在する、かつ選択されているアイテムを抽出対象とする
    // （選択されているが、他項目による絞り込みでドロップダウンに存在しないアイテムが存在する場合、挙動がおかしくなるため）
    this.measurementResultSelectedItems =
      this.measurementResultDropdownList.filter(
        (existsDropdownItem) =>
          this.measurementResultSelectedItems.indexOf(existsDropdownItem) !== -1,
      );
    // フィルタリングされたアイテムのため、マークを変更(計測結果)
    const measurementResult_mark = document.querySelector(
      '#measurementResultDropdown > div > div:nth-child(1) > span',
    );
    if (this.measurementResultSelectedItems.length !== 0) {
      measurementResult_mark.innerHTML = `<span _ngcontent-tqn-c14='' class='ng-star-inserted'>計測結果
            </span><i class='fa fa-filter'></i>`;
    } else {
      measurementResult_mark.innerHTML = `<span _ngcontent-tqn-c14='' class='ng-star-inserted'>計測結果
            </span><i class='nb-arrow-dropdown' style='padding:10px;color:white;'></i>`;
    }
    this.siteNameSelectedItems = this.siteNameDropdownList.filter(
      (existsDropdownItem) =>
        this.siteNameSelectedItems.indexOf(existsDropdownItem) !== -1,
    );
    // フィルタリングされたアイテムのため、マークを変更(サイト)
    const siteName_mark = document.querySelector(
      '#siteNameDropdown > div > div:nth-child(1) > span',
    );
    if (this.siteNameSelectedItems.length !== 0) {
      siteName_mark.innerHTML = `<span _ngcontent-tqn-c14='' class='ng-star-inserted'>サイト
                </span><i class='fa fa-filter'></i>`;
    } else {
      siteName_mark.innerHTML = `<span _ngcontent-tqn-c14='' class='ng-star-inserted'>サイト
                </span><i class='nb-arrow-dropdown' style='padding:10px;color:white;'></i>`;
    }
    this.categorySelectedItems = this.categoryDropdownList.filter(
      (existsDropdownItem) =>
        this.categorySelectedItems.indexOf(existsDropdownItem) !== -1,
    );
    // フィルタリングされたアイテムのため、マークを変更(カテゴリー)
    const category_mark = document.querySelector(
      '#categoryDropdown > div > div:nth-child(1) > span',
    );
    if (this.categorySelectedItems.length !== 0) {
      category_mark.innerHTML = `<span _ngcontent-tqn-c14='' class='ng-star-inserted'>カテゴリー
                </span><i class='fa fa-filter'></i>`;
    } else {
      category_mark.innerHTML = `<span _ngcontent-tqn-c14='' class='ng-star-inserted'>カテゴリー
                </span><i class='nb-arrow-dropdown' style='padding:10px;color:white;'></i>`;
    }
    this.scenarioSelectedItems = this.scenarioDropdownList.filter(
      (existsDropdownItem) =>
        this.scenarioSelectedItems.indexOf(existsDropdownItem) !== -1,
    );
    // フィルタリングされたアイテムのため、マークを変更(シナリオ名)
    const scenario_mark = document.querySelector(
      '#scenarioDropdown > div > div:nth-child(1) > span',
    );
    if (this.scenarioSelectedItems.length !== 0) {
      scenario_mark.innerHTML = `<span _ngcontent-tqn-c14='' class='ng-star-inserted'>シナリオ名
                </span><i class='fa fa-filter'></i>`;
    } else {
      scenario_mark.innerHTML = `<span _ngcontent-tqn-c14='' class='ng-star-inserted'>シナリオ名
                </span><i class='nb-arrow-dropdown' style='padding:10px;color:white;'></i>`;
    }

    this.configService
      .getNarrowedDownFilterItems(
        this.siteNameSelectedItems,
        this.categorySelectedItems,
        this.scenarioSelectedItems,
      )
      .subscribe((showFilterItems) => {
        this.siteNameDropdownList = showFilterItems['siteNames'];
        this.categoryDropdownList = showFilterItems['categories'];
        this.scenarioDropdownList = showFilterItems['scenarioNames'];
      });
    this.setScenarioSummaries();
  }

  measurementResultCancel() {
    if (this.measurementResultSelectedItems.length !== 0) {
      this.measurementResultSelectedItems = [];
    }
  }

  measurementResultDropdownClick() {
    document.getElementById('button-panel').style.display = 'block';
    const btnPanel = document.querySelector('.button-panel');
    const ul = document.querySelector('#measurementResultDropdown > div > div.dropdown-list');
    $(btnPanel).appendTo(ul);
  }

  measurementResultOnItemSelect(item: any) {
    this.measurementResultSelectedItems.push(item);
    this.measurementResultSelectedItems.pop();
  }

  measurementResultOnSelectAll(item: any) { }

  siteNameCancel() {
    if (this.siteNameSelectedItems.length !== 0) {
      this.siteNameSelectedItems = [];
    }
  }

  siteNameDropdownClick() {
    document.getElementById('site-button-panel').style.display = 'block';
    const btnPanel = document.querySelector('.site-button-panel');
    const ul = document.querySelector(
      '#siteNameDropdown > div > div.dropdown-list',
    );
    $(btnPanel).appendTo(ul);
  }

  siteNameOnItemSelect(item: any) {
    this.siteNameSelectedItems.push(item);
    this.siteNameSelectedItems.pop();
  }

  SiteNameOnSelectAll(item: any) { }

  categoryCancel() {
    if (this.categorySelectedItems.length !== 0) {
      this.categorySelectedItems = [];
    }
  }

  categoryDropdownClick() {
    document.getElementById('category-button-panel').style.display = 'block';
    const btnPanel = document.querySelector('.category-button-panel');
    const ul = document.querySelector(
      '#categoryDropdown > div > div.dropdown-list',
    );
    $(btnPanel).appendTo(ul);
  }

  categoryOnItemSelect(item: any) {
    this.categorySelectedItems.push(item);
    this.categorySelectedItems.pop();
  }

  categoryOnSelectAll(item: any) { }

  scenarioCancel() {
    if (this.scenarioSelectedItems.length !== 0) {
      this.scenarioSelectedItems = [];
    }
  }

  scenarioDropdownClick() {
    document.getElementById('scenario-button-panel').style.display = 'block';
    const btnPanel = document.querySelector('.scenario-button-panel');
    const ul = document.querySelector(
      '#scenarioDropdown > div > div.dropdown-list',
    );
    $(btnPanel).appendTo(ul);
  }

  scenarioOnItemSelect(item: any) {
    this.scenarioSelectedItems.push(item);
    this.scenarioSelectedItems.pop();
  }

  scenarioOnSelectAll(item: any) { }

  // シナリオ概要に表示されている結果だけドロップダウンに入れるので、コマンドアウトしました。
  // getScenarioOptions(): void {
  //   // 計測結果のリストを作成
  //   this.measurementResultDropdownList = new Array(
  //     '問題あり',
  //     'やや問題あり',
  //     '問題なし'
  //   );

  //   // サイト名のリストを取得する
  //   this.configService.getScenarioSiteNames().subscribe((siteNames) => {
  //     this.siteNameDropdownList = siteNames;
  //   });

  //   // カテゴリーのリストを取得する
  //   this.configService.getScenarioCategories([]).subscribe((categories) => {
  //     this.categoryDropdownList = categories;
  //   });

  //   // シナリオ名のリストを取得する
  //   this.configService.getScenarioNames([]).subscribe((scenarios) => {
  //     this.scenarioDropdownList = scenarios;
  //   });
  // }

  /*
   * 問題ありページの測定値を端末・バイタル値ごとの配列に追加する
   */
  addBadPageByVitalArray(
    metrics: any,
    badPage: any,
    strategy: string,
    vital: string,
    vitalBorderBad: number,
  ) {
    if (metrics[vital] >= vitalBorderBad) {
      badPage[strategy][vital].push(metrics[vital]);
    }
  }

  /*
   * やや問題ありページの測定値を端末・バイタル値ごとの配列に追加する
   */
  addNoGoodAndNoBadPageByVitalArray(
    metrics: any,
    noGoodAndNoBadPage: any,
    strategy: string,
    vital: string,
    vitalBorderBad: number,
    vitalBorderGood: number,
  ) {
    if (metrics[vital] >= vitalBorderGood && metrics[vital] < vitalBorderBad) {
      noGoodAndNoBadPage[strategy][vital].push(metrics[vital]);
    }
  }

  /*
   *指定したバイタル値の問題あり・やや問題ありの個数を数える
   */
  countByWebVital(
    pageCounter: { [key: string]: { [key: string]: number } },
    page: { [key: string]: { [key: string]: Array<number> } },
    strategy: string,
    webVital: string,
  ): void {
    pageCounter[strategy][webVital] = page[strategy][webVital].reduce(
      (previousMetric, currentMetric) => previousMetric + currentMetric,
      0,
    );
  }

  /*
   * 期間指定フィルター関連
   */
  closeDatepicker() {
    this.showDatepicker = 'close_datepicker';
  }

  onConditionSelect() {
    if (this.selectedCondition === 'custom') {
      this.showDatepicker = '';
    } else {
      this.showDatepicker = 'close_datepicker';

      this.condition = {
        start: moment()
          .subtract(parseInt(this.selectedCondition, 10), 'minute')
          .valueOf(),
        end: moment().valueOf(),
      };
      this.setScenarioSummaries();

      this.displaySelectionPeriod({
        start: moment().subtract(
          parseInt(this.selectedCondition, 10),
          'minute',
        ),
        end: moment(),
      });
    }
    sessionStorage.setItem('selectedCondition', JSON.stringify(this.selectedCondition));
  }

  displaySelectionPeriod(selectionPeriod: any) {
    this.showPeriod =
      selectionPeriod.start.format('YYYY/MM/DD') +
      '~' +
      selectionPeriod.end.format('YYYY/MM/DD');
  }

  setScenarioDetail(period: any) {
    this.showDatepicker = 'close_datepicker';
    this.condition.start = period.start;
    this.condition.end = period.end;

    this.setScenarioSummaries();
  }
  /**
   * リクエストモーダル表示
   * @param sendRequest
   */
  openRequestForm(sendRequest): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.addRequestData = new FormData();

    // リクエストフォーム　Validation設定
    this.RequestForm = this.formBuilder.group({
      inputRequest: ['', [Validators.required, Validators.maxLength(2000)]],
    });
    // フォームデータをサーバに送信する
    this.mailService.sendConsultationModalMail().subscribe((response) => {
      if (response.errors) {
        // this.modalMessage = response.errors.message;
      }
    });

    this.modalService.open(sendRequest);
    this.modalDisabled = false;
  }

  // 相談内容
  get inputRequest() {
    return this.RequestForm.get('inputRequest');
  }
  // 相談内容のメール送信
  onRequestFormSubmit(): boolean {
    this.submitDisabled = true;
    this.isSubmitted = true;
    // バリデーションのチェックに違反する場合は処理を中断する
    if (this.RequestForm.invalid) {
      this.modalMessage = '依頼内容を入力してください。';
      this.submitDisabled = false;
      return false;
    }
    // ローディングのエフェクト開始
    this.isLoading = true;
    // 入力内容をフォームデータに設定する
    this.addRequestData.append('inputRequest', this.inputRequest.value);
    // フォームデータをサーバに送信する
    this.mailService
      .sendConsultationMail(this.addRequestData)
      .subscribe((response) => {
        if (response.errors) {
          this.modalMessage = response.errors.message;
        } else {
          // モーダルを閉じる
          alert(response.success.message);
          this.modalService.dismissAll('Submit Success');
        }
        this.submitDisabled = false;
        // ローディングのエフェクト終了
        this.isLoading = false;
      });
    return true;
  }
  // メッセージ内容
  get notiRequest() {
    return this.NotificationForm.get('notiRequest');
  }

  /**
  * リクエストモーダル表示
  * @param sendNotification
  */
  OpenNotiModal(sendNotification, scenarioId, summary): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.selectedScenarioId = scenarioId;
    this.selectedScenarioSummary = summary;
    this.modalService.open(sendNotification);
    this.modalDisabled = false;
  }

  onNotificationFormSubmit(): boolean {
    this.submitDisabled = true;
    this.isSubmitted = true;

    // バリデーションのチェックに違反する場合は処理を中断する
    if (this.NotificationForm.invalid || this.notiRequest.value === '') {
      this.modalMessage = 'メッセージ内容を入力してください。';
      this.submitDisabled = false;
      return false;
    }
    // ローディングのエフェクト開始
    this.isLoading = true;
    const scenarioDatainfo = {
      scenarioNo: this.selectedScenarioId,
      summary: this.selectedScenarioSummary,
      message: this.notiRequest.value
    };
    this.mailService.sendScenarioMail(scenarioDatainfo).subscribe((response) => {
      if (response.errors) {
        this.modalMessage = response.errors.message;
      } else {
        // モーダルを閉じる
        alert(response.success.message);
        this.modalService.dismissAll('Submit Success');
      }
      this.submitDisabled = false;
      // ローディングのエフェクト終了
      this.isLoading = false;
    });
    return true;
  }

  // メッセージリストを表示する
  DisplayScenarioMessage() {
    this.mailService.DisplayScenarioMessage().subscribe((response) => {
      if (response.success) {
        const param = response.success.param;
      }
    });
  }

  showPopup(summary: any, event: MouseEvent): void {
    if (summary.scenarioConfigId == this.scenarioConfigId) {
      return;
    }

    this.scenarioConfigId = summary.scenarioConfigId;
    this.selectedItem = summary;
    this.popupcursorX = 450;
    this.popupcursorY = event.clientY - 70;
    this.popupVisible = true;
  }

  hidePopup() {
    this.selectedItem = null;
    this.popupVisible = false;
    this.scenarioConfigId = 0;
  }
}