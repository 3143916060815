import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoadingService } from 'app/@core/data/loading.service';
import { NbThemeService } from '@nebular/theme';
import { ScenarioSummary, Scenario } from 'app/models/scenario.model';
import { ChartComponent } from 'angular2-chartjs';

import { ScenarioDetailService } from 'app/@core/data/scenario-detail.service';

@Component({
  selector: 'ngx-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent implements OnInit, OnDestroy {
  // サイト名
  @Input() siteName = '';

  @Input() defaultPeriodDifferencial: any;

  // Subscribeオブジェクト一覧
  subscriptions: any = [];

  // ページローディングフラグ
  isLoading: boolean = true;

  // シナリオ情報
  scenarioSummaries: Array<ScenarioSummary> = [];

  // ローディング処理設定
  loadingConfig: any = {};

  // Chartコンポネントオオブジェクト
  @ViewChild(ChartComponent, { static: false }) chart: ChartComponent;

  // Chartグラフ用の設定オブジェクト
  options: Object;

  // Chartグラフ用のデータオブジェクト
  data: Object;

  /** グラフの線、レブル等用色 */
  colors: any;

  /** テーマ用Subscribeオブジェクト */
  themeSubscription: any;

  viewSites: Array<string> = [];

  /** Chartグラフに表示するデバイスの指定 */
  selectedStrategy: string = 'モバイル';

  constructor(
    private theme: NbThemeService,
    protected loadingService: LoadingService,
    private scenarioDetailService: ScenarioDetailService,
  ) { }

  ngOnInit() {
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      this.colors = config.variables.temperature;
    });

    this.subscriptions.push(this.scenarioDetailService.getSelectedWebsites().subscribe(data => {
      this.viewSites = data;
    }));

    this.drawChart();
  }

  get devices() {
    return this.scenarioDetailService.getAllDevices();
  }

  /**
   * グラフの描画
   */
  drawChart(): void {
    let strategyType = 'mobile';
    if (this.selectedStrategy === 'デスクトップ') {
      strategyType = 'desktop';
    }

    this.scenarioDetailService.getScenarioSummaries.subscribe(scenarioSummaries => {
      this.scenarioSummaries = scenarioSummaries;
      const siteNameLabels: Array<string> = [];
      const stepCodeLabels: Array<Array<string>> = [];
      const stepDatas: Array<any> = [];
      let stepLabels = []; // Edited by Ei Mon Kyaw
      if (this.scenarioSummaries.length) {
        this.scenarioSummaries.forEach(site => {
          // scenarioSummariesから指定されたデバイスの内容だけを抽出してグラフの設定に利用する
          const scenario: Scenario = site.strategies.find(strategy => strategy.strategy === strategyType);
          if (scenario && scenario.strategy) {
            siteNameLabels.push(site.siteName);
            stepCodeLabels.push(scenario.scenarioSteps ? scenario.scenarioSteps.map(step => step.stepCode) : []);
            stepDatas.push({
              strategies: [scenario],
            });
          }
        });
        /* Edited by Ei Mon Kyaw Start */
        const stepAllLabels = stepCodeLabels.flat();
        stepLabels = stepAllLabels.filter((value, index) => stepAllLabels.indexOf(value) === index);
        const newstepCodeLabels = new Array(stepCodeLabels.length).fill(stepLabels);
        /* Edited by Ei Mon Kyaw End */
        this.options = this.setChartOptions(newstepCodeLabels);
        this.data = {
          labels: siteNameLabels,
          datasets: this.setDatasets(stepDatas, stepLabels),
        };
      }
    });
  }

  setChartOptions(stepCodeLabels: Array<Array<string>>) {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            stacked: true,
          },
        ],
        yAxes: [
          {
            barPercentage: 0.75,
            stacked: true,
            gridLines: {
              tickMarkLength: 0,
            },
            ticks: {
              padding: 5,
            },
          },
        ],
      },
      legend: {
        display: true,
        align: 'end',
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem) => {
            return tooltipItem[0].yLabel;
          },
          label: (tooltipItem) => {
            const label = stepCodeLabels[tooltipItem.index][tooltipItem.datasetIndex];
            return (tooltipItem.xLabel === 0 ? null : label + ': ' + tooltipItem.xLabel + 'ms');
          },
        },
      },
      onClick: function (event, element) {
        const siteName = element[0]._model.label;
        const titles = document.querySelectorAll('.scenario_step_title');
        const target = Array.from(titles).find(elem => elem.textContent === siteName);
        target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      },
    };
  }

  setChartLabels() {
    return this.scenarioSummaries.flatMap(scenarioSummary =>
      scenarioSummary.strategies.map(scenarioPerStrategy => {
        return scenarioSummary.siteName + '(' + scenarioPerStrategy.strategyJp + ')';
      }),
    );
  }

  setDatasets(stepDatas: Array<any>, stepLabels: Array<any>) {
    // シナリオごとの経過時間を抽出した二次元配列
    /*let datasetsByScenario = stepDatas.flatMap(scenarioSummary =>
      scenarioSummary.strategies.map(scenarioPerStrategy =>
        scenarioPerStrategy.scenarioSteps ? scenarioPerStrategy.scenarioSteps.map(scenario =>
          scenario.elapsedTime) : [],
      ),
    );*/
    const datasetsByScenario = stepDatas.flatMap(scenarioSummary =>
      scenarioSummary.strategies.map(scenarioPerStrategy => {
        if (scenarioPerStrategy.scenarioSteps) {
          const ScenarioStep = new Array(stepLabels.length).fill(0);
          const stepsdata = scenarioPerStrategy.scenarioSteps;
          stepsdata.map((scenario, idex) => {
            /* calculate step interval here */
            const time = (idex === 0 ? scenario.elapsedTime : (scenario.elapsedTime - stepsdata[idex - 1].elapsedTime));
            /* calculate step interval here */
            const index = stepLabels.indexOf(scenario.stepCode);
            ScenarioStep[index] = time;
          });
          return ScenarioStep;
        }else {
          return [];
        }
      }),
    );
    // datasetsByScenario = this.calculateStepInterval(datasetsByScenario);
    const lengths = datasetsByScenario.map(function(a){return a.length; });
    const idxOfLargestArray = lengths.indexOf(Math.max.apply(Math, lengths));

    const datasetsByColumn = datasetsByScenario[idxOfLargestArray].map((_, c) => datasetsByScenario.map(r => r[c]));

    const datasets = datasetsByColumn.map((dataset, idx) => {
      return {label: stepLabels[idx], data: dataset, backgroundColor: this.colors[idx]};
    });
    return datasets;
  }

  // 0秒から(シナリオ測定開始時間から)の計測値から各ステップ間計測値に変換する
  calculateStepInterval(datasetsByScenario: Array<Array<number>>) {
    return datasetsByScenario.map(scenario => {
      return scenario.map((time, idx) => {
        return idx === 0 ? time : (time - scenario[idx - 1]);
      });
    });
  }

  setViewSites(viewSites) {
    this.viewSites = viewSites;
  }

  changeStrategy(strategy: string) {
    this.selectedStrategy = strategy;
    this.drawChart();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (value) {
      value.unsubscribe();
    });
    this.themeSubscription.unsubscribe();
  }
}
