import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartModule } from 'angular2-chartjs';

import { ThemeModule } from '../../@theme/theme.module';

import { ChartsRoutingModule, routedComponents } from './charts-routing.module';
import { ChartjsBarComponent } from './chartjs/chartjs-bar.component';
import { ChartjsLineComponent } from './chartjs/chartjs-line.component';
import { ChartjsBarStackedComponent } from './chartjs/chartjs-bar-stacked.component';
import { ChartjsPieComponent } from './chartjs/chartjs-pie.component';
import { EchartsAreaStackComponent } from './echarts/echarts-area-stack.component';
import { ChartjsLineScenarioComponent } from './chartjs/chartjs-line-scenario.component';
import { EchartsScenarioAreaStackComponent } from './echarts/echarts-scenario-area-stack.component';

const components = [
  ChartjsBarComponent,
  ChartjsLineComponent,
  ChartjsBarStackedComponent,
  ChartjsPieComponent,
  EchartsAreaStackComponent,
  ChartjsLineScenarioComponent,
  EchartsScenarioAreaStackComponent,
];

@NgModule({
  imports: [ThemeModule, ChartsRoutingModule, NgxEchartsModule, NgxChartsModule, ChartModule],
  exports: [...components],
  declarations: [...routedComponents, ...components],
})
export class ChartsModule { }
