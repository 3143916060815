import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { ChartModule } from 'angular2-chartjs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ThemeModule } from '../../@theme/theme.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { NgxGalleryModule } from 'ngx-gallery';
import { ChartsModule } from '../charts/charts.module';
import { LoadingModule } from 'ngx-loading';
import { ScreenCaptureModule } from '../screen-capture/screen-capture.module';


import { DashboardComponent } from './dashboard.component';

import { ElasticsearchService } from '../../@core/data/elasticsearch.service';

const components = [
  DashboardComponent,
];

@NgModule({
  imports: [
    ThemeModule,
    NgxEchartsModule,
    ChartModule,
    ChartsModule,
    Ng2SmartTableModule,
    NgxChartsModule,
    AngularMultiSelectModule,
    NgxGalleryModule,
    LoadingModule,
    ScreenCaptureModule,
  ],
  providers: [
    ElasticsearchService,
  ],
  declarations: [
    ...components,
  ],
  entryComponents: [
  ],
})
export class DashboardModule { }
