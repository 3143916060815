import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { Subscription } from 'rxjs';

import * as moment from 'moment';
import { ElasticsearchService } from 'app/@core/data/elasticsearch.service';
import { ScenarioDetailService } from 'app/@core/data/scenario-detail.service';
import { TimelineMetric } from 'app/models/scenario.model';

@Component({
  selector: 'ngx-chronological',
  templateUrl: './chronological.component.html',
  styleUrls: ['./chronological.component.scss'],
})
export class ChronologicalComponent implements OnInit, OnDestroy {

  @Output() setLoading = new EventEmitter<boolean>();

  @Input() selectedScenario: number;

  @Input() defaultPeriodDifferencial: any;

  @Input() measurementStandard: any;

  // 指標ごとの時系列データ
  timelineMetrics: Array<TimelineMetric> = [];

  selectedMetrics: Array<string> = [];

  subscriptions: Array<Subscription> = [];

  timelineData: TimelineMetric;

  pageDetailData:any;

  // 選択中のデバイスフィルター
  selectedDevices: Array<string> = [];

  selectedCondition: string = '';

  showDatepicker = 'close_datepicker';

  showPeriod: string = '期間';

  constructor(
    protected elasticsearchService: ElasticsearchService,
    private scenarioDetailService: ScenarioDetailService,
  ) { }

  ngOnInit() {
    this.scenarioDetailService.getTimelineMetrics.subscribe(timelineMetrics => {
      this.timelineMetrics = timelineMetrics;

      const metricOrder = this.scenarioDetailService.getMetricSortOrder;
      this.sortByMetricOrder(metricOrder);
    });

    this.scenarioDetailService.getPageLoadTime.subscribe(
      pageDetailData => {this.pageDetailData = pageDetailData;})

    this.scenarioDetailService.getSelectedDevices().subscribe(data => {
      this.selectedDevices = data;
    });

    this.subscriptions.push(this.scenarioDetailService.getSelectedMetrics().subscribe(selectedMetrics => {
      this.selectedMetrics = selectedMetrics;
    }));

    if (this.defaultPeriodDifferencial === 'custom') {
      const condition = JSON.parse(sessionStorage.getItem('condition'));
      if (condition === null) {
        this.displaySelectionPeriod({
          start: moment().subtract(parseInt('4320', 10), 'minute'),
          end: moment(),
        });
      } else {
        this.displaySelectionPeriod({
          start: moment(condition.start),
          end: moment(),
        });
      }
    } else {
      this.displaySelectionPeriod({
        start: moment().subtract(parseInt(this.defaultPeriodDifferencial, 10), 'minute'),
        end: moment(),
      });
    }
    this.selectedCondition = this.defaultPeriodDifferencial;
  }

  // 指標の並び替え
  sortByMetricOrder(metricOrder: Array<string>) {
    this.timelineMetrics.sort(function (a, b) {
      if (metricOrder.indexOf(a['metricName']) === -1) {
        return 1;
      } else if (metricOrder.indexOf(a['metricName']) > metricOrder.indexOf(b['metricName'])) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (value) {
      value.unsubscribe();
    });
  }

  onGetLoading(isLoading) {
    this.setLoading.emit(isLoading);
  }

  onConditionSelect() {
    this.onGetLoading(true);
    if (this.selectedCondition === 'custom') {
      this.showDatepicker = '';
    } else {
      this.showDatepicker = 'close_datepicker';
      const period = {
        start: moment().subtract(parseInt(this.selectedCondition, 10), 'minute').valueOf(),
        end: moment().valueOf(),
      };

      this.scenarioDetailService.setTimelineMetrics(period, this.selectedScenario);

      this.displaySelectionPeriod({
        start: moment().subtract(parseInt(this.selectedCondition, 10), 'minute'),
        end: moment(),
      });
    }

    this.onGetLoading(false);
  }

  setTimelineMetrics(period: any) {
    this.showDatepicker = 'close_datepicker';
    this.onGetLoading(true);
    this.scenarioDetailService.setTimelineMetrics(period, this.selectedScenario);
    this.onGetLoading(false);
  }

  displaySelectionPeriod(selectionPeriod: any) {
    this.showPeriod = selectionPeriod.start.format('YYYY/MM/DD') + '~' + selectionPeriod.end.format('YYYY/MM/DD');
  }

  closeDatepicker() {
    this.showDatepicker = 'close_datepicker';
  }

  get devices() {
    return this.scenarioDetailService.getAllDevices();
  }
  
}
