import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Content, Page } from 'app/models/scenario.model';

import { Subscription } from 'rxjs';

import { ScenarioDetailService } from 'app/@core/data/scenario-detail.service';
import { ReportService } from 'app/@core/data/report.service';

import { WebVitalsConst } from 'app/app-const';
import { ScenarioConfigService } from 'app/@core/data/scenario-config.service';
import { UserService } from 'app/@core/data/users.service';

@Component({
  selector: 'ngx-page-metrics',
  templateUrl: './page-metrics.component.html',
  styleUrls: ['./page-metrics.component.scss'],
})
export class PageMetricsComponent implements OnInit {

  @Input() pages: Array<Page>;
  @Input() contents: Array<Content>;
  @Input() viewMetrics: Array<String>;
  @Input() strategyJp: string;
  @Input() selectedMetricName: any;

  @Output() changeViewMetric = new EventEmitter<string>();

  @Input() measurementStandard: any;

  @Input() configId: number;

  constructor(
    private scenarioDetailService: ScenarioDetailService,
    private reportService: ReportService,
    private configService: ScenarioConfigService,
    private userService: UserService,
  ) { }

  subscriptions: Array<Subscription> = [];

  metrics: Array<string> = [];

  screenShots: Array<Object> = [];

  metricsPerPage: Array<Object> = [];

  contentPerPage: Array<Object> = [];

  // 各シナリオの指標名と平均値のリスト
  metricsAvarage: Array<{metricName: string, value: number}> = [];

  metricsValues: Array<number> = [];

  data: Object;

  options: Object;

  pageNames: Array<string> = [];

  pageReport: Array<Object> = [];

  type = 'bar';

  barColorArray: Array<string> = [];

  plan: number;

  userRole: number;

  progress: boolean = false;

  progressvalue: number = 20;

  ngOnInit() {
    this.subscriptions.push(this.scenarioDetailService.getSelectedMetrics().subscribe(data => {
      this.metrics = data;
      this.metricsPerPage = this.metrics.map(metric => {
        const values = this.pages.map(page => {
          let valueNum = page.metrics[metric];
          if( metric === 'TBT' || metric === 'CLS' ){
            valueNum = valueNum ? valueNum : '0';
          }
          const status = valueNum ? this.scenarioDetailService.getMetricStatus(this.measurementStandard, metric, valueNum) : '';
          return {valueNum: (valueNum || null), status: status };
        });
        if (values[0].valueNum == null && this.contents ) {
          const newvalues = this.contents.map(page => {
            let valueNum = ( metric === 'CTS' || metric === 'CBS' ) ? Number((page.contents[metric] * WebVitalsConst.BYTE_TO_MB).toFixed(1)) : page.contents[metric];
            if( metric === 'TBT' || metric === 'CLS' ){
              valueNum = valueNum ? valueNum : '0';
            }
            const status = valueNum ? this.scenarioDetailService.getMetricStatus(null, metric, valueNum) : '';
            return {valueNum: (valueNum || null), status: status };
          });
          return { metricName: metric, values: newvalues };
        }
        return { metricName: metric, values: values };
      });

      const metricOrder = this.scenarioDetailService.getMetricSortOrder;
      this.sortByMetricOrder(metricOrder);
    }));
    this.metricsAvarage = this.setMetricsAvarage(this.metricsPerPage);

    this.screenShots = this.pages.map(page => {
      return { url: page.screenshotUrl, pageName: page.pageName };
    });

    this.pageNames = this.pages.map(page => {
      return page.pageName;
    });

    this.changeChart(this.selectedMetricName[this.strategyJp]);
  }

  changeChart(metric: string) {
    this.metricsAvarage = this.metricsAvarage;
    this.selectedMetricName[this.strategyJp] = metric;
    this.metricsValues = this.pages.map(page => {
      return page.metrics[metric];
    });
    if (this.metricsValues[0] == null) {
      this.metricsValues = this.contents.map(content => {
        if (metric === 'PRN')
          return content.contents[metric];
        else
          return content.contents[metric] * WebVitalsConst.BYTE_TO_MB;
      });
    }

    this.data = {
      labels: this.pageNames,
      datasets: [
        {
          label: metric,
          data: this.metricsValues,
          backgroundColor: this.thresholdDecision (this.metricsValues, metric),
        },
      ],
    };

    this.options = {
      legend: {
        display: false,
        position: 'right',
        labels: {
            fontColor: 'rgb(255, 99, 132)',
            boxWidth: 13,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          id: 'x-axis',
          categoryPercentage: 0.2,
        }],
        yAxes: [{
          id: 'y-axis',
          ticks: this.setScalesYAxes(metric),
        }],
      },
      annotation: {
        annotations: this.setChartAnnotationGood(metric),
        drawTime: 'beforeDatasetsDraw',
      },
    };

    this.changeViewMetric.emit(this.selectedMetricName);
  }

  setScalesYAxes(metricName: string) {
    let ScalesYAxes: number = 0;

    if (metricName === 'TTFB') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.TTFB_BORDER_BAD : WebVitalsConst.TTFB_BORDER_BAD) * 3;
      return {
        min: 0,
        suggestedMax: ScalesYAxes,
        callback: function(value, index, values) {
          return('      ' + value).substr(-5);
         },
      };
    }
    // FIDを削除(RS-890)
    // else if (metricName === 'FID') {
    //   ScalesYAxes = WebVitalsConst.FID_BORDER_BAD * 3;
    // }
    if (metricName === 'LPS') {
      ScalesYAxes = 100;
    } else if (metricName === 'LCP') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.LCP_BORDER_BAD : WebVitalsConst.LCP_BORDER_BAD) * 3;
    } else if (metricName === 'CLS') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.CLS_BORDER_BAD : WebVitalsConst.CLS_BORDER_BAD) * 3;
    } else if (metricName === 'FCP') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.FCP_BORDER_BAD : WebVitalsConst.FCP_BORDER_BAD) * 3;
    } else if (metricName === 'SI') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.SI_BORDER_BAD : WebVitalsConst.SI_BORDER_BAD) * 3;
    } else if (metricName === 'TBT') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.TBT_BORDER_BAD : WebVitalsConst.TBT_BORDER_BAD) * 3;
    } else if (metricName === 'TTI') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.TTI_BORDER_BAD : WebVitalsConst.TTI_BORDER_BAD) * 3;
    }
    return {
      min: 0,
      suggestedMax: ScalesYAxes,
      callback: function(value, index, values) {
        // グラフの左側のメモリの余白を調節するためにスペースを挿入
        // 少数以下が発生する値の場合、少数点以下一桁で切り捨てする
        return ('      ' + (Math.floor(value * 10) / 10)).substr(-5);
      },
    };
  }

  thresholdDecision(metricsValues: number[], metricName: string) {
    let borderGood: number = 0;
    let borderBad: number = 0;
    // FIDを削除(RS-890)
    // else if (metricName === 'FID') {
    //   borderGood = WebVitalsConst.FID_BORDER_GOOD;
    //   borderBad = WebVitalsConst.FID_BORDER_BAD;
    // }
    if (metricName === 'LPS') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.LPS_BORDER_GOOD : WebVitalsConst.LPS_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.LPS_BORDER_BAD : WebVitalsConst.LPS_BORDER_BAD);
    } else if (metricName === 'LCP') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.LCP_BORDER_GOOD : WebVitalsConst.LCP_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.LCP_BORDER_BAD : WebVitalsConst.LCP_BORDER_BAD);
    } else if (metricName === 'CLS') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.CLS_BORDER_GOOD : WebVitalsConst.CLS_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.CLS_BORDER_BAD : WebVitalsConst.CLS_BORDER_BAD);
    } else if (metricName === 'FCP') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.FCP_BORDER_GOOD : WebVitalsConst.FCP_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.FCP_BORDER_BAD : WebVitalsConst.FCP_BORDER_BAD);
    } else if (metricName === 'TTFB') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.TTFB_BORDER_GOOD : WebVitalsConst.TTFB_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.TTFB_BORDER_BAD : WebVitalsConst.TTFB_BORDER_BAD);
    } else if (metricName === 'SI') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.SI_BORDER_GOOD : WebVitalsConst.SI_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.SI_BORDER_BAD : WebVitalsConst.SI_BORDER_BAD);
    } else if (metricName === 'TBT') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.TBT_BORDER_GOOD : WebVitalsConst.TBT_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.TBT_BORDER_BAD : WebVitalsConst.TBT_BORDER_BAD);
    } else if (metricName === 'TTI') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.TTI_BORDER_GOOD : WebVitalsConst.TTI_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.TTI_BORDER_BAD : WebVitalsConst.TTI_BORDER_BAD);
    }

    for (let i = 0; i < metricsValues.length; i++) {
      // LPSの場合、良い・悪い評価
      if (metricName === 'LPS') {
        if (metricsValues[i] >= borderGood) {
          this.barColorArray[i] = '#31d399';
        } else if (borderGood > metricsValues[i] && borderBad <= metricsValues[i]) {
          this.barColorArray[i] = '#ffd966';
        } else {
          this.barColorArray[i] = '#ff5757';
        }
      } else {
        if (metricsValues[i] <= borderGood) {
          this.barColorArray[i] = '#31d399';
        }else if (borderGood < metricsValues[i] && borderBad >= metricsValues[i]) {
          this.barColorArray[i] = '#ffd966';
        }else {
          this.barColorArray[i] = '#ff5757';
        }
      }
      if (metricName === 'CTS' || metricName === 'CBS' || metricName === 'PRN') {
        this.barColorArray[i] = '#3b08a1';
      }
    }
    return this.barColorArray;
  }

  setChartAnnotationGood(metric: string): any {
    let borderGood: number = 0;
    let borderBad: number = 0;
    // FIDを削除(RS-890)
    // else if (metric === 'FID') {
    //   borderGood = WebVitalsConst.FID_BORDER_GOOD;
    //   borderBad = WebVitalsConst.FID_BORDER_BAD;
    // }
    if (metric === 'LPS') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.LPS_BORDER_GOOD : WebVitalsConst.LPS_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.LPS_BORDER_BAD : WebVitalsConst.LPS_BORDER_BAD);
    } else if (metric === 'LCP') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.LCP_BORDER_GOOD : WebVitalsConst.LCP_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.LCP_BORDER_BAD : WebVitalsConst.LCP_BORDER_BAD);
    } else if (metric === 'CLS') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.CLS_BORDER_GOOD : WebVitalsConst.CLS_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.CLS_BORDER_BAD : WebVitalsConst.CLS_BORDER_BAD);
    } else if (metric === 'FCP') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.FCP_BORDER_GOOD : WebVitalsConst.FCP_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.FCP_BORDER_BAD : WebVitalsConst.FCP_BORDER_BAD);
    } else if (metric === 'TTFB') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.TTFB_BORDER_GOOD : WebVitalsConst.TTFB_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.TTFB_BORDER_BAD : WebVitalsConst.TTFB_BORDER_BAD);
    } else if (metric === 'SI') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.SI_BORDER_GOOD : WebVitalsConst.SI_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.SI_BORDER_BAD : WebVitalsConst.SI_BORDER_BAD);
    } else if (metric === 'TBT') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.TBT_BORDER_GOOD : WebVitalsConst.TBT_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.TBT_BORDER_BAD : WebVitalsConst.TBT_BORDER_BAD);
    } else if (metric === 'TTI') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.TTI_BORDER_GOOD : WebVitalsConst.TTI_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.TTI_BORDER_BAD : WebVitalsConst.TTI_BORDER_BAD);
    }

    if (!borderGood && !borderBad) {
      return [];
    }

    if (metric === 'LPS') {
      return [
        {
          type: 'box',
          id: 'y-axis-good',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMax: 100,
          yMin: borderGood,
          backgroundColor: 'rgba(0, 255, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(255, 0, 0, 0)',
        },
        {
          type: 'box',
          id: 'y-axis-safe',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: borderBad,
          yMax: borderGood,
          backgroundColor: 'rgba(255, 192, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(255, 192, 0, 0)',
        },
        {
          type: 'box',
          id: 'y-axis-bad',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: 0,
          yMax: borderBad,
          backgroundColor: 'rgba(255, 0, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(0, 176, 80, 0)',
        },
      ];
    } else {
      return [
        {
          type: 'box',
          id: 'y-axis-good',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: 0,
          yMax: borderGood,
          backgroundColor: 'rgba(0, 176, 80, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(0, 176, 80, 0)',
        },
        {
          type: 'box',
          id: 'y-axis-safe',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: borderGood,
          yMax: borderBad,
          backgroundColor: 'rgba(255, 192, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(255, 192, 0, 0)',
        },
        {
          type: 'box',
          id: 'y-axis-bad',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: borderBad,
          backgroundColor: 'rgba(255, 0, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(255, 0, 0, 0)',
        },
      ];
    }
  }

  // 指標の並び替え
  sortByMetricOrder(metricOrder: Array<string>) {
    this.metricsPerPage.sort(function (a, b) {
      if (metricOrder.indexOf(a['metricName']) > metricOrder.indexOf(b['metricName'])) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  isSelectedMetric(metric: String) {
    let isSelected: boolean = false;
    this.viewMetrics.forEach(selectMetric => {
      if (selectMetric === metric) {
        isSelected = true;
      }
    });
    return isSelected;
  }

  /**
   * 指標ごとの各画面のスコアを集計し、平均値を計算する。
   * @param metricsPerPage 画面ごとの指標スコアのリスト
   * @returns {Array<{metricName: string, value: number}>} 指標名と平均値のリスト
   */
  private setMetricsAvarage(metricsPerPage: Array<any>): Array<{metricName: string, value: number}> {
    // 指標名と平均値のリストを初期化
    const metricsAvarage: Array<{metricName: string, value: number}> = [];
    // 指標ごとに集計をおこなう
    metricsPerPage.forEach(metrics => {
      // metricsPerPageから指標スコアのみの配列を取得する
      const metricValues = metrics.values.map(function(pages){
        return [pages.valueNum];
      }).reduce(function(a, b){
        if (b[0]) { return a.concat(b); }
        return a;
      });
      // 指標スコアの合計値を取得する
      const metricSum = metricValues.reduce(function(cumulative, value){ return cumulative + value; });
      // 指標名とスコアの平均値をmetricsAvarageに追加する
      if (metrics.metricName === 'PRN') {
        metricsAvarage.push({
          metricName: metrics.metricName,
          value: Number((metricSum / metricValues.length).toFixed(0)),
        });
      } else {
        metricsAvarage.push({
          metricName: metrics.metricName,
          value: Number((metricSum / metricValues.length).toFixed(1)),
        });
      }
    });
    return metricsAvarage;
  }
}
