import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupConfigService } from '../../@core/data/group-config.service';
import { UserService } from '../../@core/data/users.service';

@Component({
  selector: 'ngx-user-configs',
  templateUrl: './user-configs.component.html',
  styleUrls: ['./user-configs.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class UserConfigsComponent implements OnInit {
  isLoading: boolean = true;          // ページローディングフラグ
  loadingConfig: any = {};            // ローディング処理設定

  role_id;                            // ログインユーザーの権限

  groupConfig: any = [];              // 所属するグループ情報
  roles: any = [];                    // 検索エリアの権限を格納するリスト
  userConfigs: any = [];              // ユーザー一覧のデータを格納するリスト
  page = 1;                           // ページャーのページ番号
  pageSize = 10;                      // ページャーが１ページに表示するデータの数

  isSubmitted = false;                // モーダルのSubmitボタン押下のフラグ
  modalMessage: string = '';          // モーダルに表示するメッセージ
  addUserData = new FormData();       // モーダルの登録・更新時に送信するフォームデータ

  modalDisabled: boolean = false;     // モーダルのオープンの制御フラグ
  submitDisabled: boolean = false;    // モーダルの登録・更新ボタンの制御フラグ

  runResults: any = [];               // 実行結果一覧のデータを格納するリスト
  groupandUserConfigs: any = [];      // 設定されたグループに追加可能なユーザーリスト

  // ユーザー検索フォーム　Validation設定
  searchUserForm = this.formBuilder.group({
    searchUserName: [''],
    searchRoleId: ['0'],
    searchEmail: [''],
  });

  // ユーザー登録フォーム
  addUserForm;

  // ユーザー編集フォーム
  editUserForm;
  // グループ設定の追加可能のユーザーリストフォーム
  AddUsertoGroupForm;


  constructor(protected configService: GroupConfigService,
              public formBuilder: FormBuilder,
              protected userService: UserService,
              config: NgbModalConfig,
              private modalService: NgbModal) {

    // ログインユーザーの権限を取得
    this.userService.getUsers().subscribe(user => {
      this.role_id = user.role_id;

      // グループIDが無い場合はグループ設定の画面に遷移する
      if (!this.configService.groupId) {
        if (this.role_id <= 3) {
          location.href = '/dashboard/synthetic#/pages/group-configs';
        } else {
          location.href = '/dashboard/synthetic#/pages/group-config';
        }
      }
    });

    // 所属するグループの情報を取得
    this.configService.getGroupConfig(this.configService.groupId).subscribe(response => {
      this.groupConfig = response;
    });

    // モーダルの設定
    config.backdrop = 'static';
    config.keyboard = false;
    config.windowClass = 'configs-modal';
  }

  ngOnInit(): void {
    // 検索エリアの権限のリストを取得
    this.configService.getUserRoles().subscribe(response => {
      this.roles = response;
    });

    // ユーザー一覧初期表示
    this.searchUserConfigs();
  }

  /**
   * ユーザー一覧に表示するデータを取得する
   */
  searchUserConfigs(): void {
    // ローディングのエフェクト開始
    this.isLoading = true;

    this.configService.getUserConfigs(this.searchRoleId.value,
                                      this.searchUserName.value,
                                      this.searchEmail.value)
      .subscribe(response => {
      this.userConfigs = response;

      // ローディングのエフェクト終了
      this.isLoading = false;
    });
  }

  /**
   * ユーザー一覧のモーダル表示
   * @param userlist
   */
   showUserList(userlist): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    
    this.configService.getUserListConfigs(this.groupConfig.id).subscribe((response) => {
      this.groupandUserConfigs = response;
      this.AddUsertoGroupForm = this.formBuilder.group({});
      this.groupandUserConfigs.forEach(user => {
        this.AddUsertoGroupForm.addControl(`user_${user.user_id}`, new FormControl(false));
      });
        this.modalService.open(userlist);
        this.modalDisabled = false;
      });
  }

  /**
   * ユーザーリストモーダル　追加ボタン押下処理 
   */
  onAddUsertoGroupSubmit(): boolean {
    this.submitDisabled = true;
    this.isSubmitted = true;

    // バリデーションのチェックに違反する場合は処理を中断する
    if (this.AddUsertoGroupForm.invalid) {
      this.modalMessage = '入力内容が正しくありません。';
      this.submitDisabled = false;
      return false;
    }

    const selectedIds: number[] = [];

    this.groupandUserConfigs.forEach(user => {
      const control = this.AddUsertoGroupForm.get(`user_${user.user_id}`);
      if (control.value) {
        selectedIds.push(user.user_id);
      }
    });

    // 選択したユーザーリストとグループIDをサーバに送信する
    this.configService.addUsertoGroupConfigs(selectedIds,this.groupConfig.id).subscribe(response => {
      if (response.errors) {
        this.modalMessage = response.errors.message;
      } else {
        this.closeModal(response);
      }
      this.submitDisabled = false;
    });
    this.isSubmitted = false;
  }

  /**
   * ユーザー登録モーダル表示
   * @param addUser
   */
  openAddUser(addUser): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.addUserData = new FormData();

    // ユーザー登録フォーム　Validation設定
    this.addUserForm = this.formBuilder.group({
      addRoleId: ['', [Validators.required]],
      addEmail: ['', [Validators.required, Validators.email, this.addEmailValidator, Validators.maxLength(191)]],
      addPassword: ['', [Validators.required, Validators.maxLength(20)]],
      addUserName: ['', [Validators.required, Validators.maxLength(191)]],
    });

    if (this.role_id > 3) {
      this.addRoleId.value = 5;
    }

    this.modalService.open(addUser);
    this.modalDisabled = false;
  }

  /**
   * ユーザー編集モーダル表示
   * @param editUser
   */
  openEditUser(userId, editUser): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.addUserData = new FormData();

    // ユーザー編集モーダルに必要な情報を取得する
    this.configService.getUserConfig(userId).subscribe(response => {
      if (response.success) {
        const param = response.success.param;
        // ユーザー編集フォーム　Validation設定
        this.editUserForm = this.formBuilder.group({
          editUserId: [param.id],
          editRoleId: [param.role_id, [Validators.required]],
          editEmail: [param.email, [
                                    Validators.required,
                                    Validators.email,
                                    this.addEmailValidator,
                                    Validators.maxLength(191),
                                  ]],
          editUserName: [param.name, [Validators.required, Validators.maxLength(191)]],
          editPassword: [],
        });

        this.modalService.open(editUser);
        this.modalDisabled = false;
      } else {
        this.closeModal(response);
      }
    });
  }

  /**
   * ユーザー登録モーダル　登録ボタン押下処理
   */
  onAddUserSubmit(): boolean {
    this.submitDisabled = true;
    this.isSubmitted = true;

    // グループの登録件数制限を超えている場合は処理を中断する
    /*
    const groupId = this.addGroupId.value;
    const groupUser = this.userConfigs.filter(function(item){
      if (item.group_id === groupId) return true;
    });
    if (groupUser.length >= 5) {
      this.modalMessage = '登録件数オーバー：' + groupUser[0].group_name + 'のユーザーは' + groupUser.length + '件まで登録できます。';
      this.submitDisabled = false;
      return false;
    }
*/
    // 入力内容をフォームデータに設定する
    this.addUserData.append('addRoleId', this.addRoleId.value);         // 権限
    this.addUserData.append('addEmail', this.addEmail.value);           // メールアドレス
    this.addUserData.append('addPassword', this.addPassword.value);     // パスワード
    this.addUserData.append('addUserName', this.addUserName.value);     // ユーザー名
    this.addUserData.append('addGroupId', this.configService.groupId);  // グループID

    // フォームデータをサーバに送信する
    this.configService.addUserConfigs(this.addUserData).subscribe(response => {
      if (response.errors) {
        this.modalMessage = response.errors.message;
      } else {
        // ユーザー一覧をリロードする
        this.searchUserConfigs();
        // モーダルを閉じる
        this.modalService.dismissAll('Submit Success');
      }
      this.submitDisabled = false;
    });

    return true;
  }

  /**
   * Validators.emailのチェックを補助
   * 'test@test'の形式の入力がOKになるので、こちらでエラー判定にする。
   * @param formControl
   */
  private addEmailValidator(formControl: AbstractControl) {
    const val = formControl.value;
    const reg = /.*\.[A-Za-z0-9]{1,}$/;

    return !reg.test(val) ? { addEmailValidator: true } : null;
  }

  /**
   * ユーザー編集モーダル　パスワードリセット押下処理
   */
  passwordReset(): void {
    this.editPassword.setValue('test');
  }

  /**
   * ユーザー編集モーダル　更新ボタン押下処理
   */
  onEditUserSubmit(): boolean {
    this.submitDisabled = true;
    this.isSubmitted = true;

    // バリデーションのチェックに違反する場合は処理を中断する
    if (this.editUserForm.invalid) {
      this.modalMessage = '入力内容が正しくありません。';
      this.submitDisabled = false;
      return false;
    }

    // 入力内容をフォームデータに設定する
    this.addUserData.append('editUserId', this.editUserId.value);         // ユーザーID
    this.addUserData.append('editRoleId', this.editRoleId.value);         // 権限
    this.addUserData.append('editEmail', this.editEmail.value);           // メールアドレス
    this.addUserData.append('editUserName', this.editUserName.value);     // ユーザー名
    this.addUserData.append('editPassword', this.editPassword.value);     // パスワード

    // フォームデータをサーバに送信する
    this.configService.editUserConfigs(this.addUserData).subscribe(response => {
      if (response.errors) {
        this.modalMessage = response.errors.message;
      } else {
        this.closeModal(response);
      }
      this.submitDisabled = false;
    });

    this.isSubmitted = false;
  }

  /**
   * ユーザー編集モーダル　削除ボタン押下処理
   */
  deleteUser(): void {
    this.submitDisabled = true;
    if (window.confirm('このユーザーを削除しますか？')) {
      this.configService.deleteUser(this.editUserId.value).subscribe(response => {
        this.closeModal(response);
      });
    } else {
      this.submitDisabled = false;
    }
  }

  /**
   * モーダルを閉じてユーザー一覧をリロードする
   */
  private closeModal(response) {
    if (response.exception) {
      alert(response.exception.message);
    }

    if (response.success) {
      alert(response.success.message);
    }

    // ユーザー一覧をリロードする
    this.searchUserConfigs();
    // モーダルを閉じる
    this.modalService.dismissAll('Submit Success');
    this.submitDisabled = false;
  }

  /**
   * getter 検索エリア
   */
  // ユーザー名
  get searchUserName(){
    return this.searchUserForm.get('searchUserName');
  }

  // 権限
  get searchRoleId(){
    return this.searchUserForm.get('searchRoleId');
  }

  // メールアドレス
  get searchEmail(){
    return this.searchUserForm.get('searchEmail');
  }

  /**
   * getter ユーザー登録フォーム
   */
  // 権限
  get addRoleId(){
    return this.addUserForm.get('addRoleId');
  }

  // メールアドレス
  get addEmail(){
    return this.addUserForm.get('addEmail');
  }

  // パスワード
  get addPassword(){
    return this.addUserForm.get('addPassword');
  }

   // ユーザー名
  get addUserName(){
    return this.addUserForm.get('addUserName');
  }

  /**
   * getter ユーザー編集フォーム
   */
  // ユーザーID
  get editUserId(){
    return this.editUserForm.get('editUserId');
  }

  // 権限
  get editRoleId(){
    return this.editUserForm.get('editRoleId');
  }

  // メールアドレス
  get editEmail(){
    return this.editUserForm.get('editEmail');
  }

  // ユーザー名
  get editUserName(){
    return this.editUserForm.get('editUserName');
  }

  // パスワード
  get editPassword(){
    return this.editUserForm.get('editPassword');
  }
}
