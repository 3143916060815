import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { ScenarioSummaryComponent } from './scenario-summary.component';
import { SummaryComponent } from './summary/summary.component';

const routes: Routes = [{
  path: '',
  component: ScenarioSummaryComponent,
  children: [{
    path: '',
    component: SummaryComponent,
  }, {
    path: 'detail',
    loadChildren: () => import('./detail/detail.module').then(m => m.DetailModule),
  }],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ScenarioSummaryRoutingModule {
}
