import { NgModule } from '@angular/core';
import { ScenarioSummaryComponent } from './scenario-summary.component';
import { ScenarioSummaryRoutingModule } from './scenario-summary-routing.module';
import { SummaryComponent } from './summary/summary.component';
import { LoadingModule } from 'ngx-loading';

import { ThemeModule } from '../../@theme/theme.module';
import { DetailModule } from './detail/detail.module';

import { ScenarioDetailService } from './../../@core/data/scenario-detail.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MailService } from './../../@core/data/mail.service';

@NgModule({
  declarations: [ScenarioSummaryComponent, SummaryComponent],
  imports: [
    ScenarioSummaryRoutingModule,
    ThemeModule,
    LoadingModule,
    DetailModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [ScenarioDetailService, MailService],
})
export class ScenarioSummaryModule {}
