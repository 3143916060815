import { Component, ViewChild, OnDestroy, OnInit, Input } from '@angular/core';
import { NbThemeService, NbColorHelper } from '@nebular/theme';
import { SearchService } from '../../../@core/data/search.service';
import { ElasticsearchService } from '../../../@core/data/elasticsearch.service';
import { ChartComponent } from 'angular2-chartjs';
import { LoadingService } from '../../../@core/data/loading.service';
import { Router } from '@angular/router';
import spdIndexDataJson from 'assets/query/pages/charts/spdIndex_data.json';
import fmpTotalJson from 'assets/query/pages/charts/fmp_total.json';
import psiDataJson from 'assets/query/pages/charts/psi_data.json';
import javascriptAvgJson from 'assets/query/pages/charts/javascript_avg.json';
import fileMinifyJson from 'assets/query/pages/charts/file_minify.json';
import fileCompJson from 'assets/query/pages/charts/file_compression.json';
import fileOptJson from 'assets/query/pages/charts/file_optimisation.json';

/**
 * 下記グラフのレンダリングを行うコンポネント
 * - SpeedIndexスコア 中央値
 * - 画面毎のユーザ体感速度(FMP) 中央値
 * - Page Speed Insight スコア 中央値
 * - Javascriptファイルごとの処理時間一覧
 * - Minifyによる容量削減対象ファイル一覧
 * - 圧縮でファイル容量削減可能なファイル一覧
 * - 最適化によるファイル容量作成可能な画像一覧
 */
@Component({
  selector: 'ngx-chartjs-bar',
  template: `
    <chart type="bar" [hidden]="!isShow" *ngIf="isCompInit && !isNoData" [data]="data" [options]="options"
    (clickElement)="clickChart($event)"></chart>
    <div *ngIf="isNoData"> 計測結果は0件です。条件を変更し再検索してください。 </div>
  `,
})
export class ChartjsBarComponent implements OnDestroy, OnInit {
  /** Chartコンポネントオブジェクト */
  @ViewChild(ChartComponent, {static: false}) chart: ChartComponent;
  /** グラフタイプ(HTML入力) */
  @Input() type: string = '';
  /** グラフカテゴリ(HTML入力) */
  @Input() category: string = '';
  /** グラフレベル(HTML入力) */
  @Input() label: string = '';
  /** グラフ値単位(HTML入力) */
  @Input() unit: string = '';
  /** コンポネント初期化フラフ */
  isCompInit: boolean = false;
  /** グラフ表示用データ */
  data: any;
  /** グラフ設定 */
  options: any;
  /** 検索用Subscribeオブジェクト */
  searchSubscription: any;
  /** テーマ用Subscribeオブジェクト */
  themeSubscription: any;
  /** Elasticsearch用Subscribeオブジェクト */
  elasticSubscription: any;
  /** 検索条件 */
  condition: any = {};
  /** グラフの線、レブル等用色 */
  colors: any;
  /** ChartJS用色 */
  chartjs: any;
  /** サイトのURL・サイト名マップ */
  allSiteNameMap: any = {};
  /** グラフの表示フラグ */
  isShow: boolean = false;
  /** 計測データの有無 */
  isNoData: boolean = false;

  constructor(
    private theme: NbThemeService,
    protected searchService: SearchService,
    protected elasticsearchService: ElasticsearchService,
    protected loadingService: LoadingService,
    private router: Router,
  ) { }

  /**
   * コンポネント初期処理
   */
  ngOnInit(): void {
    let init = false;
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      this.condition = this.searchService.getCondition();
      this.colors = config.variables.temperature;
      this.chartjs = config.variables.chartjs;
      this.allSiteNameMap = this.searchService.getAllSiteNameMap();

      if (init) {
        return;
      }
      init = true;
      // 検索条件が変わった際のイベントを登録
      this.searchSubscription = this.searchService.getConditionState().subscribe(res => {
        this.isShow = false;
        this.stopElasticRequest();
        this.condition = this.searchService.getCondition();
        this.allSiteNameMap = this.searchService.getAllSiteNameMap();
        this.serach(this.condition);
      });
      // ヘッダ初期化されていなければ初期化を待つ。
      if (Object.getOwnPropertyNames(this.allSiteNameMap).length !== 0) {
        this.serach(this.condition);
      } else {
        setTimeout(() => {
          if (!this.loadingService.getLoadingStatus()) {
            this.loadingService.setLoadConditionStatus();
          }
        });
      }
    });
  }

  /**
   * コンポーネント破棄時処理
   */
  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
    this.searchSubscription.unsubscribe();
    this.stopElasticRequest();
  }

  /**
   * Elasticsearchリクエストを中止
   */
  stopElasticRequest(): void {
    if (this.elasticSubscription) {
      this.elasticSubscription.unsubscribe();
    }
  }

  /**
   * サーバ側でクエリを検索する処理
   * @param condition 検索条件
   */
  private serach(condition) {
    // ESにクエリを発行
    const comp = this;
    let replacePgNameFlag = true;
    let jsonConfig;

    switch (comp.type) {
      // SpeedIndexスコア 中央値
      case 'spdIndex_data':
        jsonConfig = spdIndexDataJson;
        break;
      // 画面毎のユーザ体感速度(FMP) 中央値
      case 'fmp_total':
        jsonConfig = fmpTotalJson;
        break;
      // Page Speed Insight スコア 中央値
      case 'psi_data':
        jsonConfig = psiDataJson;
        break;
      // Javascriptファイルごとの処理時間一覧
      case 'javascript_avg':
        jsonConfig = javascriptAvgJson;
        replacePgNameFlag = false;
        break;
      // Minifyによる容量削減対象ファイル一覧
      case 'file_minify':
        jsonConfig = fileMinifyJson;
        replacePgNameFlag = false;
        break;
      // 圧縮でファイル容量削減可能なファイル一覧
      case 'file_compression':
        jsonConfig = fileCompJson;
        replacePgNameFlag = false;
        break;
      // 最適化によるファイル容量作成可能な画像一覧
      case 'file_optimisation':
        jsonConfig = fileOptJson;
        replacePgNameFlag = false;
        break;
    }

    if (jsonConfig.length !== 0) {
      // const query: any = jsonConfig[0];
      const query: any = JSON.parse(JSON.stringify(jsonConfig[0])); // deep copy
      query.body.query.bool.must[0].range = {
        '@timestamp':
        {
          'gte': condition.start,
          'lte': condition.end,
          'format': 'epoch_millis',
        },
      };
      const strategyQuery = {'term' : {'strategy': condition.device}};
      query.body.query.bool.must.push(strategyQuery);
      // fileサイズ関連の集計時にはfilterがないjsonクエリなので、適用しない。
      if ( comp.category !== 'file') {
        query.body.aggs.page_aggs.aggs.sort_terms.filter.term.strategy = condition.device; // sort条件
      }
      this.elasticSubscription = comp.elasticsearchService.searchWithProxy(query, replacePgNameFlag)
        .subscribe(response => {
          // 結果がない場合は処理を行わない。
          if (!response.aggregations) {
            if (!comp.loadingService.getLoadingStatus()) {
              comp.loadingService.setLoadConditionStatus();
            }
            this.isNoData = true;
            return;
          }
          comp.parseSearchData(response);

          if ('datasets' in this.data && this.data.datasets.length === 0) {
            if (!comp.loadingService.getLoadingStatus()) {
              comp.loadingService.setLoadConditionStatus();
            }
            this.isNoData = true;
            return;
          }

          comp.draw();
          comp.isShow = true;
          if (!comp.loadingService.getLoadingStatus()) {
            comp.loadingService.setLoadConditionStatus();
          }
        },
      );
    }
  }

  /**
   * 検索結果のデータを利用し、各グラフデータを作成
   * @param resp 検索結果レスポンス
   */
  private parseSearchData(resp: any) {
    const response_page = resp.aggregations.page_aggs.buckets;
    const labels: any[] = [];
    const datasets: any[] = [];
    let dataset_color;
    let page_dataset;
    for (let i = 0; i < response_page.length; i++) {
      labels[i] = response_page[i].key;

      // 集約階層が1段階の場合、1系列のみのデータを集約
      if (!response_page[i].page_terms) {
        page_dataset = datasets[0];
        if (i < this.colors.length) {
          dataset_color = this.colors[i];
        } else {
          dataset_color = NbColorHelper.hexToRgbA(this.colors[Math.floor(Math.random() * this.colors.length)], 0.8);
        }
        if (!page_dataset) {
          page_dataset = {
            data: [],
            label: this.label,
            backgroundColor: dataset_color,
            borderColor: dataset_color,
          };
          datasets[0] = page_dataset;
        }
        page_dataset.data.push(response_page[i].page_avg.value);

        continue;
      }

      // 集約階層が2段階の場合、系列をを分けて集約
      const page_terms = response_page[i].page_terms.buckets;
      let page_term_dataset;
      for (let j = 0; j < page_terms.length; j++) {
        page_term_dataset = datasets[j];
        if (j < this.colors.length) {
          dataset_color = this.colors[j];
        } else {
          dataset_color = NbColorHelper.hexToRgbA(this.colors[Math.floor(Math.random() * this.colors.length)], 0.8);
        }
        if (!page_term_dataset) {
          page_term_dataset = {
            data: [],
            label: page_terms[j].key,
            backgroundColor: dataset_color,
            borderColor: dataset_color,
          };
          datasets[j] = page_term_dataset;
        }
        // medianの場合との分岐
        if (page_terms[j].page_median) {
          page_term_dataset.data.push(page_terms[j].page_median.values[0].value);
        } else {
          page_term_dataset.data.push(page_terms[j].page_avg.value);
        }
      }
    }

    this.data = {
      labels: labels,
      datasets: datasets,
    };
  }

  /**
   * レスポンスをもとにグラフを描画する。
   */
  private draw() {
    if (!this.options) {
      const unit = this.unit;

      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        animation:
        {
            duration: 0,
        },
        legend: {
          display: false,
          // labels: {
          //   fontColor: this.chartjs.textColor,
          // },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                color: this.chartjs.axisLineColor,
              },
              ticks: {
                fontColor: this.chartjs.textColor,
                callback: function (value) {
                  // 13文字以上は省略
                  return value.length < 14 ? value : value.substring(0, 12) + '…';
                },
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: this.chartjs.axisLineColor,
              },
              scaleLabel: {
                display: true,
                labelString: unit,
              },
              ticks: {
                fontColor: this.chartjs.textColor,
                beginAtZero: true,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem) {
              const value = this._data.labels[tooltipItem[0].index];
              const nlValue = value.substring(0, 70) + '\n' + value.substring(69, value.length);
              return value.length < 70 ? value : nlValue.split('\n');
            },
            label: function (tooltipItem, data) {
              // msec/byte単位の想定のため、小数点以下切り捨て
              return this._data.datasets[tooltipItem.datasetIndex].label
                     + ':' + Math.floor(tooltipItem.yLabel) + ' ' + unit;
            },
          },
        },
      };
      this.isCompInit = true;
    }
  }

  /**
   * Chartの棒をクリック処理
   * @param event クリックイベント
   */
  clickChart(event) {
    if (event.length > 0) {
      const selectedValue = this.allSiteNameMap[event[0]._model.label];
      if (selectedValue) {
        this.searchService.setDrillDownOption(selectedValue);
        this.searchService.setDrillDownConditionStatus();
        this.router.navigate(['/pages/analysis'], { queryParams: { selectedSite: selectedValue } });
      }
    }
  }
}
