import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DetailRoutingModule } from './detail-routing.module';
import { DetailComponent } from './detail.component';
import { ThemeModule } from '../../../@theme/theme.module';
import { ChronologicalComponent } from './tabs/chronological/chronological.component';
import { PageTransitionComponent } from './tabs/page-transition/page-transition.component';
import { StepComponent } from './tabs/step/step.component';

import { ScenarioDetailService } from './../../../@core/data/scenario-detail.service';
import { PageInfoComponent } from './tabs/page-transition/page-info/page-info.component';
import { StepInfoComponent } from './tabs/step/step-info/step-info.component';

import { PageMetricsComponent } from './tabs/page-transition/page-info/page-metrics/page-metrics.component';
import { LighthouseReportComponent } from './tabs/lighthouse-report/lighthouse-report.component';
import { LighthouseReportInfoComponent } from './tabs/lighthouse-report/lighthouse-report-info/lighthouse-report-info.component';
import { WaterfallChartComponent } from './tabs/waterfall-chart/waterfall-chart.component';
import { WaterfallChartInfoComponent } from './tabs/waterfall-chart/waterfall-chart-info/waterfall-chart-info.component';

import { ChartModule } from 'angular2-chartjs';
import { TimelineInfoComponent } from './tabs/chronological/timeline-info/timeline-info.component';
import { TimelineChartComponent } from './tabs/chronological/timeline-info/timeline-chart/timeline-chart.component';
import { LoadingModule } from 'ngx-loading';
import { AvarageBoxComponent } from './avarage-box/avarage-box.component';
import { PageDetailComponent } from './tabs/page-detail/page-detail.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { PageloadtimeComponent } from './tabs/chronological/pageloadtime/pageloadtime.component';
import { PageloadtimeChartComponent } from './tabs/chronological/pageloadtime/pageloadtime-chart/pageloadtime-chart.component';

@NgModule({
  declarations: [DetailComponent,
    ChronologicalComponent,
    PageTransitionComponent,
    StepComponent,
    PageInfoComponent,
    PageMetricsComponent,
    LighthouseReportComponent,
    LighthouseReportInfoComponent,
    WaterfallChartComponent,
    WaterfallChartInfoComponent,
    StepInfoComponent,
    TimelineInfoComponent,
    TimelineChartComponent,
    AvarageBoxComponent,
    PageDetailComponent,
    PageloadtimeComponent,
    PageloadtimeChartComponent],
  imports: [
    CommonModule,
    ThemeModule,
    DetailRoutingModule,
    ChartModule,
    LoadingModule,
    NgxEchartsModule
  ],
  providers: [
    ScenarioDetailService,
  ],
  exports: [
    TimelineInfoComponent,
    ChronologicalComponent,
  ],
})
export class DetailModule { }
