import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NbThemeService, NbColorHelper } from '@nebular/theme';
import { SearchService } from '../../../@core/data/search.service';
import { LoadingService } from '../../../@core/data/loading.service';
import { ElasticsearchService } from '../../../@core/data/elasticsearch.service';

import eumOsBrowserRatioJson from 'assets/query/pages/charts/eum_os_browser_ratio.json';
import eumRespondRatioJson from 'assets/query/pages/charts/eum_respond_ratio.json';

/**
 * EUM詳細ページで下記グラフのレンダリングを行うコンポネント
 * - OS/ブラウザごと割合
 * - エラー種別ごと割合
 */
@Component({
  selector: 'ngx-chartjs-pie',
  template: `
    <chart type="pie" *ngIf="isCompInit" [data]="data" [options]="options" (clickElement)="clickChart($event)"></chart>
  `,
})
export class ChartjsPieComponent implements OnInit, OnDestroy {
  @Input() category: string;
  /** グラフ表示用データ */
  data: any;
  /** グラフ設定 */
  options: any;
  /** データ種別 */
  type: any;
  /** コンポネント初期化フラフ */
  isCompInit: boolean = false;
  /** グラフの線、レブル等用色 */
  colors: any;
  /** ChartJS用色 */
  chartjs: any;
  /** 検索用Subscribeオブジェクト */
  searchSubscription: any;
  /** テーマ用Subscribeオブジェクト */
  themeSubscription: any;
  /** Elasticsearch用Subscribeオブジェクト */
  elasticSubscription: any;
  /** ドリルダウン用Subscribeオブジェクト */
  drillDownSubscription: any;
  /** ドリルダウン値 */
  drillDownValue: any;
  /** OSのカウント */
  totalOsCount: any;
  /** Browserのカウント */
  totalBrowserCount: any;
  /** 検索条件 */
  condition: any = {};

  constructor(
    private theme: NbThemeService,
    protected searchService: SearchService,
    protected elasticsearchService: ElasticsearchService,
    protected loadingService: LoadingService,
  ) { }

  /**
   * コンポネント初期処理
   */
  ngOnInit(): void {
    let init = false;
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      if (init) {
        return;
      }
      init = true;
      this.condition = this.searchService.getCondition();
      this.colors = config.variables.temperature;
      this.chartjs = config.variables.chartjs;
      this.searchData();

      // ドリルダウンされた場合、サイトリストフィルタの対象サイトを選択
      this.drillDownSubscription = this.searchService.getEumDrillDownConditionStatus().subscribe(res => {
        this.drillDownValue = this.searchService.getEumDrillDownOption();
        this.searchData();
      });
    });
  }

  /**
   * コンポネント破棄時処理
   */
  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
    // this.searchSubscription.unsubscribe();
    this.drillDownSubscription.unsubscribe();
    this.stopElasticRequest();
  }

  /**
   * Elasticsearchリクエストを中止
   */
  stopElasticRequest(): void {
    if (this.elasticSubscription) {
      this.elasticSubscription.unsubscribe();
    }
  }

  /**
   * サーバ側でクエリを検索する処理
   * @param condition 検索条件
   */
  private searchData() {
    const comp = this;
    let jsonConfig;

    switch (comp.category) {
      // 画面毎のユーザ体感速度(EUM) OS/ブラウザごと割合
      case 'ua':
        comp.type = 'doughnut';
        jsonConfig = eumOsBrowserRatioJson;
        break;
      // 画面毎のユーザ体感速度(EUM) エラー種別ごと割合
      case 'error':
        jsonConfig = eumRespondRatioJson;
        break;
    }

    if (jsonConfig && jsonConfig.length !== 0) {
      const query: any = JSON.parse(JSON.stringify(jsonConfig[0])); // deep copy
      query.body.query.bool.must[0].range = {
        '@timestamp': {
          'gte': comp.condition.start,
          'lte': comp.condition.end,
          'format': 'epoch_millis',
        },
      };

      // drilldown値の確認
      if (comp.drillDownValue) {
        let filter = {};
        if (comp.drillDownValue.type === 'os') {
          filter = {
            'term': {
              'data.page.userAgent.os.name': comp.drillDownValue.value,
            },
          };
        } else {
          filter = {
            'term': {
              'data.page.userAgent.browser.name': comp.drillDownValue.value,
            },
          };
        }
        query.body.query.bool.must.push(filter);
      }

      // ESに対して検索
      this.elasticSubscription = comp.elasticsearchService.searchEumWithProxy(query, true, 'detail')
        .subscribe(response => {
          // aggregation結果
          if (!response.aggregations) {
            if (!comp.loadingService.getLoadingStatus()) {
              comp.loadingService.setLoadConditionStatus();
            }
            return;
          }
          comp.parseData(comp, response);
          comp.draw();
          if (!comp.loadingService.getLoadingStatus()) {
            comp.loadingService.setLoadConditionStatus();
          }
        });
    }
  }

  /**
   * 検索結果のデータを利用し、各グラフデータを作成
   * @param resp 検索結果レスポンス
   */
  private parseData(comp, resp) {
    const datasets = [];
    const labels = [];
    // const color_index = 0;

    if (comp.category === 'ua') {
      const browser_buckets = resp.aggregations.browser_aggs.buckets;
      const os_buckets = resp.aggregations.os_aggs.buckets;
      const each_os_dataset = {
        data: [],
        backgroundColor: [],
        label: 'Doughnut 1',
      };
      const each_browser_dataset = {
        data: [],
        backgroundColor: [],
        label: 'Doughnut 2',
      };
      // Os用
      comp.totalOsCount = this.getTotalDocCount(os_buckets);
      this.getDatasets(comp, os_buckets, labels, 'os', each_os_dataset, each_browser_dataset);
      datasets.push(each_os_dataset);

      // browser用
      comp.totalBrowserCount = this.getTotalDocCount(browser_buckets);
      this.getDatasets(comp, browser_buckets, labels, 'browser' , each_browser_dataset, each_os_dataset);
      datasets.push(each_browser_dataset);

      comp.data = {
          datasets: datasets,
          labels: labels,
        };
    } else {
      const error_buckets = resp.aggregations.error_aggs.buckets;
      const error_dataset = {
        data: [],
        backgroundColor: [],
      };
      for (let i = 0; i < error_buckets.length; i++) {
        labels.push(error_buckets[i].key);
        error_dataset.data.push(error_buckets[i].doc_count);
        if (i < comp.colors.length) {
          error_dataset.backgroundColor.push(comp.colors[i]);
        } else {
          const r_color = NbColorHelper.hexToRgbA(comp.colors[Math.floor(Math.random() * comp.colors.length)], 0.8);
          error_dataset.backgroundColor.push(r_color);
        }
      }
      comp.data = {
        labels: labels,
        datasets: [error_dataset],
      };
    }
  }

  private getTotalDocCount(buckets) {
    let totalDocCount = 0;
    for (let i = 0; i < buckets.length; i++) {
      totalDocCount = totalDocCount + buckets[i].doc_count;
    }
    return totalDocCount;
  }

  /**
   * レスポンスの結果からグラフのデータセットを取得。
   *
   * @param comp 本クラスのインスタンス
   * @param buckets レスポンス内容
   * @param labels グラフ表示ラベル
   * @param type agent種別
   * @param main_dataset 対象ターゲットデータセット
   * @param other_dataset 他のデータセット
   */
  private getDatasets(comp, buckets, labels, type, main_dataset, other_dataset) {
    let color;
    for (let i = 0; i < buckets.length; i++) {
      labels.push(buckets[i].key);
      other_dataset.data.push(0);
      main_dataset.data.push(buckets[i].doc_count);
      color = this.getColorWithRatio(comp, type, buckets[i].doc_count);
      main_dataset.backgroundColor.push(color);
      other_dataset.backgroundColor.push(color);
    }
  }

  /**
   * レスポンスをもとにグラフを描画する。
   */
  private draw() {
    if (!this.options) {
      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        animation:
        {
            duration: 0,
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 20,
          },
        },
        scale: {
          pointLabels: {
            fontSize: 14,
            fontColor: this.chartjs.textColor,
          },
        },
        scales: {
          xAxes: [
            {
              display: false,
            },
          ],
          yAxes: [
            {
              display: false,
            },
          ],
        },
      };
      this.isCompInit = true;
    }
  }

  /**
   * グラフのエリア色をデータ割合で振分る
   * @param comp
   * @param type
   * @param doc_count データ割合
   */
  private getColorWithRatio(comp, type, doc_count) {
    let ratio;
    const dangerThresholdSpeed = 100;
    // const safeHColor = 240;
    let color = 240;

    if (type === 'os') {
      ratio = (doc_count / comp.totalOsCount) * 100;
    } else {
      ratio = (doc_count / comp.totalBrowserCount) * 100;
    }

    if (ratio < dangerThresholdSpeed) {
      color = 240 * ( 1 - ratio / dangerThresholdSpeed );
    } else {
      color = 0;
    }
    return 'hsla(' + color + ', 100%, 50%, 1.0)';
  }

    /**
   * Chartの棒をクリック処理
   * @param event クリックイベント
   */
  clickChart(event) {
    if (event.length > 0) {
      const dataset_index = event[0]._datasetIndex;
      const data_index = event[0]._index;
      let selectedValue = '';
      let agentType = '';

      if (dataset_index === 0) {
        agentType = 'os';
        selectedValue = this.data.labels[data_index];
      } else {
        agentType = 'browser';
        selectedValue = this.data.labels[data_index];
      }

      const drillDownOption = {
        type: agentType,
        value: selectedValue,
      };
      this.searchService.setEumDrillDownOption(drillDownOption);
      this.searchService.setEumDrillDownConditionStatus();
    }
  }
}
