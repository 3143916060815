import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountConfigService } from '../../@core/data/account-config.service';
import { UserService } from '../../@core/data/users.service';
import { GroupConfigService } from '../../@core/data/group-config.service';

@Component({
  selector: 'ngx-group-config',
  templateUrl: './group-config.component.html',
  styleUrls: ['./group-config.component.scss'],
})
export class GroupConfigComponent implements OnInit {
  isLoading: boolean = true;          // ページローディングフラグ
  loadingConfig: any = {};            // ローディング処理設定

  role_id;                            // ログインユーザーの権限
  groupConfig: any = {};              // グループ情報

  constructor(protected userService: UserService,
              protected accountConfigService: AccountConfigService,
              protected groupConfigService: GroupConfigService) { }

  ngOnInit() {
    this.userService.getUsers().subscribe(user => {
      if (this.role_id < 4) {
        // ログインしているユーザーの権限がGuneralUser以下の場合はダッシュボードに遷移する
        location.href = '/dashboard/synthetic#/pages/scenario-summary';
      } else {
        this.accountConfigService.getGroupInfo().subscribe(group => {
          this.groupConfig = group;

          this.groupConfigService.groupId = group.id;

          this.role_id = user.role_id;

          // ローディングのエフェクト終了
          this.isLoading = false;
        });
      }
    });
  }

  /**
   * ユーザー設定画面に遷移
   */
  goUserConfigs (): void {
    location.href = '/dashboard/synthetic#/pages/user-configs';
  }
}
