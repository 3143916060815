import { Component } from '@angular/core';

/**
 * ChartJsコンポネント
 */
@Component({
  selector: 'ngx-chartjs',
  styleUrls: ['./chartjs.component.scss'],
  templateUrl: './chartjs.component.html',
})
export class ChartjsComponent {}
