import { Component, Input, OnInit } from '@angular/core';
import { Report } from 'app/models/scenario.model';

@Component({
  selector: 'ngx-lighthouse-report-info',
  templateUrl: './lighthouse-report-info.component.html',
  styleUrls: ['./lighthouse-report-info.component.scss'],
})

export class LighthouseReportInfoComponent implements OnInit {

  @Input() strategyJp: string;
  @Input() reports: Array<Report>;

  pageReport: Array<Object> = [];

  ngOnInit() {
    if (this.reports !== undefined && this.reports.length > 0) {
      this.reports.map(report => {
        const reportObj = JSON.parse(report['pageReports']);
        const auditData = Object.values(reportObj['audits']);
        const auditCat  = Object.values(reportObj['categories']['performance']['auditRefs']);

        const opportunity = auditData.filter((au) => {
          return au.hasOwnProperty('details') && au['details']['type'] === 'opportunity' && au['score'] < 0.9 && au['score'] !== null;
        });
        opportunity.sort((a, b) => b['numericValue'] - a['numericValue']);

        // get categories audit
        const categories = [];
        auditCat.map((item) => {
          if (item.hasOwnProperty('relevantAudits')) {
            categories.push({id : item['id'], relevantAudits : item['relevantAudits']});
          }
        });

        let allCat = [];
        categories.map(ct => {
          allCat = [...allCat, ...ct.relevantAudits];
        });

        const audiagnosticsData = auditData.filter((au) => {
          return allCat.includes(au['id']);
        });

        const diagnostics = audiagnosticsData.filter((au) => {
          return au['score'] < 0.9 && au['scoreDisplayMode'] !== 'notApplicable' && (au.hasOwnProperty('details') && au['details']['type'] !== 'opportunity');
        });

        diagnostics.forEach(function(digdata, index) {
          if (digdata['id'] === 'critical-request-chains') {
            diagnostics[index]['details']['parent'] = Object.values(digdata['details']['chains'])[0]['request'];
            diagnostics[index]['details']['child'] = Object.values(Object.values(digdata['details']['chains'])[0]['children']);
          }
        });

        diagnostics.sort((a, b) => (a['score'] == null ? 1 : (b['score'] == null ? -1 : a['score'] - b['score'])));

        this.pageReport.push({ status : 200, pageName : report.pageName, opportunity : opportunity, diagnostics : diagnostics });
      });
    }
  }
}
