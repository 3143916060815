import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { SearchService } from '../../../@core/data/search.service';
import { ElasticsearchService } from '../../../@core/data/elasticsearch.service';
import { LoadingService } from '../../../@core/data/loading.service';
import renAvgAreaStackScenarioJson from 'assets/query/pages/charts/scenario_render_avg_area_stack.json';

/**
 * 下記グラフのレンダリングを行うコンポネント
 * - シナリオの各ステップ処理毎の処理時間 時系列推移
 */
@Component({
  selector: 'ngx-echarts-scenario-area-stack',
  template: `
    <div echarts *ngIf="!isNoData" [options]="options" class="echart"></div>
    <div *ngIf="isNoData"> 計測結果は0件です。条件を変更し再検索してください。 </div>
  `,
})
export class EchartsScenarioAreaStackComponent implements OnDestroy, OnInit {
  /** グラフタイプ(HTML入力) */
  @Input() type: string = '';
  /** グラフ値単位(HTML入力) */
  @Input() unit: string = '';
  /** グラフ設定 */
  options: any;
  /** グラフの表示設定 */
  isDisplay: boolean = true;
  /** テーマ用Subscribeオブジェクト */
  themeSubscription: any;
  /** Elasticsearch用Subscribeオブジェクト */
  elasticSubscription: any;
  /** 検索条件 */
  condition: any = {};
  /** グラフの凡例 */
  legend: any;
  /** グラフのx-axisデータ */
  xData: any;
  /** グラフのシーリーズデータ */
  series: any;
  /** グラフの線、レブル等用色 */
  colors: any;
  /** echarts用色 */
  echarts: any;
  /** グラフのy-axis最大値 */
  axisMax: any;
  /** 計測データの有無 */
  isNoData: boolean = false;

  constructor(
    private theme: NbThemeService,
    protected searchService: SearchService,
    protected elasticsearchService: ElasticsearchService,
    protected loadingService: LoadingService,
  ) { }

  /**
   * コンポネント初期処理
   */
  ngOnInit(): void {
    let init = false;
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

      this.condition = this.searchService.getCondition();
      this.colors = config.variables.temperature;
      this.echarts = config.variables.echarts;

      if (init) {
        return;
      }
      init = true;

      // 検索条件が変わった際のイベントを登録
      this.searchService.getConditionState().subscribe(res => {
        this.stopElasticRequest();
        this.options = {};
        this.isDisplay = true;
        this.condition = this.searchService.getCondition();
        this.serach(this.condition);
      });

      // ヘッダ初期化されていなければ初期化を待つ。
      const allScenarioTypeList = this.searchService.getAllScenarioNameList();
      if (Object.getOwnPropertyNames(allScenarioTypeList).length !== 0) {
        this.serach(this.condition);
      } else {
        setTimeout(() => {
          if (!this.loadingService.getLoadingStatus()) {
            this.loadingService.setLoadConditionStatus();
          }
        });
      }
    });
  }

  /**
   * コンポネント破棄時処理
   */
  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
    this.stopElasticRequest();
  }

  /**
   * Elasticsearchリクエストを中止
   */
  stopElasticRequest(): void {
    if (this.elasticSubscription) {
      this.elasticSubscription.unsubscribe();
    }
  }

  /**
   * サーバ側でクエリを検索する処理
   * @param condition 検索条件
   */
  private serach(condition) {
    // Elasticsearchにクエリを発行
    const comp = this;
    let jsonConfig: any;
    comp.axisMax = 25000;

    jsonConfig = renAvgAreaStackScenarioJson;

    if (jsonConfig && jsonConfig.length !== 0) {
      const query: any = jsonConfig[0];

      // 取得する期間を指定
      query.body.query.bool.must[0].range = {
        '@timestamp': {
          'gte': condition.start,
          'lte': condition.end,
          'format': 'epoch_millis',
        },
      };

      // 端末の指定
      query.body.query.bool.must[1] = {'term' : {'strategy': condition.device}};

      // 取得するシナリオの指定
      query.body.query.bool.must.splice( 2, 2 );
      if (condition.scenarioType.length !== 0) {
        query.body.query.bool.must[2] = {'term' : {'scenarioConfigId': condition.scenarioType[0]}};
      }

      // Elasticsearchに対して検索
      this.elasticSubscription = comp.elasticsearchService.searchScenarioAreaStackWithProxy(query)
        .subscribe(response => {
          if (!response.aggregations || response.aggregations.length === 0) {
            this.isDisplay = false;
            if (!comp.loadingService.getLoadingStatus()) {
              comp.loadingService.setLoadConditionStatus();
            }
            this.isNoData = true;
            return;
          }

          comp.isNoData = false;
          comp.parseData(response);
          comp.draw();

          if (!comp.loadingService.getLoadingStatus()) {
            comp.loadingService.setLoadConditionStatus();
          }
        });
    }
  }

  /**
   * 検索結果のデータを利用し、各グラフデータを作成
   * @param resp 検索結果レスポンス
   */
  private parseData(resp: any) {
    this.legend = [];
    const data: any[] = [];
    const series_data: any[] = [];
    const step_buckets = resp.aggregations.step_aggs.buckets;
    let time_buckets;

    // ステップ毎のループ
    for (let i = 0; i < step_buckets.length; i++) {
      this.legend[i] = step_buckets[i].key;     // チャートに表示するステップ名のリストを作成

      time_buckets = step_buckets[i].time_aggs.buckets;

      // 時間毎のループ
      const dataItemList = [];
      for (let j = 0; j < time_buckets.length; j++) {
        data[j] = time_buckets[j].key;                        // チャートに表示する計測時刻
        dataItemList[j] = time_buckets[j].value_aggs.value;   // チャートに表示する計測値
      }

      // チャートの設定
      series_data[i] = {
        name: step_buckets[i].key,
        type: 'line',
        stack: 'Total amount',
        label: {},
        areaStyle: { normal: { opacity: this.echarts.areaOpacity } },
        data: dataItemList,
      };
    }

    this.xData = data;
    this.series = series_data;
  }

  /**
   * レスポンスをもとにグラフを描画する。
   */
  private draw() {
    const unit = this.unit;
    this.options = {
      display: this.isDisplay,
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: this.echarts.bg,
      color: this.colors,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          label: {
            backgroundColor: this.echarts.tooltipBackgroundColor,
            formatter: function (params) {
              const date = new Date(params.value);
              const dateStr = [(date.getMonth() + 1), date.getDate()];
              const hhStr = date.getHours();
              const mmStr = date.getMinutes();
              const timeStr = [
                (hhStr < 10 ? '0' : '') + hhStr,
                (mmStr < 10 ? '0' : '') + mmStr,
              ];
              const text = dateStr.join('/') + ' ' + timeStr.join(':');
              return text;
            },
          },
        },
        formatter: function (tooltipItems) {
          let txt = '';
          tooltipItems.forEach(function (tooltipItem, index) {
            if (index === 0) {
              txt = tooltipItem.axisValueLabel;
            }
            txt += '<br/>' + tooltipItem.seriesName + ' : ' + Math.floor(tooltipItem.value) + ' ' + unit;
          });
          return txt;
        },
      },
      legend: {
        data: this.legend,
        textStyle: {
          color: this.echarts.textColor,
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: this.xData,
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: this.echarts.axisLineColor,
            },
          },
          axisLabel: {
            textStyle: {
              color: this.echarts.textColor,
            },
            formatter: function (value, index) {
              const date = new Date(value);
              const dateStr = [(date.getMonth() + 1), date.getDate()];
              const hhStr = date.getHours();
              const mmStr = date.getMinutes();
              const timeStr = [
                (hhStr < 10 ? '0' : '') + hhStr,
                (mmStr < 10 ? '0' : '') + mmStr,
              ];
              const text = dateStr.join('/') + ' ' + timeStr.join(':');
              return text;
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: unit,
          axisLine: {
            lineStyle: {
              color: this.echarts.axisLineColor,
            },
          },
          splitLine: {
            lineStyle: {
              color: this.echarts.splitLineColor,
            },
          },
          axisLabel: {
            textStyle: {
              color: this.echarts.textColor,
            },
          },
        },
      ],
      series: this.series,
    };
  }
}
