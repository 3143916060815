import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NbThemeService, NbColorHelper } from '@nebular/theme';
import { SearchService } from '../../../@core/data/search.service';
import { ElasticsearchService } from '../../../@core/data/elasticsearch.service';
import { LoadingService } from '../../../@core/data/loading.service';
import renAvgAreaStackDesktopJson from 'assets/query/pages/charts/render_avg_area_stack_desktop.json';
import renAvgAreaStackMobileJson from 'assets/query/pages/charts/render_avg_area_stack_mobile.json';

/**
 * 下記グラフのレンダリングを行うコンポネント
 * - 各種レンダリング処理毎の処理時間 時系列推移(Mobile)
 * - 各種レンダリング処理毎の処理時間 時系列推移(Desktop)
 */
@Component({
  selector: 'ngx-echarts-area-stack',
  template: `
    <div echarts [options]="options" class="echart"></div>
  `,
})
export class EchartsAreaStackComponent implements OnDestroy, OnInit {
  /** グラフタイプ(HTML入力) */
  @Input() type: string = '';
  /** グラフ値単位(HTML入力) */
  @Input() unit: string = '';
  /** グラフ設定 */
  options: any;
  /** グラフの表示設定 */
  isDisplay: boolean = true;
  /** テーマ用Subscribeオブジェクト */
  themeSubscription: any;
  /** Elasticsearch用Subscribeオブジェクト */
  elasticSubscription: any;
  /** 検索条件 */
  condition: any = {};
  /** グラフの凡例 */
  legend: any;
  /** グラフのx-axisデータ */
  xData: any;
  /** グラフのシーリーズデータ */
  series: any;
  /** グラフの線、レブル等用色 */
  colors: any;
  /** echarts用色 */
  echarts: any;
  /** グラフのy-axis最大値 */
  axisMax: any;

  constructor(
    private theme: NbThemeService,
    protected searchService: SearchService,
    protected elasticsearchService: ElasticsearchService,
    protected loadingService: LoadingService,
  ) { }

  /**
   * コンポネント初期処理
   */
  ngOnInit(): void {
    let init = false;
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

      this.condition = this.searchService.getCondition();
      this.colors = config.variables.temperature;
      this.echarts = config.variables.echarts;

      if (init) {
        return;
      }
      init = true;
      // 検索条件が変わった際のイベントを登録
      this.searchService.getConditionState().subscribe(res => {
        this.stopElasticRequest();
        this.options = {};
        this.isDisplay = true;
        this.condition = this.searchService.getCondition();
        this.serach(this.condition);
      });
      const allSiteNameMap = this.searchService.getAllSiteNameMap();
      if (Object.getOwnPropertyNames(allSiteNameMap).length !== 0) {
        this.serach(this.condition);
      } else {
        setTimeout(() => {
          if (!this.loadingService.getLoadingStatus()) {
            this.loadingService.setLoadConditionStatus();
          }
        });
      }
    });
  }

  /**
   * コンポネント破棄時処理
   */
  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
    this.stopElasticRequest();
  }

  /**
   * Elasticsearchリクエストを中止
   */
  stopElasticRequest(): void {
    if (this.elasticSubscription) {
      this.elasticSubscription.unsubscribe();
    }
  }

  /**
   * サーバ側でクエリを検索する処理
   * @param condition 検索条件
   */
  private serach(condition) {
    // ESにクエリを発行
    const comp = this;
    let jsonConfig: any;
    comp.axisMax = 25000;

    switch (comp.type) {
      // 各種レンダリング処理毎の処理時間 時系列推移(Mobile)
      case 'render_mobile':
      if (this.searchService.isMobile()) jsonConfig = renAvgAreaStackMobileJson;
        break;
      // 各種レンダリング処理毎の処理時間 時系列推移(Desktop)
      case 'render_desktop':
      if (this.searchService.isPC()) jsonConfig = renAvgAreaStackDesktopJson;
        break;
    }

    // タイプをもとに検索条件を指定
    if (jsonConfig && jsonConfig.length !== 0) {
      const query: any = jsonConfig[0];
      query.body.query.bool.must[0].range = {
        '@timestamp': {
          'gte': condition.start,
          'lte': condition.end,
          'format': 'epoch_millis',
        },
      };

      this.elasticSubscription = comp.elasticsearchService.searchWithProxy(query)
        .subscribe(response => {
          if (!response.aggregations) {
            this.isDisplay = false;
            if (!comp.loadingService.getLoadingStatus()) {
              comp.loadingService.setLoadConditionStatus();
            }
            return;
          }
          comp.parseData(response);
          comp.draw();
          if (!comp.loadingService.getLoadingStatus()) {
            comp.loadingService.setLoadConditionStatus();
          }
        });
    }
  }

  /**
   * 検索結果のデータを利用し、各グラフデータを作成
   * @param resp 検索結果レスポンス
   */
  private parseData(resp: any) {
    const data: any[] = [];
    const series_data: any[] = [];
    const dataList: any[] = [];
    let flag = 1; // エリアスタックのオブジェクトリスト初期化フラグ

    this.legend = ['updateLayerTree', 'styles', 'raster', 'parseHTML',
                   'paint', 'layout', 'javaScriptCompile', 'javaScript',
                   'forcedRecalcs', 'forcedLayouts', 'composite'];

    const aggs4_buckets = resp.aggregations.page_aggs.buckets;
    let aggs2_buckets;
    let each_dataset;
    let bucketList;
    let dataset_color;
    for (let i = 0; i < aggs4_buckets.length; i++) {
      aggs2_buckets = aggs4_buckets[i].aggs2.buckets;

      if (flag === 1) {
        for (let c = 0; c < this.legend.length; c++) {
          each_dataset = series_data[c];
          bucketList = new Array(aggs2_buckets.length).fill(0);
          dataList[c] = bucketList;

          if (c < this.colors.length) {
            dataset_color = this.colors[c];
          } else {
            dataset_color = NbColorHelper.hexToRgbA(this.colors[Math.floor(Math.random() * this.colors.length)], 0.8);
          }
          if (!each_dataset) {
            each_dataset = {
              name: '',
              type: 'line',
              stack: 'Total amount',
              label: {},
              areaStyle: { normal: { opacity: this.echarts.areaOpacity } },
              data: [],
            };
            series_data[c] = each_dataset;
          }
        }
        flag = 0;
      }

      let dataset = series_data[0];
      let dataItemList = dataList[0];
      let dataItem;
      let dateStr;
      let aggs3_buckets;
      let lagendIndex;
      for (let j = 0; j < aggs2_buckets.length; j++) {
        dateStr = aggs2_buckets[j].key_as_string;
        data[j] = dateStr;

        aggs3_buckets = aggs2_buckets[j].aggs3.buckets;

        for (let k = 0; k < aggs3_buckets.length; k++) {
          lagendIndex = this.legend.indexOf(aggs3_buckets[k].key);
          if (lagendIndex === -1) {
            lagendIndex = this.legend.length - 1;
          }
          // データ名
          dataset = series_data[lagendIndex];
          dataset.name = aggs3_buckets[k].key;
          // データリストをマージ
          dataItemList = dataList[lagendIndex];
          dataItem = dataItemList[j];
          dataItem = dataItem + aggs3_buckets[k].aggs1.value;
          dataItemList[j] = dataItem;
          dataList[lagendIndex] = dataItemList;
          // データセットをシリーズデータに更新
          dataset.data = dataItemList;
          series_data[lagendIndex] = dataset;
        }
      }
    }

    this.xData = data;
    this.series = series_data;
  }

  /**
   * レスポンスをもとにグラフを描画する。
   */
  private draw() {
    const unit = this.unit;
    this.options = {
      display: this.isDisplay,
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: this.echarts.bg,
      color: this.colors,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          label: {
            backgroundColor: this.echarts.tooltipBackgroundColor,
            formatter: function (params) {
              const date = new Date(params.value);
              const dateStr = [(date.getMonth() + 1), date.getDate()];
              const hhStr = date.getHours();
              const mmStr = date.getMinutes();
              const timeStr = [
                (hhStr < 10 ? '0' : '') + hhStr,
                (mmStr < 10 ? '0' : '') + mmStr,
              ];
              const text = dateStr.join('/') + ' ' + timeStr.join(':');
              return text;
            },
          },
        },
        formatter: function (tooltipItems) {
          let txt = '';
          tooltipItems.forEach(function (tooltipItem, index) {
            if (index === 0) {
              txt = tooltipItem.axisValueLabel;
            }
            txt += '<br/>' + tooltipItem.seriesName + ' : ' + Math.floor(tooltipItem.value) + ' ' + unit;
          });
          return txt;
        },
      },
      legend: {
        data: this.legend,
        textStyle: {
          color: this.echarts.textColor,
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: this.xData,
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: this.echarts.axisLineColor,
            },
          },
          axisLabel: {
            textStyle: {
              color: this.echarts.textColor,
            },
            formatter: function (value, index) {
              const date = new Date(value);
              const dateStr = [(date.getMonth() + 1), date.getDate()];
              const hhStr = date.getHours();
              const mmStr = date.getMinutes();
              const timeStr = [
                (hhStr < 10 ? '0' : '') + hhStr,
                (mmStr < 10 ? '0' : '') + mmStr,
              ];
              const text = dateStr.join('/') + ' ' + timeStr.join(':');
              return text;
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: unit,
          axisLine: {
            lineStyle: {
              color: this.echarts.axisLineColor,
            },
          },
          splitLine: {
            lineStyle: {
              color: this.echarts.splitLineColor,
            },
          },
          axisLabel: {
            textStyle: {
              color: this.echarts.textColor,
            },
          },
        },
      ],
      series: this.series,
    };
  }
}
