import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { ScenarioSummary, Scenario } from 'app/models/scenario.model';

import { ElasticsearchService } from 'app/@core/data/elasticsearch.service';
import { ScenarioDetailService } from 'app/@core/data/scenario-detail.service';

import jsonScenarioPageTransition from 'assets/query/pages/scenario-summary/scenario_page_transition.json';
import jsonScenarioLighthouseReport from 'assets/query/pages/scenario-summary/scenario_lighthouse_report.json';
import jsonScenarioStep from 'assets/query/pages/scenario-summary/scenario_step.json';

@Component({
  selector: 'ngx-step-info',
  templateUrl: './step-info.component.html',
  styleUrls: ['./step-info.component.scss'],
})
export class StepInfoComponent implements OnInit, OnDestroy {
  @Output() setViewWebsites = new EventEmitter();

  // サイト名
  @Input() siteName = '';

  @Input() summary: ScenarioSummary;
  @Input() defaultPeriodDifferencial: any;
  @Input() index: number;

  // Subscribeオブジェクト一覧
  subscriptions: any = [];

  // 競合他社サイトセレクトボックス用サイト配列
  allWebsites: Array<string> = [];

  // 選択中の指標フィルター
  selectedCondition: string = '';

  // スクショ
  screenShots: Array<Object> = [];

  // 経過時間
  elapsedTimePerStep: Array<number> = [];

  // サイトフィルター
  sites: Array<string> = [];

  // 選択中のウェブサイトフィルター
  selectedWebsites: Array<string> = [];

  // 選択中のデバイスフィルター
  selectedDevices: Array<string> = [];

  scenarioSummary: ScenarioSummary;

  showPeriod: string = '期間';

  showDatepicker = 'close_datepicker';

  constructor(
    private elasticsearchService: ElasticsearchService,
    private scenarioDetailService: ScenarioDetailService,
  ) { }

  ngOnInit() {
    this.scenarioSummary = Object.create(this.summary);

    this.subscriptions.push(this.scenarioDetailService.getSelectedWebsites().subscribe(data => {
      this.selectedWebsites = data;

      // 競合他社サイトのセレクトボックス内に自身のwebsiteを表示しないため、配列から取り除く
      this.allWebsites = this.selectedWebsites.filter(website => website !== this.siteName);
    }));

    this.subscriptions.push(this.scenarioDetailService.getSelectedDevices().subscribe(data => {
      this.selectedDevices = data;
    }));

    this.displaySelectionPeriod({
      start: moment().subtract(parseInt(this.defaultPeriodDifferencial, 10), 'minute'),
      end: moment(),
    });
    this.selectedCondition = this.defaultPeriodDifferencial;
  }

  get isSelectedSite() {
    return this.summary.siteName === this.siteName;
  }

  get websites() {
    return this.allWebsites;
  }

  websiteFilterChanged() {
    this.selectedWebsites = [this.siteName].concat(this.selectedWebsites);
    this.setViewWebsites.emit(this.selectedWebsites);
  }

  closeDatepicker() {
    this.showDatepicker = 'close_datepicker';
  }

  onConditionSelect() {
    if (this.selectedCondition === 'custom') {
      this.showDatepicker = '';
    } else {
      this.showDatepicker = 'close_datepicker';
      const period = {
        start: moment().subtract(parseInt(this.selectedCondition, 10), 'minute').valueOf(),
        end: moment().valueOf(),
      };
      this.setScenarioDetail(period);
      this.displaySelectionPeriod({
        start: moment().subtract(parseInt(this.selectedCondition, 10), 'minute'),
        end: moment(),
      });
    }
  }

  get devices() {
    return this.scenarioDetailService.getAllDevices();
  }

  displaySelectionPeriod(selectionPeriod: any) {
    this.showPeriod = selectionPeriod.start.format('YYYY/MM/DD') + '~' + selectionPeriod.end.format('YYYY/MM/DD');
  }

  setScenarioDetail(period: any) {
    this.showDatepicker = 'close_datepicker';

    const queryScenarioStep: any = jsonScenarioStep[0];
    const queryScenarioPageTransition: any = jsonScenarioPageTransition[0];
    const queryScenarioLighthouseReport: any = jsonScenarioLighthouseReport[0];

    const range = {
      '@timestamp': {
        'gte': period.start,
        'lte': period.end,
        'format': 'epoch_millis',
      },
    };

    queryScenarioStep.body.query.bool.must[0].range = range;
    queryScenarioPageTransition.body.query.bool.must[0].range = range;
    queryScenarioLighthouseReport.body.query.bool.must[0].range = range;

    this.elasticsearchService.getScenarioDetail(queryScenarioStep,
                                                queryScenarioPageTransition,
                                                queryScenarioLighthouseReport,
                                                this.scenarioSummary.scenarioConfigId,
                                                0).subscribe(data => {
      const scenarioSummaries = data.map(scenarioSummary => new ScenarioSummary(
        scenarioSummary.scenarioConfigId,
        scenarioSummary.scenarioName,
        scenarioSummary.siteName,
        scenarioSummary.category,
        scenarioSummary.strategies.map(
          scenario => new Scenario(
            scenario.timestamp,
            scenario.strategy,
            scenario.pageTransitions,
            scenario.lighthouseReports,
            scenario.scenarioSteps,
          ),
        ),
      ));

      if (Array.isArray(scenarioSummaries)) {
        const scenario_data = scenarioSummaries[0];
        if (scenario_data) {
          this.scenarioSummary.strategies = scenario_data.strategies;
        } else {
          this.scenarioSummary.strategies = [];
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (value) {
      value.unsubscribe();
    });
  }
}
