import { Injectable, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ElasticsearchService } from 'app/@core/data/elasticsearch.service';
import { ScenarioSummary,
          Scenario,
          TimelineMetric,
          TimelineDevice,
          TimelinePage,
          TimelineValue,
          MetricStandardScore } from 'app/models/scenario.model';
import { WebVitalsConst } from 'app/app-const';
import jsonScenarioStep from 'assets/query/pages/scenario-summary/scenario_step.json';
import jsonScenarioPageTransition from 'assets/query/pages/scenario-summary/scenario_page_transition.json';
import jsonScenarioLighthouseReport from 'assets/query/pages/scenario-summary/scenario_lighthouse_report.json';
import jsonScenarioPageContent from 'assets/query/pages/scenario-summary/scenario_page_content.json';
import scenarioChartPageTransitionJson from 'assets/query/pages/charts/scenario_chart_page_transition.json';
import scenarioChartPageContentJson from 'assets/query/pages/charts/scenario_chart_page_content.json';
import scenarioPageLoadTime from 'assets/query/pages/charts/scenario_page_load_time.json'
import { WarningMetricsForCallouts } from '../../models/scenario.model';

@Injectable()
export class ScenarioDetailService implements OnDestroy {
  private scenarioSummaries: BehaviorSubject<Array<ScenarioSummary>>;

  private subscriptions: Array<Subscription> = [];

  private metrics: Array<string>;

  private websites: Array<string>;

  private devices: Array<string>;

  // 各指標の基準値情報を持った配列
  private metricStandardScores: Array<MetricStandardScore> = [
    new MetricStandardScore('FCP', WebVitalsConst.FCP_BORDER_GOOD, WebVitalsConst.FCP_BORDER_BAD),
    new MetricStandardScore('SI', WebVitalsConst.SI_BORDER_GOOD, WebVitalsConst.SI_BORDER_BAD),
    new MetricStandardScore('LCP', WebVitalsConst.LCP_BORDER_GOOD, WebVitalsConst.LCP_BORDER_BAD),
    new MetricStandardScore('TTI', WebVitalsConst.TTI_BORDER_GOOD, WebVitalsConst.TTI_BORDER_BAD),
    new MetricStandardScore('CLS', WebVitalsConst.CLS_BORDER_GOOD, WebVitalsConst.CLS_BORDER_BAD),
    new MetricStandardScore('TTFB', WebVitalsConst.TTFB_BORDER_GOOD, WebVitalsConst.TTFB_BORDER_BAD),
    new MetricStandardScore('TBT', WebVitalsConst.TBT_BORDER_GOOD, WebVitalsConst.TBT_BORDER_BAD),
    new MetricStandardScore('LPS', WebVitalsConst.LPS_BORDER_GOOD, WebVitalsConst.LPS_BORDER_BAD),
    new MetricStandardScore('CTS', null, null),
    new MetricStandardScore('BTS', null, null),
    new MetricStandardScore('PRN', null, null),
  ];

  private goodValClassName: string = 'goodVal';
  private badValClassName: string = 'badVal';
  private needImpValClassName: string = 'needImprovement';

  private selectedMetrics: BehaviorSubject<Array<string>>;

  private selectedWebsites: BehaviorSubject<Array<string>>;

  private selectedDevices: BehaviorSubject<Array<string>>;

  private timelineMetrics: BehaviorSubject<Array<TimelineMetric>>;

  private pageLoadTime :BehaviorSubject<Array<string>>;

  constructor(
    protected elasticsearchService: ElasticsearchService,
  ) {
    this.scenarioSummaries = new BehaviorSubject<Array<ScenarioSummary>>([]);
    this.timelineMetrics = new BehaviorSubject<Array<TimelineMetric>>([]);
    this.selectedMetrics = new BehaviorSubject<Array<string>>([]);
    this.selectedWebsites = new BehaviorSubject<Array<string>>([]);
    this.selectedDevices = new BehaviorSubject<Array<string>>([]);
    this.pageLoadTime= new BehaviorSubject<Array<string>>([]);
  }

  get getScenarioSummaries() {
    return this.scenarioSummaries.asObservable();
  }

  get getTimelineMetrics() {
    return this.timelineMetrics.asObservable();
  }

  get getMetricSortOrder() {
    return WebVitalsConst.METRICS;
  }

  get getPageLoadTime() {
    return this.pageLoadTime.asObservable();
  }

  getMetricStatus(measurementStandard, metricName, value) {
    let metricStandardCustom: Array<MetricStandardScore>;
    if (measurementStandard !== null) {
      metricStandardCustom = [
        new MetricStandardScore('FCP', measurementStandard.FCP_BORDER_GOOD, measurementStandard.FCP_BORDER_BAD),
        new MetricStandardScore('SI', measurementStandard.SI_BORDER_GOOD, measurementStandard.SI_BORDER_BAD),
        new MetricStandardScore('LCP', measurementStandard.LCP_BORDER_GOOD, measurementStandard.LCP_BORDER_BAD),
        new MetricStandardScore('TTI', measurementStandard.TTI_BORDER_GOOD, measurementStandard.TTI_BORDER_BAD),
        new MetricStandardScore('CLS', measurementStandard.CLS_BORDER_GOOD, measurementStandard.CLS_BORDER_BAD),
        // new MetricStandardScore('FID', WebVitalsConst.FID_BORDER_GOOD, WebVitalsConst.FID_BORDER_BAD),//FIDを削除(RS-890)
        new MetricStandardScore('TTFB', measurementStandard.TTFB_BORDER_GOOD, measurementStandard.TTFB_BORDER_BAD),
        new MetricStandardScore('TBT', measurementStandard.TBT_BORDER_GOOD, measurementStandard.TBT_BORDER_BAD),
        new MetricStandardScore('LPS', measurementStandard.LPS_BORDER_GOOD, measurementStandard.LPS_BORDER_BAD),
        new MetricStandardScore('CTS', null, null),
        new MetricStandardScore('BTS', null, null),
        new MetricStandardScore('PRN', null, null),
      ];
    }
    const standardScore = (measurementStandard !== null ? metricStandardCustom.find(metric => metric.name === metricName) : this.metricStandardScores.find(metric => metric.name === metricName));

    if (metricName === 'LPS' && standardScore) {
      if (value >= standardScore.goodVal) {
        return this.goodValClassName;
      } else if (value < standardScore.badVal) {
        return this.badValClassName;
      } else {
        return this.needImpValClassName;
      }
    }else if (standardScore) {
      if (value < standardScore.goodVal) {
        return this.goodValClassName;
      } else if (value > standardScore.badVal) {
        return this.badValClassName;
      } else {
        return this.needImpValClassName;
      }
    }
  }

  setScenarioSummaries(condition: any) {
    const queryScenarioStep: any = jsonScenarioStep[0];
    const queryScenarioPageTransition: any = jsonScenarioPageTransition[0];
    const queryScenarioLighthouseReport: any = jsonScenarioLighthouseReport[0];
    const queryScenarioPageContent: any = jsonScenarioPageContent[0];

    const range = {
      '@timestamp': {
        'gte': condition.start,
        'lte': condition.end,
        'format': 'epoch_millis',
      },
    };

    queryScenarioStep.body.query.bool.must[0].range = range;
    queryScenarioPageTransition.body.query.bool.must[0].range = range;
    queryScenarioLighthouseReport.body.query.bool.must[0].range = range;
    queryScenarioPageContent.body.query.bool.must[0].range = range;

    this.elasticsearchService.getScenarioSummary(queryScenarioStep,
                                                 queryScenarioPageTransition,
                                                 queryScenarioLighthouseReport,
                                                 queryScenarioPageContent,
                                                 condition.scenarioName,
                                                 condition.scenarioCategory, condition.scenarioSite).subscribe(data => {
      const scenarioSummaries = data.map(scenarioSummary => new ScenarioSummary(
        scenarioSummary.scenarioConfigId,
        scenarioSummary.scenarioName,
        scenarioSummary.siteName,
        scenarioSummary.category,
        scenarioSummary.strategies.map(
          scenario => new Scenario(
            scenario.timestamp,
            scenario.strategy,
            scenario.pageTransitions,
            scenario.lighthouseReports,
            scenario.pageContents,
            scenario.scenarioSteps,
          ),
        ),
        new WarningMetricsForCallouts(),
        this.formatTimestampToDateTime(scenarioSummary.strategies[0].pageTransitions[0].timestamp),
      ));
      this.scenarioSummaries.next(scenarioSummaries);
    });
  }

  setScenarioDetails(condition: any, selectedScenario: any) {
    const queryScenarioStep: any = jsonScenarioStep[0];
    const queryScenarioPageTransition: any = jsonScenarioPageTransition[0];
    const queryScenarioLighthouseReport: any = jsonScenarioLighthouseReport[0];
    const queryScenarioPageContent: any = jsonScenarioPageContent[0];


    const range = {
      '@timestamp': {
        'gte': condition.start,
        'lte': condition.end,
        'format': 'epoch_millis',
      },
    };

    queryScenarioStep.body.query.bool.must[0].range = range;
    queryScenarioPageTransition.body.query.bool.must[0].range = range;
    queryScenarioLighthouseReport.body.query.bool.must[0].range = range;
    queryScenarioPageContent.body.query.bool.must[0].range = range;

    this.elasticsearchService.getScenarioDetail(queryScenarioStep,
                                                queryScenarioPageTransition,
                                                queryScenarioLighthouseReport,
                                                queryScenarioPageContent,
                                                selectedScenario,
                                                1).subscribe(data => {
      const scenarioSummaries = data.map(scenarioSummary => new ScenarioSummary(
        scenarioSummary.scenarioConfigId,
        scenarioSummary.scenarioName,
        scenarioSummary.siteName,
        scenarioSummary.category,
        scenarioSummary.strategies.map(
          scenario => new Scenario(
            scenario.timestamp,
            scenario.strategy,
            scenario.pageTransitions,
            scenario.lighthouseReports,
            scenario.pageContents,
            scenario.scenarioSteps,
          ),
        ),
      ));

      this.metrics = this.extractMetricTypes(scenarioSummaries);
      this.websites = scenarioSummaries.map(summary => summary.siteName);
      this.devices = this.extractDevices(scenarioSummaries);
      this.scenarioSummaries.next(scenarioSummaries);

      this.selectedMetrics.next(this.metrics);
      this.selectedWebsites.next(this.websites);
      this.selectedDevices.next(this.devices);
    });
  }

  setTimelineMetrics(condition: any, selectedScenario: any) {
    const query: any = scenarioChartPageTransitionJson[0];
    const contentquery: any = scenarioChartPageContentJson[0];
    const range = {
      '@timestamp': {
        'gte': condition.start,
        'lte': condition.end,
        'format': 'epoch_millis',
      },
    };
    query.body.query.bool.must[0].range = range;
    contentquery.body.query.bool.must[0].range = range;

    query.body.query.bool.must[1].term.strategy = 'desktop';
    contentquery.body.query.bool.must[1].term.strategy = 'desktop';

    query.body.query.bool.must[2].term.scenarioConfigId = selectedScenario;
    contentquery.body.query.bool.must[2].term.scenarioConfigId = selectedScenario;

    this.elasticsearchService.searchScenarioLineChartWithProxy(query, contentquery, null).subscribe(data => {
      const timelineMetrics = data.map(timelineMetric => new TimelineMetric(
        timelineMetric.metricName,
        timelineMetric.devices.map(timelineDevice => new TimelineDevice(
          timelineDevice.deviceJPName,
          timelineDevice.pages.map(timelinePage => new TimelinePage(
            timelinePage.pageName,
            timelinePage.metrics.length ? timelinePage.metrics.map(timelineValue => new TimelineValue(
              timelineValue.date,
              timelineValue.value,
            )) : [],
          )),
        )),
      ));

      this.timelineMetrics.next(timelineMetrics);
    });
  }

  setPageLoadTime(condition: any, selectedScenario: any) {
    const range = {
      '@timestamp': {
        'gte': condition.start,
        'lte': condition.end,
        'format': 'epoch_millis',
      },
    };
    const query: any = scenarioPageLoadTime[0];
    query.body.query.bool.must[0].match.scenarioConfigId = selectedScenario;
    query.body.query.bool.filter[0].range = range;
    this.elasticsearchService.searchPageLoadTime(query).subscribe(data => { this.pageLoadTime.next(data); });
  }
/*
  getSummaries(data?: any): Observable<Array<ScenarioSummary>> {
    if (data) {
      const scenarioSummaries = data.map(scenarioSummary => new ScenarioSummary(
        scenarioSummary.scenarioConfigId,
        scenarioSummary.scenarioName,
        scenarioSummary.siteName,
        scenarioSummary.category,
        scenarioSummary.strategies.map(
          scenario => new Scenario(
            scenario.timestamp,
            scenario.strategy,
            scenario.pageTransitions,
            scenario.scenarioSteps,
          ),
        ),
      ));
      this.metrics = this.extractMetricTypes(scenarioSummaries);
      this.websites = scenarioSummaries.map(summary => summary.siteName);

      this.scenarioSummaries.next(scenarioSummaries);
    } else {
      this.metrics = this.extractMetricTypes(SCENARIOS_DUMMY);
      this.websites = SCENARIOS_DUMMY.map(summary => summary.siteName);

      this.scenarioSummaries.next(SCENARIOS_DUMMY);
    }

    this.selectedMetrics.next(this.metrics);
    this.selectedWebsites.next(this.websites);
    return this.scenarioSummaries;
  }
*/

  getMetricTypes(): Array<string> {
    return this.metrics;
  }

  getSelectedMetrics(): BehaviorSubject<Array<string>> {
    return this.selectedMetrics;
  }

  getAllWebsites(): Array<string> {
    return this.websites;
  }

  getSelectedWebsites(): BehaviorSubject<Array<string>> {
    return this.selectedWebsites;
  }

  getAllDevices(): Array<string> {
    return this.devices;
  }

  getSelectedDevices(): BehaviorSubject<Array<string>> {
    return this.selectedDevices;
  }

  changeSelectedMetrics(selectedMetrics: Array<string>) {
    this.selectedMetrics.next(selectedMetrics);
  }

  changeSelectedWebsites(selectedWebsites: Array<string>) {
    this.selectedWebsites.next(selectedWebsites);
  }

  changeSelectedDevices(selectedDevices: Array<string>) {
    this.selectedDevices.next(selectedDevices);
  }

  extractMetricTypes(scenarioSummaries: Array<ScenarioSummary>) {
    const metricdata = scenarioSummaries.flatMap(summary =>
      summary.strategies.flatMap(scenario =>
        scenario.pageTransitions ? scenario.pageTransitions.flatMap(page =>
          Object.keys(page.metrics),
        ) : [],
      ),
    ).reduce((unique, key) => {
      return unique.includes(key) ? unique : [...unique, key];
    }, []);
    const contentdata = scenarioSummaries.flatMap(summary =>
      summary.strategies.flatMap(scenario =>
        scenario.pageContents ? scenario.pageContents.flatMap(content =>
          Object.keys(content.contents),
        ) : [],
      ),
    ).reduce((unique, key) => {
      return unique.includes(key) ? unique : [...unique, key];
    }, []);
    return metricdata.concat(contentdata);
  }

  extractDevices(scenarioSummaries: Array<ScenarioSummary> ) {
    return scenarioSummaries.flatMap(summary => summary.strategies
      .flatMap(device => device.strategyJp))
      .reduce((unique, key) => {
        return unique.includes(key) ? unique : [...unique, key];
      }, []);
  }

/*
  getTimelineMetrics(data?: any): Observable<Array<TimelineMetric>> {
    if (data) {
      const timelineMetrics = data.map(timelineMetric => new TimelineMetric(
        timelineMetric.metricName,
        timelineMetric.devices.map(timelineDevice => new TimelineDevice(
          timelineDevice.deviceJPName,
          timelineDevice.pages.map(timelinePage => new TimelinePage(
            timelinePage.pageName,
            timelinePage.metrics.map(timelineValue => new TimelineValue(
              timelineValue.date,
              timelineValue.value,
            )),
          )),
        )),
      ));

      this.timelineMetrics.next(timelineMetrics);
    } else {
      this.timelineMetrics.next(METRICS_DUMMY);
    }
    return this.timelineMetrics;
  }
*/

  /* シナリオ詳細ページに関するデータの初期化*/
  initializeScenarioDetailPageData() {
    // 表示対象のシナリオ詳細の初期化
    this.scenarioSummaries = new BehaviorSubject<Array<ScenarioSummary>>([]);
    this.timelineMetrics = new BehaviorSubject<Array<TimelineMetric>>([]);
  }

  /*
  * タイムスタンプ（string）をYYYY/M/D AM/PM H:mmにフォーマットする
  */
  formatTimestampToDateTime(timestamp: string): {[key: string]: string} {
    // const date = new Date(timestamp);

    // AM/PMで出力したいため、ロケールはen-USを使用する
    const formattedDate = formatDate(timestamp, 'yyyy/MM/dd', 'en-US');
    const formattedTime = formatDate(timestamp, 'a h:mm', 'en-US');

    return {date: formattedDate, time: formattedTime};
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
  }

}
