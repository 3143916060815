import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EngineerConfigService } from '../../@core/data/engineer-config.service';
import { UserService } from '../../@core/data/users.service';

@Component({
  selector: 'ngx-engineer-configs',
  templateUrl: './engineer-configs.component.html',
  styleUrls: ['./engineer-configs.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class EngineerConfigsComponent implements OnInit {
  isLoading: boolean = true;          // ページローディングフラグ
  loadingConfig: any = {};            // ローディング処理設定

  role_id;                            // ログインユーザーの権限

  groupConfig: any = [];              // 所属するグループ情報
  roles: any = [];                    // 検索エリアの権限を格納するリスト
  userGroups: any = [];               // 検索エリアのグループを格納するリスト
  engineerConfigs: any = [];          // エンジニア一覧のデータを格納するリスト
  page = 1;                           // ページャーのページ番号
  pageSize = 10;                      // ページャーが１ページに表示するデータの数

  isSubmitted = false;                // モーダルのSubmitボタン押下のフラグ
  modalMessage: string = '';          // モーダルに表示するメッセージ
  addEngineerData = new FormData();   // モーダルの登録・更新時に送信するフォームデータ

  modalDisabled: boolean = false;     // モーダルのオープンの制御フラグ
  submitDisabled: boolean = false;    // モーダルの登録・更新ボタンの制御フラグ

  runResults: any = [];               // 実行結果一覧のデータを格納するリスト
  tooltipGroup: any = [];

  // エンジニア検索フォーム　Validation設定
  searchEngineerForm = this.formBuilder.group({
    searchEngineerName: [''],
    searchRoleId: ['0'],
    searchGroupId: ['0'],
  });

  // エンジニア登録フォーム
  addEngineerForm;

  // エンジニア編集フォーム
  editEngineerForm;

  constructor(protected configService: EngineerConfigService,
              protected userService: UserService,
              public formBuilder: FormBuilder,
              config: NgbModalConfig,
              private modalService: NgbModal) {

    this.userService.getUsers().subscribe(user => {
      this.role_id = user.role_id;
      if (this.role_id > 3) {
        // ログインしているユーザーの権限がGuneralUser以下の場合はダッシュボードに遷移する
        location.href = '/dashboard/synthetic#/pages/scenario-summary';
      }
    });

    // モーダルの設定
    config.backdrop = 'static';
    config.keyboard = false;
    config.windowClass = 'configs-modal';
  }

  ngOnInit(): void {
    // 検索エリアの権限のリストを取得
    this.configService.getEngineerRoles().subscribe(response => {
      this.roles = response;
    });

    // 検索エリアのグループのリストを取得
    this.configService.getGroups().subscribe(response => {
      this.userGroups = response;
    });

    // エンジニア一覧初期表示
    this.searchEngineerConfigs();
  }

  /**
   * エンジニア一覧に表示するデータを取得する
   */
  searchEngineerConfigs(): void {
    // ローディングのエフェクト開始
    this.isLoading = true;

    this.configService.getEngineerConfigs(this.searchRoleId.value,
                                          this.searchEngineerName.value,
                                          this.searchGroupId.value)
      .subscribe(response => {
      this.engineerConfigs = response;

      // ローディングのエフェクト終了
      this.isLoading = false;
    });
  }

  /**
   * エンジニア登録モーダル表示
   * @param addEngineer
   */
  openAddEngineer(addEngineer): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.addEngineerData = new FormData();

    // エンジニア登録フォーム　Validation設定
    this.addEngineerForm = this.formBuilder.group({
      addRoleId: ['', [Validators.required]],
      addEmail: ['', [Validators.required, Validators.email, this.addEmailValidator, Validators.maxLength(191)]],
      addPassword: ['', [Validators.required, Validators.maxLength(20)]],
      addEngineerName: ['', [Validators.required, Validators.maxLength(191)]],
    });

    this.modalService.open(addEngineer);
    this.modalDisabled = false;
  }

  /**
   * エンジニア編集モーダル表示
   * @param editEngineer
   */
  openEditEngineer(engineerId, editEngineer): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.addEngineerData = new FormData();

    // エンジニア編集モーダルに必要な情報を取得する
    this.configService.getEngineerConfig(engineerId).subscribe(response => {
      if (response.success) {
        const param = response.success.param;
        // エンジニア編集フォーム　Validation設定
        this.editEngineerForm = this.formBuilder.group({
          editEngineerId: [param.id],
          editRoleId: [param.role_id, [Validators.required]],
          editEmail: [param.email,
                      [Validators.required,
                       Validators.email,
                       this.addEmailValidator,
                       Validators.maxLength(191),
                      ]],
          editEngineerName: [param.name, [Validators.required, Validators.maxLength(191)]],
          editPassword: [],
        });

        this.modalService.open(editEngineer);
        this.modalDisabled = false;
      } else {
        this.closeModal(response);
      }
    });
  }

  /**
   * エンジニア登録モーダル　登録ボタン押下処理
   */
  onAddEngineerSubmit(): boolean {
    this.submitDisabled = true;
    this.isSubmitted = true;

    // バリデーションのチェックに違反する場合は処理を中断する
    if (this.addEngineerForm.invalid) {
      this.modalMessage = '入力内容が正しくありません。';
      this.submitDisabled = false;
      return false;
    }

    // 入力内容をフォームデータに設定する
    this.addEngineerData.append('addRoleId', this.addRoleId.value);                 // 権限
    this.addEngineerData.append('addEmail', this.addEmail.value);                   // メールアドレス
    this.addEngineerData.append('addPassword', this.addPassword.value);             // パスワード
    this.addEngineerData.append('addEngineerName', this.addEngineerName.value);     // ユーザー名

    // フォームデータをサーバに送信する
    this.configService.addEngineerConfigs(this.addEngineerData).subscribe(response => {
      if (response.errors) {
        this.modalMessage = response.errors.message;
      } else {
        // エンジニア一覧をリロードする
        this.searchEngineerConfigs();
        // モーダルを閉じる
        this.modalService.dismissAll('Submit Success');
      }
      this.submitDisabled = false;
    });

    return true;
  }

  /**
   * Validators.emailのチェックを補助
   * 'test@test'の形式の入力がOKになるので、こちらでエラー判定にする。
   * @param formControl
   */
  private addEmailValidator(formControl: AbstractControl) {
    const val = formControl.value;
    const reg = /.*\.[A-Za-z0-9]{1,}$/;

    return !reg.test(val) ? { addEmailValidator: true } : null;
  }

  /**
   * エンジニア編集モーダル　パスワードリセット押下処理
   */
  passwordReset(): void {
    this.editPassword.setValue('test');
  }

  /**
   * エンジニア編集モーダル　更新ボタン押下処理
   */
  onEditEngineerSubmit(): boolean {
    this.submitDisabled = true;
    this.isSubmitted = true;

    // バリデーションのチェックに違反する場合は処理を中断する
    if (this.editEngineerForm.invalid) {
      this.modalMessage = '入力内容が正しくありません。';
      this.submitDisabled = false;
      return false;
    }

    // 入力内容をフォームデータに設定する
    this.addEngineerData.append('editEngineerId', this.editEngineerId.value);         // エンジニアID
    this.addEngineerData.append('editRoleId', this.editRoleId.value);                 // 権限
    this.addEngineerData.append('editEmail', this.editEmail.value);                   // メールアドレス
    this.addEngineerData.append('editEngineerName', this.editEngineerName.value);     // エンジニア名
    if (this.editPassword.value) {
      this.addEngineerData.append('editPassword', this.editPassword.value);           // パスワード
    }

    // フォームデータをサーバに送信する
    this.configService.editEngineerConfigs(this.addEngineerData).subscribe(response => {
      if (response.errors) {
        this.modalMessage = response.errors.message;
      } else {
        this.closeModal(response);
      }
      this.submitDisabled = false;
    });

    this.isSubmitted = false;
  }

  /**
   * エンジニア編集モーダル　削除ボタン押下処理
   */
  deleteEngineer(): void {
    this.submitDisabled = true;
    if (window.confirm('このエンジニアを削除しますか？')) {
      this.configService.deleteEngineer(this.editEngineerId.value).subscribe(response => {
        this.closeModal(response);
      });
    } else {
      this.submitDisabled = false;
    }
  }

  /**
   * モーダルを閉じてエンジニア一覧をリロードする
   */
  private closeModal(response) {
    if (response.exception) {
      alert(response.exception.message);
    }

    if (response.success) {
      alert(response.success.message);
    }

    // エンジニア一覧をリロードする
    this.searchEngineerConfigs();
    // モーダルを閉じる
    this.modalService.dismissAll('Submit Success');
    this.submitDisabled = false;
  }

  /**
   * 担当グループ設定画面へ遷移
   */
  goEngineerGroupConfig(engineerId): void {
    // エンジニア編集モーダルに必要な情報を取得する
    this.configService.getEngineerConfig(engineerId).subscribe(response => {
      if (response.success) {
        this.configService.engineerId = engineerId;
        location.href = 'synthetic#/pages/engineer-group-config';
      } else {
        this.closeModal(response);
      }
    });
  }

  /**
   * getter 検索エリア
   */
  // エンジニア名
  get searchEngineerName(){
    return this.searchEngineerForm.get('searchEngineerName');
  }

  // 権限
  get searchRoleId(){
    return this.searchEngineerForm.get('searchRoleId');
  }

  // グループ
  get searchGroupId(){
    return this.searchEngineerForm.get('searchGroupId');
  }

  /**
   * getter エンジニア登録フォーム
   */
  // 権限
  get addRoleId(){
    return this.addEngineerForm.get('addRoleId');
  }

  // メールアドレス
  get addEmail(){
    return this.addEngineerForm.get('addEmail');
  }

  // パスワード
  get addPassword(){
    return this.addEngineerForm.get('addPassword');
  }

   // エンジニア名
  get addEngineerName(){
    return this.addEngineerForm.get('addEngineerName');
  }

  /**
   * getter エンジニア編集フォーム
   */
  // エンジニアID
  get editEngineerId(){
    return this.editEngineerForm.get('editEngineerId');
  }

  // 権限
  get editRoleId(){
    return this.editEngineerForm.get('editRoleId');
  }

  // メールアドレス
  get editEmail(){
    return this.editEngineerForm.get('editEmail');
  }

  // エンジニア名
  get editEngineerName(){
    return this.editEngineerForm.get('editEngineerName');
  }

  // パスワード
  get editPassword(){
    return this.editEngineerForm.get('editPassword');
  }
}
