import { Component, OnDestroy } from '@angular/core';
import { ANIMATION_TYPES } from 'ngx-loading';
import { SearchService } from '../../@core/data/search.service';
import { ElasticsearchService } from '../../@core/data/elasticsearch.service';
import { ThresholdService } from '../../@core/data/threshold.service';
import { LoadingService } from '../../@core/data/loading.service';
import { Router } from '@angular/router';
import speedIndexJson from 'assets/query/pages/overview/speedIndex.json';
import fmpJson from 'assets/query/pages/overview/fmp.json';
import eumJson from 'assets/query/pages/overview/eum.json';
import psiJson from 'assets/query/pages/overview/psi.json';

/**
 * 概要値タイプクラス
 */
class OverviewValueType {
  name: string;
  url: string;
  value: string;
  colorStyle: string;
}

/**
 * 下記概要のレンダリングを行うコンポネント
 * - Speed Index
 * - FMP
 * - PSI
 * - EUM
 */
@Component({
  selector: 'ngx-overview',
  styleUrls: ['./overview.component.scss'],
  templateUrl: './overview.component.html',
})
export class OverviewComponent implements OnDestroy {
  /** 全サイト名マップ */
  allSiteNameMap: any = {};
  /** SpeedIndex値配列 */
  speedIndexValues: any = [];
  /** FMP値配列 */
  fmpValues: any = [];
  /** PSI値配列 */
  psiValues: any = [];
  /** EUM値配列 */
  eumValues: any = [];
  /** テーマ用Subscribeオブジェクト */
  themeSubscription: any;
  /** Elasticsearch用Subscribeオブジェクト一覧 */
  elasticSubscriptions = [];
  /** 検索条件 */
  condition: any = {};
  /** Subscribeオブジェクト一覧 */
  subscriptions = [];
  /** SpeedIndexゼロフラグ */
  isSpeedIndexZero: boolean = false;
  /** FMPゼロフラグ */
  isFmpZero: boolean = false;
  /** PSIゼロフラグ */
  isPsiZero: boolean = false;
  /** PSIゼロフラグ */
  isEumZero: boolean = false;
  /** ページローディングフラグ */
  isLoading: boolean = true;
  /** ローディング処理設定 */
  loadingConfig: any = {};
  /** 取得サイト名一覧。この一覧のサイトを1行目に表示する。 */
  resultSiteNameList: any = [];
  /** speedIndex取得url一覧。この一覧のeumを取得する。 */
  resultUrlList: any = [];

  constructor(
    protected searchService: SearchService,
    protected elasticsearchService: ElasticsearchService,
    protected thresholdService: ThresholdService,
    protected loadingService: LoadingService,
    private router: Router,
  ) {
    // 絞り込みは未実装なので解除
    this.condition = this.searchService.getCondition();
    this.allSiteNameMap = this.searchService.getAllSiteNameMap();
    const vm = this;
    this.loadingService.reset();
    this.loadingConfig = {
      animationType: ANIMATION_TYPES.threeBounce,
    };
    if (Object.getOwnPropertyNames(this.allSiteNameMap).length !== 0) {
      this.search_draw();
    } else {
      this.isSpeedIndexZero = true;
      this.isFmpZero = true;
      this.isPsiZero = true;
      this.isEumZero = true;
      this.isLoading = false;
    }
    // 検索条件が変わった際のイベントを登録
    this.subscriptions.push(this.searchService.getConditionState().subscribe(res => {
      vm.stopElasticRequest();
      vm.isLoading = true;
      // 条件の取得
      vm.condition = vm.searchService.getCondition();
      vm.allSiteNameMap = vm.searchService.getAllSiteNameMap();
      vm.search_draw();
    }));

    // EUMの概要データ検索
    this.subscriptions.push(this.searchService.getOverviewEumStatus().subscribe(res => {
      vm.search_eum();
    }));

    this.subscriptions.push(this.loadingService.getLoadConditionStatus().subscribe(res => {
      vm.isLoading = false;
    }));
  }

  /**
   * コンポーネント破棄時処理
   */
  ngOnDestroy() {
    this.subscriptions.forEach(function(value) {
      value.unsubscribe();
    });
    this.stopElasticRequest();
  }

  /**
   * Elasticsearchリクエストを中止
   */
  stopElasticRequest(): void {
    this.elasticSubscriptions.forEach(function(value){
      value.unsubscribe();
    });
    this.elasticSubscriptions = [];
  }

  /**
   * サーバ側で下記のクエリを検索する
   * - SpeedIndex
   * - FMP
   * - PSI
   */
  private search_draw() {
    this.searchInit();
    this.search_speedIndex();
    this.search_fmp();
    this.search_psi();
    // this.search_eum();
  }

 /**
  * 検索の最初に呼び出すべき前処理
  */
  private searchInit() {
    this.isSpeedIndexZero = false;
    this.isFmpZero = false;
    this.isPsiZero = false;
    this.isEumZero = false;
  }

  /**
   * サーバ側でSpeedIndexのクエリを検索する
   */
  private search_speedIndex() {
    const vm = this;
    // elasticsearch queryを作成する。
    const jsonConfig: any = speedIndexJson;

    // if (jsonConfig.default && jsonConfig.default.length > 0)
    if (jsonConfig.length > 0) {
      // const query: any = jsonConfig[0];
      const query: any = JSON.parse(JSON.stringify(jsonConfig[0])); // deep copy
      query.body.query.bool.must[0].range = {
        '@timestamp': {
          'gte': vm.condition.start,
          'lte': vm.condition.end,
          'format': 'epoch_millis',
        },
      };
      const strategyQuery = {'term' : {'strategy': vm.condition.device}};
      query.body.query.bool.must.push(strategyQuery);

      this.elasticSubscriptions.push(vm.elasticsearchService.searchWithProxy(query)
        .subscribe(response => {
          if (!response.aggregations) {
            vm.isSpeedIndexZero = true;
            vm.searchService.setOverviewEumStatus();
            vm.loadingService.setLoadConditionStatus();
            return;
          }

          const aggsTargets = response.aggregations.page_aggs.buckets;
          const targets = [];
          for (const averageTarget of aggsTargets) {
            const target = new OverviewValueType();
            target.url = this.allSiteNameMap[averageTarget.key];
            const value = averageTarget.median.values[0].value;
            target.colorStyle = vm.thresholdService.getValueColor('fmp', value);
            target.value = Math.round(value).toString();
            targets.push(target);
          }
          // 実際にデータ取得できたサイト一覧を詰める。speedIndexは最初の検索なので必ず詰める
          this.setSiteList(vm, aggsTargets, true);
          vm.speedIndexValues = targets;
          if (vm.speedIndexValues.length === 0) {
            vm.isSpeedIndexZero = true;
          }
          vm.loadingService.setLoadConditionStatus();
          vm.searchService.setOverviewEumStatus();
        },
      ));
    }
  }

  /**
   * サーバ側でFMPのクエリを検索する
   */
  private search_fmp() {
    const vm = this;
    // elasticsearch queryを作成する。
    const jsonConfig: any = fmpJson;
    if (jsonConfig.length !== 0) {
      // const query: any = jsonConfig.default[0];
      const query: any = JSON.parse(JSON.stringify(jsonConfig[0])); // deep copy
      query.body.query.bool.must[0].range = {
        '@timestamp': {
          'gte': vm.condition.start,
          'lte': vm.condition.end,
          'format': 'epoch_millis',
        },
      };
      const strategyQuery = {'term' : {'strategy': vm.condition.device}};
      query.body.query.bool.must.push(strategyQuery);

      this.elasticSubscriptions.push(vm.elasticsearchService.searchWithProxy(query)
        .subscribe(response => {
          if (!response.aggregations) {
            vm.isFmpZero = true;
            return;
          }

          const aggsTargets = response.aggregations.page_aggs.buckets;
          const targets = [];
          for (const aggsTarget of aggsTargets) {
            const target = new OverviewValueType();
            target.url = this.allSiteNameMap[aggsTarget.key];
            const value = aggsTarget.FMP.median.values[0].value;
            target.colorStyle = vm.thresholdService.getValueColor('fmp', value);
            target.value = (Math.round(value / 100) / 10).toFixed(1);
            targets.push(target);
          }
          // speedIndexを取得できていなかったサイトをここで補う。ソートはspeedindexで行うので、順番は変わらない。
          this.setSiteList(vm, aggsTargets, false);
          vm.fmpValues = targets;
          if (vm.fmpValues.length === 0) {
            vm.isFmpZero = true;
          }
        },
      ));
    }
  }

  /**
   * サーバ側でPSIのクエリを検索する
   */
  private search_psi() {
    const vm = this;
    const jsonConfig: any = psiJson;
    if (jsonConfig.length !== 0) {
      // const query: any = jsonConfig.default[0];
      const query: any = JSON.parse(JSON.stringify(jsonConfig[0])); // deep copy
      query.body.query.bool.must[0].range = {
        '@timestamp': {
          'gte': vm.condition.start,
          'lte': vm.condition.end,
          'format': 'epoch_millis',
        },
      };
      const strategyQuery = {'term' : {'strategy': vm.condition.device}};
      query.body.query.bool.must.push(strategyQuery);

      this.elasticSubscriptions.push(vm.elasticsearchService.searchWithProxy(query)
        .subscribe(response => {
          if (!response.aggregations) {
            vm.isPsiZero = true;
            return;
          }

          const aggsTargets = response.aggregations.page_aggs.buckets;
          const targets = [];
          for (const aggsTarget of aggsTargets) {
            const target = new OverviewValueType();
            target.url = this.allSiteNameMap[aggsTarget.key];
            const value = aggsTarget.psi.median.values[0].value;
            target.colorStyle = vm.thresholdService.getValueColor('psi', value);
            target.value = value;
            targets.push(target);
          }
          // speedIndexを取得できていなかったサイトをここで補う。
          this.setSiteList(vm, aggsTargets, false);
          vm.psiValues = targets;
          if (vm.psiValues.length === 0) {
            vm.isPsiZero = true;
          }
        },
      ));
    }
  }

  /**
   * サーバ側でEUMのクエリを検索する
   */
  private search_eum() {
    const vm = this;
    const jsonConfig: any = eumJson;
    if (jsonConfig.length !== 0) {
      // const query: any = jsonConfig.default[0];
      const query: any = JSON.parse(JSON.stringify(jsonConfig[0])); // deep copy
      query.body.query.bool.must[0].range = {
        '@timestamp': {
          'gte': vm.condition.start,
          'lte': vm.condition.end,
          'format': 'epoch_millis',
        },
      };

      this.elasticSubscriptions.push(vm.elasticsearchService.searchEumWithProxy(query, true, 'overview')
        .subscribe(response => {
          if (!response.aggregations) {
            vm.isEumZero = true;
            return;
          }
          const averageTargets = response.aggregations.page_aggs.buckets;
          const targets = [];

          // eumの集計結果から、対象urlに一致するものを探して、詰め替える。
          for (const eachurl of vm.resultUrlList){
            const target = new OverviewValueType();
            target.value = '-';
            target.url = eachurl;
            for (const averageTarget of averageTargets) {
              if (eachurl === vm.allSiteNameMap[averageTarget.key]) {
                target.colorStyle = vm.thresholdService.getValueColor('eum', averageTarget.median.values[0].value);
                target.value = (Math.round(averageTarget.median.values[0].value / 100) / 10).toFixed(1);
                break;
              }
            }
            targets.push(target);
          }
          vm.eumValues = targets;
          if (vm.eumValues.length === 0) {
            vm.isEumZero = true;
          }
        },
      ));
    }
  }

  /**
   * 概要の対象サイトがクリックされた際、ドリルダウン処理を行う
   * @param val 対象サイト
   */
  selectedItem(val): void {
    // siteを絞る
    this.searchService.setDrillDownOption(val);
    this.searchService.setDrillDownConditionStatus();
    this.router.navigate(['/pages/analysis'], { queryParams: { selectedSite: val } });
  }

  /**
   * データを取得できたサイト一覧を表示対象・およびeum取得対象としてセットする。
   * @param vm 概要ページ表示用インスタンス
   * @param aggsTargets 測定データ
   * @param isFirst 概要ページで行う初めての検索か否か
   */
  setSiteList(vm, aggsTargets, isFirst: boolean): void {
    const resultUrlListTemp = [];
    const resultSiteNameListTemp = [];
    for (const averageTarget of aggsTargets) {
      const target = new OverviewValueType();
      target.name = averageTarget.key;
      resultSiteNameListTemp.push(target.name);
      target.url = this.allSiteNameMap[averageTarget.key];
      resultUrlListTemp.push(target.url);
    }
    // 概要ページでの初めての検索か、測定できたページがいままでの検索よりも多かった場合、上書きする。
    if (isFirst || vm.resultSiteNameList.length < resultSiteNameListTemp.length) {
      vm.resultSiteNameList = resultSiteNameListTemp;
      vm.resultUrlList = resultUrlListTemp;
    }
  }
}
