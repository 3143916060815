import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';

/**
 * レポートのPlan内容をテーブルでレンダリングするコンポネント
 */
@Component({
    selector: 'ngx-report-table',
    templateUrl: './report-table.component.html',
    styles: [`
        :host ::ng-deep ng2-smart-table table tr th a {
            color: #ffffff;
        }

        :host ::ng-deep ng2-smart-table thead > tr > th  {
            background-color: #3393e6e0;
        }

        :host ::ng-deep ng2-smart-table tbody > tr {
            background-color: #e6f3ff !important;
        }

        ::ng-deep ng2-smart-table table tr td {
            max-width: 400px;
            border: 2px solid #ffffff !important;
        }

        div.plan-detail-table {
            margin-left: 2rem;
        }
    `],
})
export class ReportTableComponent implements OnInit, OnDestroy {
    /** レポート内容(HTML入力) */
    @Input() data: any;
    /** テーブルのデータソース */
    source: LocalDataSource = new LocalDataSource();
    /** テーブル設定 */
    settings: any;

    constructor(
    ) { }

    /**
     * コンポネント初期処理
     */
    ngOnInit() {
        this.settings = {
            actions: false,
            hideSubHeader: true,
            columns: {
                item: {
                    title: '項目名',
                    type: 'string',
                },
                content: {
                    title: '内容',
                    type: 'html',
                },
            },
        };
        this.parseData();
    }

    /**
     * コンポネント破棄時処理
     */
    ngOnDestroy() {
    }

    /**
     * レポートデータを利用し、テーブルデータを作成
     */
    private parseData() {
        const parseData = [];

        parseData.push({
            item: '修正による予測向上速度',
            content: this.data.improve + 'msec',
        });

        parseData.push({
            item: '修正難易度',
            content: this.data.difficulty,
        });

        const plan = this.data.plan;
        const plan_content = this.parseFormat(plan);

        parseData.push({
            item: '修正方針',
            content: plan_content,
        });

        this.source.load(parseData);
    }

    /**
     * 対象のレポートフォーマットの編集処理
     * @param plan 対象レポート
     */
    private parseFormat(plan: any): string {
        let re;
        if (plan.args.length > 0) {
            for (const arg of plan.args) {
                re = new RegExp('(\{)+' + arg.key + '(\})+', 'g');
                plan.format = plan.format.replace(re,
                    '<a href="' + arg.value + '">' + arg.display + '</a>');
            }
        }
        return plan.format;
    }
}
