import { Component, Input, OnInit, ViewChild, ViewEncapsulation, HostListener } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryComponent } from 'ngx-gallery';
import 'hammerjs';

/**
 * Caurosel Thumbnailsを利用した、画像プリビューコンポネント
 */
@Component({
  selector: 'ngx-screen-capture-modal',
  template: `
    <ngx-gallery #gallery style="background:#aaa" [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
  `,
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .myModal .modal-dialog {
        max-width: 800px;
        padding-top: 100px;
    }
    .myModal .modal-content {
        line-height: 0;
    }
    .ngx-gallery-thumbnails > .ngx-gallery-active {
        border: 3px solid #40dc7e;
        background-color: #9a9a9a;
    }
  `],
})
export class ScreenCaptureModalComponent implements OnInit {
  /** 画像コンポネントオブジェクト */
  @ViewChild('gallery', {static: false}) gallery: NgxGalleryComponent;
  /** 画像コンポネント設定(HTML入力) */
  @Input() galleryOptions: NgxGalleryOptions[];
  /** 画像コンポネントの表示用画像配列(HTML入力) */
  @Input() galleryImages: NgxGalleryImage[];
  /** 画像コンポネントの画像表示インデクス(HTML入力) */
  @Input() startIndex: number;

  constructor() {  }

  /**
   * コンポネント初期処理
   */
  ngOnInit(): void {
  }

  /**
   * 画像プリビューの動きをキーボートで操作
   * @param event キーボートイベント
   */
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      if (this.gallery.canShowNext()) {
        this.gallery.showNext();
      }
    } else if (event.key === 'ArrowLeft') {
      if (this.gallery.canShowPrev()) {
        this.gallery.showPrev();
      }
    }
  }
}
