import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ScreenCaptureFilterService } from '../../../@core/data/screencapture.filter.service';

/**
 * スクリーンキャプチャフィルタコンポネント
 */
@Component({
    selector: 'ngx-screen-capture-filter',
    styleUrls: ['./screen-capture-filter.component.scss'],
    templateUrl: './screen-capture-filter.component.html',
})
export class ScreenCaptureFilterComponent implements OnInit, OnDestroy {
    /** フィルタ表示フラグ(HTML入力) */
    @Input() show = false;
    /** 全スクリーンキャプチャ表示フラグ */
    isShowAllDisabled = false;
    /** 最初５サイトスクリーンキャプチャ表示フラグ */
    isShowTop5Disabled = true;
    /** 最初５サイトの表示リミット */
    top5Limit = 5;
    /** スクリーンキャプチャのSubscribeオブジェクト */
    screenSubscription: any;
    /** フィルタ対象項目一覧 */
    itemList = [];
    /** 選択後フィルタ対象項目一覧 */
    selectedItems = [];
    /** フィルタ設定 */
    settings = {};

    constructor(protected screencaptureFilterService: ScreenCaptureFilterService) {
    }

    /**
     * コンポネント初期処理
     */
    ngOnInit() {
        this.screenSubscription = this.screencaptureFilterService.getTargetSitesState().subscribe(res =>
            this.showTopFive(),
        );
    }

    /**
     * コンポーネント破棄時処理
     */
    ngOnDestroy(): void {
        this.screenSubscription.unsubscribe();
    }

    /**
     * 最初５サイトのスクリーンキャプチャのレンダリングを行う
     */
    showTopFive() {
        this.isShowTop5Disabled = true;
        this.isShowAllDisabled = false;
        this.show = false;
        this.selectedItems = [];
        const top5TargetSites: any[] = [];
        const targetSites = this.screencaptureFilterService.getSortedTargetSiteList();

        if (targetSites.length > 0) {
            let limit = targetSites.length;
            if (limit > this.top5Limit) {
                limit = this.top5Limit;
            }

            for (let i = 0; i < limit; i++) {
                top5TargetSites.push(targetSites[i]);
            }
            this.screencaptureFilterService.setDisplayTargetSitesState(top5TargetSites);
        }
    }

    /**
     * 全サイトのスクリーンキャプチャのレンダリングを行う
     */
    showAll() {
        this.isShowTop5Disabled = false;
        this.isShowAllDisabled = true;
        this.show = false;
        this.selectedItems = [];
        const targetSites = this.screencaptureFilterService.getSortedTargetSiteList();
        this.screencaptureFilterService.setDisplayTargetSitesState(targetSites);
    }

    /**
     * フィルタ項目を選択した際の処理
     * @param item 対象項目
     */
    onItemSelect(item: any) {
        this.isShowTop5Disabled = false;
        this.isShowAllDisabled = false;
        this.filterOnSelect();
    }

    /**
     * フィルタ項目を選択削除した際の処理
     * @param item 対象項目
     */
    OnItemDeSelect(item: any) {
        this.isShowTop5Disabled = false;
        this.isShowAllDisabled = false;
        this.filterOnSelect();
    }

    /**
     * フィルタの全項目を選択する際の処理
     * @param items 対象項目
     */
    onSelectAll(items: any) {
        this.isShowTop5Disabled = false;
        this.isShowAllDisabled = false;
        this.filterOnSelect();
    }

    /**
     * フィルタの全項目を選択削除する際の処理
     * @param items 対象項目
     */
    onDeSelectAll(items: any) {
        this.isShowTop5Disabled = false;
        this.isShowAllDisabled = false;
        this.filterOnSelect();
    }

    /**
     * フィルタの項目一覧を作成
     */
    ceateItemList() {
        this.show = !this.show;
        if (this.show) {
            const targetSites = this.screencaptureFilterService.getAllTargetSitesList();
            this.itemList = [];
            if (targetSites.length > 0) {
                targetSites.forEach((targetSite, targetSiteIndex) => {
                    this.itemList.push({
                        'id': targetSiteIndex + 1,
                        'itemName': targetSite.pageTitle,
                    });
                });
            }

            this.settings = {
                text: 'サイトを選択',
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                badgeShowLimit: 2,
            };
        }
    }

    /**
     * フィルタの項目が選択された際、スクリーンキャプチャの表示対象サイトを更新する
     */
    private filterOnSelect() {
        const targetSites = this.screencaptureFilterService.getAllTargetSitesList();
        const selectedSites: any[] = [];
        const vm = this;
        if (targetSites.length > 0) {
            for (let i = 0; i < this.selectedItems.length; i++) {
                targetSites.forEach((targetSite, targetSiteIndex) => {
                    if (targetSite.pageTitle === vm.selectedItems[i].itemName) {
                        selectedSites.push(targetSite);
                    }
                });
            }
            this.screencaptureFilterService.setDisplayTargetSitesState(selectedSites);
        }
    }
}
