import { Component,EventEmitter, Input, OnInit,Output } from '@angular/core';
import { ScenarioSummary } from 'app/models/scenario.model';
import { ScenarioDetailService } from 'app/@core/data/scenario-detail.service';

@Component({
  selector: 'ngx-lighthouse-report',
  templateUrl: './lighthouse-report.component.html',
  styleUrls: ['./lighthouse-report.component.scss'],
})

export class LighthouseReportComponent implements OnInit {

  @Input() scenarioSummaries: ScenarioSummary;

  @Output() pageDetailDataLift=new EventEmitter<any>();
  // 選択中のデバイスフィルター
  selectedDevices: Array<string> = [];

  constructor(
    private scenarioDetailService: ScenarioDetailService,
  ) { }

  ngOnInit() {
    this.scenarioDetailService.getSelectedDevices().subscribe(data => {
      this.selectedDevices = data;
    });
    this.pageDetailDataLift.emit(this.scenarioSummaries[0].strategies);
  }

  get devices() {
    return this.scenarioDetailService.getAllDevices();
  }
}
