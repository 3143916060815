import { Component } from '@angular/core';

/**
 * Echartsコンポネント
 */
@Component({
  selector: 'ngx-echarts',
  styleUrls: ['./echarts.component.scss'],
  templateUrl: './echarts.component.html',
})
export class EchartsComponent {}
