import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as moment from 'moment';
import { ElasticsearchService } from 'app/@core/data/elasticsearch.service';
import { ScenarioDetailService } from 'app/@core/data/scenario-detail.service';
import scenarioChartPageTransitionJson from 'assets/query/pages/charts/scenario_chart_page_transition.json';
import scenarioChartPageContentJson from 'assets/query/pages/charts/scenario_chart_page_content.json';
import { TimelineMetric, TimelineDevice, TimelinePage, TimelineValue } from 'app/models/scenario.model';

@Component({
  selector: 'ngx-timeline-info',
  templateUrl: './timeline-info.component.html',
  styleUrls: ['./timeline-info.component.scss'],
})
export class TimelineInfoComponent implements OnInit {

  @Output() setLoading = new EventEmitter<boolean>();

  @Input() selectedScenario: number;
  @Input() selectedDevices:Array<string> = [];
  @Input() metric: any;
  @Input() defaultPeriodDifferencial: any;

  @Input() measurementStandard: any;

  timelineData: TimelineMetric;

  // 選択中のデバイスフィルター

  selectedCondition: string = '';

  showDatepicker = 'close_datepicker';

  showPeriod: string = '期間';

  constructor(
    protected elasticsearchService: ElasticsearchService,
    private scenarioDetailService: ScenarioDetailService,
  ) {
  }

  ngOnInit() {
    this.timelineData = Object.create(this.metric);

    // this.scenarioDetailService.getSelectedDevices().subscribe(data => {
    //   this.selectedDevices = data;
    // });

    // if (this.defaultPeriodDifferencial === 'custom') {
    //   const condition = JSON.parse(sessionStorage.getItem('condition'));
    //   if (condition === null) {
    //     this.displaySelectionPeriod({
    //       start: moment().subtract(parseInt('4320', 10), 'minute'),
    //       end: moment(),
    //     });
    //   } else {
    //     this.displaySelectionPeriod({
    //       start: moment(condition.start),
    //       end: moment(),
    //     });
    //   }
    // } else {
    //   this.displaySelectionPeriod({
    //     start: moment().subtract(parseInt(this.defaultPeriodDifferencial, 10), 'minute'),
    //     end: moment(),
    //   });
    // }
    this.selectedCondition = this.defaultPeriodDifferencial;
  }

  onConditionSelect(metricName: string) {
    this.setLoading.emit(true);
    if (this.selectedCondition === 'custom') {
      this.showDatepicker = '';
    } else {
      this.showDatepicker = 'close_datepicker';
      const period = {
        start: moment().subtract(parseInt(this.selectedCondition, 10), 'minute').valueOf(),
        end: moment().valueOf(),
      };
      this.setTimelineMetrics(period, metricName);
      this.displaySelectionPeriod({
        start: moment().subtract(parseInt(this.selectedCondition, 10), 'minute'),
        end: moment(),
      });
    }
  }

  setTimelineMetrics(period: any, metricName: string) {
    this.showDatepicker = 'close_datepicker';

    const query: any = scenarioChartPageTransitionJson[0];
    const contentquery: any = scenarioChartPageContentJson[0];

    const range = {
      '@timestamp': {
        'gte': period.start,
        'lte': period.end,
        'format': 'epoch_millis',
      },
    };

    query.body.query.bool.must[0].range = range;
    contentquery.body.query.bool.must[0].range = range;

    query.body.query.bool.must[1].term.strategy = 'desktop';
    contentquery.body.query.bool.must[1].term.strategy = 'desktop';

    query.body.query.bool.must[2].term.scenarioConfigId = this.selectedScenario;
    contentquery.body.query.bool.must[2].term.scenarioConfigId = this.selectedScenario;

    this.elasticsearchService.searchScenarioLineChartWithProxy(query, contentquery, metricName).subscribe(data => {
      const timelineMetrics = data.map(timelineMetric => new TimelineMetric(
        timelineMetric.metricName,
        timelineMetric.devices.map(timelineDevice => new TimelineDevice(
          timelineDevice.deviceJPName,
          timelineDevice.pages.map(timelinePage => new TimelinePage(
            timelinePage.pageName,
            timelinePage.metrics.length ? timelinePage.metrics.map(timelineValue => new TimelineValue(
              timelineValue.date,
              timelineValue.value,
            )) : [],
          )),
        )),
      ));

      this.setLoading.emit(false);

      if (Array.isArray(timelineMetrics)) {
        const scenario_data = timelineMetrics[0];
        if (scenario_data) {
          this.timelineData.devices = scenario_data.devices;
        } else {
          this.timelineData.devices = [];
        }
      }
    });
  }

  displaySelectionPeriod(selectionPeriod: any) {
    this.showPeriod = selectionPeriod.start.format('YYYY/MM/DD') + '~' + selectionPeriod.end.format('YYYY/MM/DD');
  }

  closeDatepicker() {
    this.showDatepicker = 'close_datepicker';
  }
}
