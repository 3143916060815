import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { ChartModule } from 'angular2-chartjs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxGalleryModule } from 'ngx-gallery';
import { ChartsModule } from '../charts/charts.module';
import { LoadingModule } from 'ngx-loading';

import { ThemeModule } from '../../@theme/theme.module';
import { EngineerConfigsComponent } from './engineer-configs.component';
import { HeaderComponent } from '../../@theme/components/header/header.component';

@NgModule({
  imports: [
    ThemeModule,
    NgxEchartsModule,
    ChartModule,
    ChartsModule,
    Ng2SmartTableModule,
    NgxChartsModule,
    NgxGalleryModule,
    LoadingModule,
  ],
  providers: [
    HeaderComponent,
  ],
  declarations: [
    EngineerConfigsComponent,
  ],
})
export class EngineerConfigsModule { }
