import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { OverviewComponent } from './overview/overview.component';
import { ReportComponent } from './report/report.component';
import { ScenarioConfigsComponent } from './scenario-configs/scenario-configs.component';
import { UserActivitiesComponent } from './user-activities/user-activities.component';
import { AccountConfigComponent } from './account-config/account-config.component';
import { EngineerConfigsComponent } from './engineer-configs/engineer-configs.component';
import { EngineerGroupConfigComponent } from './engineer-group-config/engineer-group-config.component';
import { UserConfigsComponent } from './user-configs/user-configs.component';
import { GroupConfigsComponent } from './group-configs/group-configs.component';
import { GroupConfigComponent } from './group-config/group-config.component';

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [{
    path: 'dashboard',
    component: DashboardComponent,
  }, {
    path: 'overview',
    component: OverviewComponent,
  }, {
    path: 'scenario-summary',
    loadChildren: () => import('./scenario-summary/scenario-summary.module').then(m => m.ScenarioSummaryModule),
  }, {
    path: 'analysis',
    component: AnalysisComponent,
  }, {
    path: 'report',
    component: ReportComponent,
  }, {
    path: 'maps',
    loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule),
  }, {
    path: 'monitoring-config',
    component: null,
  }, {
    path: 'scenario-configs',
    component: ScenarioConfigsComponent,
  }, {
    path: 'user-activities',
    component: UserActivitiesComponent,
  }, {
    path: 'user-configs',
    component: UserConfigsComponent,
  }, {
    path: 'group-configs',
    component: GroupConfigsComponent,
  }, {
    path: 'group-config',
    component: GroupConfigComponent,
  }, {
    path: 'account-config',
    component: AccountConfigComponent,
  }, {
    path: 'engineer-configs',
    component: EngineerConfigsComponent,
  }, {
    path: 'engineer-group-config',
    component: EngineerGroupConfigComponent,
  }, {
    path: '',
    redirectTo: 'scenario-summary',
    pathMatch: 'full',
  }],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
