import { MenuItem, PAGES_INFO } from '../app-const';

export const MENU_ITEMS: MenuItem[] = [
  PAGES_INFO['/pages/scenario-summary'],
  PAGES_INFO['/pages/scenario-configs'],
  PAGES_INFO['/pages/user-activities'],
  // PAGES_INFO['/pages/account-config'],
  // PAGES_INFO['/pages/engineer-configs'],
  // PAGES_INFO['/pages/engineer-group-config'],
  // PAGES_INFO['/pages/group-configs'],
  // PAGES_INFO['/pages/group-config'],
];
