import { Component, OnDestroy, OnInit, Input, ViewChild } from '@angular/core';
import { NbThemeService, NbColorHelper } from '@nebular/theme';
import { ChartComponent } from 'angular2-chartjs';
import { SearchService } from '../../../@core/data/search.service';
import { ElasticsearchService } from '../../../@core/data/elasticsearch.service';
import { LoadingService } from '../../../@core/data/loading.service';
import { Router } from '@angular/router';
import renAvgBarStackJson from 'assets/query/pages/charts/render_avg_bar_stack.json';

/**
 * 下記グラフのレンダリングを行うコンポネント
 * - 各種レンダリング処理毎の処理時間 中央値
 */
@Component({
  selector: 'ngx-chartjs-bar-stacked',
  template: `
    <chart type="bar" [hidden]="!isShow" *ngIf="isCompInit" [data]="data" [options]="options"
    (clickElement)="clickChart($event)"></chart>
  `,
})
export class ChartjsBarStackedComponent implements OnDestroy, OnInit {
  /** Chartコンポネントオオブジェクト */
  @ViewChild(ChartComponent, {static: false}) chart: ChartComponent;
  /** グラフタイプ(HTML入力) */
  @Input() type: string = '';
  /** グラフレベル(HTML入力) */
  @Input() label: string = '';
  /** グラフ値単位(HTML入力) */
  @Input() unit: string = '';
  /** コンポネント初期化フラフ */
  isCompInit: boolean = false;
  /** グラフ表示用データ */
  data: any;
  /** グラフ設定 */
  options: any;
  /** 検索用Subscribeオブジェクト */
  searchSubscription: any;
  /** テーマ用Subscribeオブジェクト */
  themeSubscription: any;
  /** Elasticsearch用Subscribeオブジェクト */
  elasticSubscription: any;
  /** 検索条件 */
  condition: any = {};
  /** グラフの線、レブル等用色 */
  colors: any;
  /** ChartJS用色 */
  chartjs: any;
  /** サイトのURL・サイト名マップ */
  allSiteNameMap: any = {};
  /** グラフの表示フラグ */
  isShow: boolean = false;

  constructor(
    private theme: NbThemeService,
    protected searchService: SearchService,
    protected elasticsearchService: ElasticsearchService,
    protected loadingService: LoadingService,
    private router: Router,
  ) { }

  /**
   * コンポネントの初期処理
   */
  ngOnInit(): void {
    let init = false;
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

      this.condition = this.searchService.getCondition();
      this.colors = config.variables.temperature;
      this.chartjs = config.variables.chartjs;
      this.allSiteNameMap = this.searchService.getAllSiteNameMap();

      if (init) {
        return;
      }
      init = true;
      // 検索条件が変わった際のイベントを登録
      this.searchSubscription = this.searchService.getConditionState().subscribe(res => {
        this.stopElasticRequest();
        this.isShow = false;
        this.condition = this.searchService.getCondition();
        this.allSiteNameMap = this.searchService.getAllSiteNameMap();
        this.search(this.condition);
      });
      // ヘッダ初期化されていなければ初期化を待つ。
      if (Object.getOwnPropertyNames(this.allSiteNameMap).length !== 0) {
        this.search(this.condition);
      } else {
        setTimeout(() => {
          if (!this.loadingService.getLoadingStatus()) {
            this.loadingService.setLoadConditionStatus();
          }
        });
      }
    });
  }

  /**
   * コンポーネント破棄時処理
   */
  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
    this.searchSubscription.unsubscribe();
    this.stopElasticRequest();
  }

  /**
   * Elasticsearchリクエストを中止
   */
  stopElasticRequest(): void {
    if (this.elasticSubscription) {
      this.elasticSubscription.unsubscribe();
    }
  }

  /**
   * サーバ側でクエリを検索する処理
   * @param condition 検索条件
   */
  private search(condition) {
    const comp = this;
    const jsonConfig: any = renAvgBarStackJson;

    if (jsonConfig.length !== 0) {
      // const query: any = jsonConfig[0];
      const query: any = JSON.parse(JSON.stringify(jsonConfig[0])); // deep copy
      query.body.query.bool.must[0].range = {
        '@timestamp': {
          'gte': condition.start,
          'lte': condition.end,
          'format': 'epoch_millis',
        },
      };
      const strategyQuery = {'term' : {'strategy': condition.device}};
      query.body.query.bool.must.push(strategyQuery);

      this.elasticSubscription = comp.elasticsearchService.searchWithProxy(query)
        .subscribe(response => {
          // 結果がない場合は処理を行わない。
          if (!response.aggregations) {
            if (!comp.loadingService.getLoadingStatus()) {
              comp.loadingService.setLoadConditionStatus();
            }
            return;
          }
          comp.parseRenderAvg(response);
          comp.draw();
          comp.isShow = true;
          if (!comp.loadingService.getLoadingStatus()) {
            comp.loadingService.setLoadConditionStatus();
          }
        });
    }
  }

  /**
   * 検索結果のデータを利用し、各グラフデータを作成
   * @param resp 検索結果レスポンス
   */
  private parseRenderAvg(resp: any) {
    const response_page = resp.aggregations.page_aggs.buckets;
    const labels: any[] = [];
    const datasets: any[] = [];
    const labelStr: any[] = ['updateLayerTree', 'styles', 'raster',
                             'parseHTML', 'paint', 'layout', 'javaScriptCompile',
                             'javaScript', 'forcedRecalcs', 'forcedLayouts',
                             'composite'];
    let dataset_c = 0;
    let each_dataset;
    let dataset_color;
    for (let s = 0; s < 2; s++) {
      for (let c = 0; c < labelStr.length; c++) {
        each_dataset = datasets[dataset_c];
        if (c < this.colors.length) {
          dataset_color = this.colors[c];
        } else {
          dataset_color = NbColorHelper.hexToRgbA(this.colors[Math.floor(Math.random() * this.colors.length)], 0.8);
        }
        if (!each_dataset) {
          each_dataset = {
            data: [],
            label: labelStr[c],
            stack: (s + 1),
            backgroundColor: dataset_color,
            borderColor: dataset_color,
          };
          datasets[dataset_c] = each_dataset;
        }
        dataset_c++;
      }
    }

    let dataset = datasets[0];
    let index = 0;
    let strategy_terms;
    let page_terms;
    let labelIndex;
    for (let i = 0; i < response_page.length; i++) {
      labels[i] = response_page[i].key;

      // 集約階層が1段階の場合、1系列のみのデータを集約
      strategy_terms = response_page[i].strategy_terms.buckets;
      for (let j = 0; j < strategy_terms.length; j++) {
        page_terms = strategy_terms[j].page_terms.buckets;
        index = j * labelStr.length;

        for (let k = 0; k < page_terms.length; k++) {
          labelIndex = labelStr.indexOf(page_terms[k].key);
          if (labelIndex === -1) {
            labelIndex = labelStr.length - 1;
          }
          dataset = datasets[index + labelIndex];
          dataset.data.push(this.getValue(page_terms[k]));
          datasets[index + labelIndex] = dataset;
        }
      }
    }
    this.data = {
      labels: labels,
      datasets: datasets,
      seriesLabel: labelStr,
    };
  }

  /**
   * 対象データから中央値を取得
   * @param target 対象データ
   */
  private getValue(target): number {
    if (target.page_median) {
      return target.page_median.values[0].value;
    } else {
      return target.value;
    }
  }

  /**
   * レスポンスをもとにグラフを描画する。
   */
  private draw() {
    const vm = this;
    if (!this.options) {
      const unit = this.unit;
      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        animation:
        {
            duration: 0,
        },
        legend: {
          labels: {
            fontColor: this.chartjs.textColor,
            generateLabels: function (chart) {
              const max_label = vm.data.seriesLabel.length - 1;
              return chart.data.datasets.map(function (dataset, i) {
                if (i <= max_label) {
                  return {
                    text: dataset.label,
                    fillStyle: dataset.backgroundColor,
                    hidden: !chart.isDatasetVisible(i),
                    lineCap: dataset.borderCapStyle,
                    lineDash: [],
                    lineDashOffset: 0,
                    lineJoin: dataset.borderJoinStyle,
                    lineWidth: dataset.pointBorderWidth,
                    strokeStyle: dataset.borderColor,
                    pointStyle: dataset.pointStyle,
                    datasetIndex: i,  // extra data used for toggling the datasets
                  };
                } else {
                  return {
                    text: '',
                    datasetIndex: i,
                    fillStyle: 'white',
                    strokeStyle: 'white',
                    pointStyle: 'default',
                  };
                }
              });
            },
          },
          onClick: function (e, legendItem){
            const d_index = legendItem.datasetIndex;
            const ci = this.chart;
            const m_index = d_index + (ci.data.datasets.length / 2);
            const meta_desktop = ci.getDatasetMeta(d_index);
            const meta_mobile = ci.getDatasetMeta(m_index);

            meta_desktop.hidden = meta_desktop.hidden === null ? !ci.data.datasets[d_index].hidden : null;
            meta_mobile.hidden = meta_mobile.hidden === null ? !ci.data.datasets[m_index].hidden : null;

            ci.update();
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
                color: this.chartjs.axisLineColor,
              },
              ticks: {
                fontColor: this.chartjs.textColor,
                callback: function (value) {
                  // 13文字以上は省略
                  return value.length < 14 ? value : value.substring(0, 12) + '…';
                },
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                color: this.chartjs.axisLineColor,
              },
              scaleLabel: {
                display: true,
                labelString: unit,
              },
              ticks: {
                fontColor: this.chartjs.textColor,
                beginAtZero: true,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              let strategy = 'Desktop';
              if (this._data.datasets[tooltipItem[0].datasetIndex].stack === 2) {
                strategy = 'Mobile';
              }
              const value = this._data.labels[tooltipItem[0].index] + '(' + strategy + ')';
              const nlValue = value.substring(0, 70) + '\n' + value.substring(69, value.length);
              return value.length < 70 ? value : nlValue.split('\n');
            },
            label: function (tooltipItem, data) {
              // msec単位の想定のため、小数点以下切り捨て
              return this._data.datasets[tooltipItem.datasetIndex].label
                     + ':' + Math.floor(tooltipItem.yLabel) + ' ' + unit;
            },
          },
        },
      };
      this.isCompInit = true;
    }
  }

  /**
   * Chartの棒をクリック処理
   * @param event クリックイベント
   */
  clickChart(event) {
    if (event.length > 0) {
      const selectedValue = this.allSiteNameMap[event[0]._model.label];
      if (selectedValue) {
        this.searchService.setDrillDownOption(selectedValue);
        this.searchService.setDrillDownConditionStatus();
        this.router.navigate(['/pages/analysis'], { queryParams: { selectedSite: selectedValue } });
      }
    }
  }
}
