import { Component, OnInit, Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupConfigService } from '../../@core/data/group-config.service';
import { UserService } from '../../@core/data/users.service';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap'; // edited by EMK
import { WebVitalsConst } from '../../app-const'; // edited by EMK
/* Edited by EMK Start */
const I18N_VALUES = {
  ja: {
    weekdays: [ '月', '火', '水', '木', '金', '土', '日'],
    months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  },
};

@Injectable()
export class I18n {
  language = 'ja';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }
  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
/* Edited by EMK End */
@Component({
  selector: 'ngx-group-configs',
  templateUrl: './group-configs.component.html',
  styleUrls: ['./group-configs.component.scss'],
  providers: [NgbModalConfig, NgbModal, I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}],
}) // adding I18n and provide for picker
export class GroupConfigsComponent implements OnInit {
  isLoading: boolean = true;          // ページローディングフラグ
  loadingConfig: any = {};            // ローディング処理設定

  role_id;                            // ログインユーザーの権限

  plans: any = [];                    // 検索エリアのプランを格納するリスト
  selectedViewPlans = {};             // プランIDをキーとしたプランのリスト
  groupConfigs: any = [];             // グループ一覧のデータを格納するリスト
  page = 1;                           // ページャーのページ番号
  pageSize = 10;                      // ページャーが１ページに表示するデータの数

  isSubmitted = false;                // モーダルのSubmitボタン押下のフラグ
  modalMessage: string = '';          // モーダルに表示するメッセージ
  addGroupData = new FormData();      // モーダルの登録・更新時に送信するフォームデータ

  modalDisabled: boolean = false;     // モーダルのオープンの制御フラグ
  submitDisabled: boolean = false;    // モーダルの登録・更新ボタンの制御フラグ

  // グループ検索フォーム　Validation設定
  searchGroupForm = this.formBuilder.group({
    searchGroupName: [''],
    searchPlanId: [''],
    searchStartDate: [''],
    searchEndDate: [''],
  });

  // グループ登録フォーム
  addGroupForm;

  // グループ編集フォーム
  editGroupForm;

  constructor(protected configService: GroupConfigService,
              public formBuilder: FormBuilder,
              protected userService: UserService,
              config: NgbModalConfig,
              private modalService: NgbModal) {
    // モーダルの設定
    config.backdrop = 'static';
    config.keyboard = false;
    config.windowClass = 'configs-modal';
  }

  ngOnInit(): void {
    this.userService.getUsers().subscribe(user => {
      this.role_id = user.role_id;
      if (this.role_id > 3) {
        // ログインしているユーザーの権限がGuneralUser以下の場合はダッシュボードに遷移する
        location.href = '/dashboard/synthetic#/pages/scenario-summary';
      } else {
        // 検索エリアのプランのリストを取得
        this.configService.getPlans().subscribe(response => {
          this.plans = response;
          this.plans.forEach(plan => {
            this.selectedViewPlans[plan.id] = plan;
          });
        });
      }
    });

    // グループ一覧初期表示
    this.searchGroupConfigs();
  }

  /**
   * グループ一覧に表示するデータを取得する
   */
  searchGroupConfigs(): void {
    let startDate = this.searchStartDate.value;
    if (startDate) {
      if (Object.prototype.toString.call(startDate) === '[object Object]') {
        startDate = startDate.year + '-' + startDate.month + '-' + startDate.day;
      } else {
        // 日付が正しく取得できない場合はエラーを表示して検索を中断する
        return;
      }
    }

    let endDate = this.searchEndDate.value;
    if (endDate) {
      if (Object.prototype.toString.call(endDate) === '[object Object]') {
        endDate = endDate.year + '-' + endDate.month + '-' + endDate.day;
      } else {
        // 日付が正しく取得できない場合はエラーを表示して検索を中断する
        return;
      }
    }

    // ローディングのエフェクト開始
    this.isLoading = true;

    this.configService.getGroupConfigs(this.searchGroupName.value,
                                       this.searchPlanId.value,
                                       startDate,
                                       endDate)
      .subscribe(response => {
      this.groupConfigs = response;

      // ローディングのエフェクト終了
      this.isLoading = false;
    });
  }

  /**
   * グループ登録モーダル表示
   * @param addGroup
   */
  openAddGroup(addGroup): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.addGroupData = new FormData();

    // グループ登録フォーム　Validation設定
    this.addGroupForm = this.formBuilder.group({
      addGroupName: ['', [Validators.required, Validators.maxLength(50)]],
      addStreetAddress: ['', [Validators.maxLength(255)]],
      addTelNo: ['', [Validators.maxLength(20)]],
      addEmail: ['', [Validators.email, Validators.maxLength(191)]],
      addPlanId: ['', [Validators.required]],
      addStartDate: [],
      addEndDate: [],
      addRemarks: [''],
      addLCPGood: [WebVitalsConst.LCP_BORDER_GOOD],
      addLCPBad: [WebVitalsConst.LCP_BORDER_BAD],
      addCLSGood: [WebVitalsConst.CLS_BORDER_GOOD],
      addCLSBad: [WebVitalsConst.CLS_BORDER_BAD],
      addFCPGood: [WebVitalsConst.FCP_BORDER_GOOD],
      addFCPBad: [WebVitalsConst.FCP_BORDER_BAD],
      addTTFBGood: [WebVitalsConst.TTFB_BORDER_GOOD],
      addTTFBBad: [WebVitalsConst.TTFB_BORDER_BAD],
      addSIGood: [WebVitalsConst.SI_BORDER_GOOD],
      addSIBad: [WebVitalsConst.SI_BORDER_BAD],
      addTBTGood: [WebVitalsConst.TBT_BORDER_GOOD],
      addTBTBad: [WebVitalsConst.TBT_BORDER_BAD],
      addTTIGood: [WebVitalsConst.TTI_BORDER_GOOD],
      addTTIBad: [WebVitalsConst.TTI_BORDER_BAD],
      addLPSGood: [WebVitalsConst.LPS_BORDER_GOOD],
      addLPSBad: [WebVitalsConst.LPS_BORDER_BAD],
    });

    this.modalService.open(addGroup);
    this.modalDisabled = false;
  }

  /**
   * グループ編集モーダル表示
   * @param editGroup
   */
  openEditGroup(groupId, editGroup): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.addGroupData = new FormData();

    // グループ編集モーダルに必要な情報を取得する
    this.configService.getGroupConfig(groupId).subscribe(response => {
      // グループ編集フォーム　Validation設定
      let measurement_st = null;
      if (response.measurement_standards) {
        measurement_st = JSON.parse(response.measurement_standards);
      }
      this.editGroupForm = this.formBuilder.group({
        editGroupId: [response.id, [Validators.required]],
        editGroupName: [response.name, [Validators.required, Validators.maxLength(50)]],
        editStreetAddress: [response.street_address, [Validators.maxLength(255)]],
        editTelNo: [response.tel_no, [Validators.maxLength(20)]],
        editEmail: [response.email, [Validators.email, Validators.maxLength(191)]],
        editPlanId: [response.plan_id, [Validators.required]],
        editStartDate: [],
        editEndDate: [],
        editUserLimit: [response.user_limit, [Validators.required, Validators.pattern('^[0-9]+$')]],
        editMonitoringLimit: [response.monitoring_limit, [Validators.required, Validators.pattern('^[0-9]+$')]],
        editScenarioLimit: [response.scenario_limit, [Validators.required, Validators.pattern('^[0-9]+$')]],
        editSiteLimit: [response.siteurl_limit, [Validators.required, Validators.pattern('^[0-9]+$')]],
        editRemarks: [response.remarks],
        editLCPGood: [measurement_st !== null ? measurement_st.LCP_BORDER_GOOD : WebVitalsConst.LCP_BORDER_GOOD],
        editLCPBad: [measurement_st !== null ? measurement_st.LCP_BORDER_BAD : WebVitalsConst.LCP_BORDER_BAD],
        editCLSGood: [measurement_st !== null ? measurement_st.CLS_BORDER_GOOD : WebVitalsConst.CLS_BORDER_GOOD],
        editCLSBad: [measurement_st !== null ? measurement_st.CLS_BORDER_BAD : WebVitalsConst.CLS_BORDER_BAD],
        editFCPGood: [measurement_st !== null ? measurement_st.FCP_BORDER_GOOD : WebVitalsConst.FCP_BORDER_GOOD],
        editFCPBad: [measurement_st !== null ? measurement_st.FCP_BORDER_BAD : WebVitalsConst.FCP_BORDER_BAD],
        editTTFBGood: [measurement_st !== null ? measurement_st.TTFB_BORDER_GOOD : WebVitalsConst.TTFB_BORDER_GOOD],
        editTTFBBad: [measurement_st !== null ? measurement_st.TTFB_BORDER_BAD : WebVitalsConst.TTFB_BORDER_BAD],
        editSIGood: [measurement_st !== null ? measurement_st.SI_BORDER_GOOD : WebVitalsConst.SI_BORDER_GOOD],
        editSIBad: [measurement_st !== null ? measurement_st.SI_BORDER_BAD : WebVitalsConst.SI_BORDER_BAD],
        editTBTGood: [measurement_st !== null ? measurement_st.TBT_BORDER_GOOD : WebVitalsConst.TBT_BORDER_GOOD],
        editTBTBad: [measurement_st !== null ? measurement_st.TBT_BORDER_BAD : WebVitalsConst.TBT_BORDER_BAD],
        editTTIGood: [measurement_st !== null ? measurement_st.TTI_BORDER_GOOD : WebVitalsConst.TTI_BORDER_GOOD],
        editTTIBad: [measurement_st !== null ? measurement_st.TTI_BORDER_BAD : WebVitalsConst.TTI_BORDER_BAD],
        editLPSGood: [measurement_st !== null ? measurement_st.LPS_BORDER_GOOD : WebVitalsConst.LPS_BORDER_GOOD],
        editLPSBad: [measurement_st !== null ? measurement_st.LPS_BORDER_BAD : WebVitalsConst.LPS_BORDER_BAD],
      });

      if (response.start_date) {
        const date = response.start_date.split('-');
        this.editStartDate.setValue({'year': Number(date[0]), 'month': Number(date[1]), 'day': Number(date[2])});
      }

      if (response.end_date) {
        const date = response.end_date.split('-');
        this.editEndDate.setValue({'year': Number(date[0]), 'month': Number(date[1]), 'day': Number(date[2])});
      }

      this.modalService.open(editGroup);
      this.modalDisabled = false;
    });
  }

  /**
   * グループ登録モーダル　登録ボタン押下処理
   */
  onAddGroupSubmit(): boolean {
    this.submitDisabled = true;
    this.isSubmitted = true;

    // バリデーションのチェックに違反する場合は処理を中断する
    if (this.addGroupForm.invalid ||
        (this.addStartDate.value && !this.addStartDate.value.year) ||
        (this.addEndDate.value && !this.addEndDate.value.year) ||
        this.addStartDate.date > this.addEndDate.date) {
      this.modalMessage = '入力内容が正しくありません。';
      this.submitDisabled = false;
      return false;
    }

    let startDate = '';
    if (this.addStartDate.value) {
      startDate = this.addStartDate.value.year + '-' +
                  this.addStartDate.value.month + '-' +
                  this.addStartDate.value.day;
    }

    let endDate = '';
    if (this.addEndDate.value) {
      endDate = this.addEndDate.value.year + '-' +
                this.addEndDate.value.month + '-' +
                this.addEndDate.value.day;
    }

    // 入力内容をフォームデータに設定する
    this.addGroupData.append('addGroupName', this.addGroupName.value);            // グループ名
    this.addGroupData.append('addStreetAddress', this.addStreetAddress.value);    // 企業・団体の住所
    this.addGroupData.append('addTelNo', this.addTelNo.value);                    // 代表者の電話番号
    this.addGroupData.append('addEmail', this.addEmail.value);                    // 代表者のメールアドレス
    this.addGroupData.append('addPlanId', this.addPlanId.value);                  // プラン
    this.addGroupData.append('addStartDate', startDate);                          // 利用期間開始日
    this.addGroupData.append('addEndDate', endDate);                              // 利用期間終了日
    this.addGroupData.append('addRemarks', this.addRemarks.value);                // 備考
    // for measurement standard
    const measurement = { LCP_BORDER_GOOD : this.addLCPGood.value, LCP_BORDER_BAD : this.addLCPBad.value,
                        CLS_BORDER_GOOD : this.addCLSGood.value, CLS_BORDER_BAD : this.addCLSBad.value,
                        FCP_BORDER_GOOD : this.addFCPGood.value, FCP_BORDER_BAD : this.addFCPBad.value,
                        TTFB_BORDER_GOOD : this.addTTFBGood.value, TTFB_BORDER_BAD : this.addTTFBBad.value,
                        SI_BORDER_GOOD : this.addSIGood.value, SI_BORDER_BAD : this.addSIBad.value,
                        TBT_BORDER_GOOD : this.addTBTGood.value, TBT_BORDER_BAD : this.addTBTBad.value,
                        TTI_BORDER_GOOD : this.addTTIGood.value, TTI_BORDER_BAD : this.addTTIBad.value,
                        LPS_BORDER_GOOD : this.addLPSGood.value, LPS_BORDER_BAD : this.addLPSBad.value,
                      };
    this.addGroupData.append('addmeasurement', JSON.stringify(measurement));
    // フォームデータをサーバに送信する
    this.configService.addGroupConfigs(this.addGroupData).subscribe(response => {
      if (response.errors) {
        this.modalMessage = response.errors.message;
      } else {
        // グループ一覧をリロードする
        this.searchGroupConfigs();
        // モーダルを閉じる
        this.modalService.dismissAll('Submit Success');
      }
      this.submitDisabled = false;
    });
  }

  /**
   * グループ編集モーダル　更新ボタン押下処理
   */
  onEditGroupSubmit(): boolean {
    this.submitDisabled = true;
    this.isSubmitted = true;

    // バリデーションのチェックに違反する場合は処理を中断する
    if (this.editGroupForm.invalid ||
        (this.editStartDate.value && !this.editStartDate.value.year) ||
        (this.editEndDate.value && !this.editEndDate.value.year) ||
        this.editStartDate.date > this.editEndDate.date) {
      this.modalMessage = '入力内容が正しくありません。';
      this.submitDisabled = false;
      return false;
    }

    let startDate = '';
    if (this.editStartDate.value) {
      startDate = this.editStartDate.value.year + '-' +
                  this.editStartDate.value.month + '-' +
                  this.editStartDate.value.day;
    }

    let endDate = '';
    if (this.editEndDate.value) {
      endDate = this.editEndDate.value.year + '-' +
                this.editEndDate.value.month + '-' +
                this.editEndDate.value.day;
    }

    // 入力内容をフォームデータに設定する
    this.addGroupData.append('editGroupId', this.editGroupId.value);                  // グループID
    this.addGroupData.append('editGroupName', this.editGroupName.value);              // グループ名
    this.addGroupData.append('editStreetAddress', this.editStreetAddress.value);      // 企業・団体の住所
    this.addGroupData.append('editTelNo', this.editTelNo.value);                      // 代表者の電話番号
    this.addGroupData.append('editEmail', this.editEmail.value);                      // 代表者のメールアドレス
    this.addGroupData.append('editPlanId', this.editPlanId.value);                    // プラン
    this.addGroupData.append('editStartDate', startDate);                             // 利用期間開始日
    this.addGroupData.append('editEndDate', endDate);                                 // 利用期間終了日
    this.addGroupData.append('editRemarks', this.editRemarks.value);                  // 備考
    this.addGroupData.append('editUserLimit', this.editUserLimit.value);              // ユーザー制限数
    this.addGroupData.append('editMonitoringLimit', this.editMonitoringLimit.value);  // 監視対象制限数
    this.addGroupData.append('editScenarioLimit', this.editScenarioLimit.value);      // シナリオ制限数
    this.addGroupData.append('editSiteLimit', this.editSiteLimit.value);              // サイトURL制限数
    // edit measurement stanard
    const measurement = { LCP_BORDER_GOOD : this.editLCPGood.value, LCP_BORDER_BAD : this.editLCPBad.value,
                        CLS_BORDER_GOOD : this.editCLSGood.value, CLS_BORDER_BAD : this.editCLSBad.value,
                        FCP_BORDER_GOOD : this.editFCPGood.value, FCP_BORDER_BAD : this.editFCPBad.value,
                        TTFB_BORDER_GOOD : this.editTTFBGood.value, TTFB_BORDER_BAD : this.editTTFBBad.value,
                        SI_BORDER_GOOD : this.editSIGood.value, SI_BORDER_BAD : this.editSIBad.value,
                        TBT_BORDER_GOOD : this.editTBTGood.value, TBT_BORDER_BAD : this.editTBTBad.value,
                        TTI_BORDER_GOOD : this.editTTIGood.value, TTI_BORDER_BAD : this.editTTIBad.value,
                        LPS_BORDER_GOOD : this.editLPSGood.value, LPS_BORDER_BAD : this.editLPSBad.value,
    };
    this.addGroupData.append('editmeasurement', JSON.stringify(measurement));
    // フォームデータをサーバに送信する
    this.configService.editGroupConfigs(this.addGroupData).subscribe(response => {
      if (response.errors) {
        this.modalMessage = response.errors.message;
      } else {
        // グループ一覧をリロードする
        this.searchGroupConfigs();
        // モーダルを閉じる
        this.modalService.dismissAll('Submit Success');
      }
      this.submitDisabled = false;
    });

    this.isSubmitted = false;
  }

  /**
   * グループ編集モーダル　プラン変更処理
   */
  onChangeEditPlanId(): void {
    // グループの制限数の項目に選択したプランの制限数を設定する
    const plan = this.selectedViewPlans[this.editPlanId.value];
    this.editUserLimit.setValue(plan.user_limit);               // ユーザー制限数
    this.editMonitoringLimit.setValue(plan.monitoring_limit);   // 監視対象制限数
    this.editScenarioLimit.setValue(plan.scenario_limit);       // シナリオ制限数
  }

  /**
   * グループ編集モーダル　削除ボタン押下処理
   */
  deleteGroup(): void {
    this.submitDisabled = true;
    if (window.confirm('このグループを削除しますか？')) {
      this.configService.deleteGroup(this.editGroupId.value).subscribe(response => {
        if (response.errors) {
          this.modalMessage = response.errors.message;
        } else {
          // グループ一覧をリロードする
          this.searchGroupConfigs();
          // モーダルを閉じる
          this.modalService.dismissAll('Submit Success');
          this.submitDisabled = false;
        }
      });
    } else {
      this.submitDisabled = false;
    }
  }

  /**
   * グループ一覧　所属設定ボタン押下処理
   */
  goGroupUserConfig(groupId): void {
    // グループ所属設定画面へ遷移
    this.configService.groupId = groupId;
    location.href = '/dashboard/synthetic#/pages/user-configs';
  }

  /**
   * getter 検索エリア
   */
  // グループ名
  get searchGroupName(){
    return this.searchGroupForm.get('searchGroupName');
  }

  // プラン
  get searchPlanId(){
    return this.searchGroupForm.get('searchPlanId');
  }

  // 利用期間開始日
  get searchStartDate(){
    return this.searchGroupForm.get('searchStartDate');
  }

  // 利用期間終了日
  get searchEndDate(){
    return this.searchGroupForm.get('searchEndDate');
  }

  /**
   * getter グループ登録フォーム
   */
  // グループ名
  get addGroupName(){
    return this.addGroupForm.get('addGroupName');
  }

  // 企業・団体の住所
  get addStreetAddress(){
    return this.addGroupForm.get('addStreetAddress');
  }

  // 代表者の電話番号
  get addTelNo(){
    return this.addGroupForm.get('addTelNo');
  }

  // 代表者のメールアドレス
  get addEmail(){
    return this.addGroupForm.get('addEmail');
  }

  // プラン
  get addPlanId(){
    return this.addGroupForm.get('addPlanId');
  }

  // 利用期間開始日
  get addStartDate(){
    const addStartDate = this.addGroupForm.get('addStartDate');
    if (addStartDate.value) {
      if (addStartDate.value.year) {
        addStartDate.date = new Date(addStartDate.value.year, addStartDate.value.month, addStartDate.value.day);
      }
    }
    return addStartDate;
  }

  // 利用期間終了日
  get addEndDate(){
    const addEndDate = this.addGroupForm.get('addEndDate');
    if (addEndDate.value) {
      if (addEndDate.value.year) {
        addEndDate.date = new Date(addEndDate.value.year, addEndDate.value.month, addEndDate.value.day);
      }
    }
    return addEndDate;
  }

  get addLCPGood(){
    return this.addGroupForm.get('addLCPGood');
  }

  get addLCPBad(){
    return this.addGroupForm.get('addLCPBad');
  }

  get addCLSGood(){
    return this.addGroupForm.get('addCLSGood');
  }

  get addCLSBad(){
    return this.addGroupForm.get('addCLSBad');
  }

  get addFCPGood(){
    return this.addGroupForm.get('addFCPGood');
  }

  get addFCPBad(){
    return this.addGroupForm.get('addFCPBad');
  }

  get addTTFBGood(){
    return this.addGroupForm.get('addTTFBGood');
  }

  get addTTFBBad(){
    return this.addGroupForm.get('addTTFBBad');
  }

  get addSIGood(){
    return this.addGroupForm.get('addSIGood');
  }

  get addSIBad(){
    return this.addGroupForm.get('addSIBad');
  }

  get addTBTGood(){
    return this.addGroupForm.get('addTBTGood');
  }

  get addTBTBad(){
    return this.addGroupForm.get('addTBTBad');
  }

  get addTTIGood(){
    return this.addGroupForm.get('addTTIGood');
  }

  get addTTIBad(){
    return this.addGroupForm.get('addTTIBad');
  }

  get addLPSGood(){
    return this.addGroupForm.get('addLPSGood');
  }

  get addLPSBad(){
    return this.addGroupForm.get('addLPSBad');
  }

  // 備考
  get addRemarks(){
    return this.addGroupForm.get('addRemarks');
  }

  /**
   * getter グループ編集フォーム
   */
  // グループID
  get editGroupId(){
    return this.editGroupForm.get('editGroupId');
  }

  // グループ名
  get editGroupName(){
    return this.editGroupForm.get('editGroupName');
  }

  // 企業・団体の住所
  get editStreetAddress(){
    return this.editGroupForm.get('editStreetAddress');
  }

  // 代表者の電話番号
  get editTelNo(){
    return this.editGroupForm.get('editTelNo');
  }

  // 代表者のメールアドレス
  get editEmail(){
    return this.editGroupForm.get('editEmail');
  }

  // プラン
  get editPlanId(){
    return this.editGroupForm.get('editPlanId');
  }

  // 利用期間開始日
  get editStartDate(){
    const editStartDate = this.editGroupForm.get('editStartDate');
    if (editStartDate.value) {
      if (editStartDate.value.year) {
        editStartDate.date = new Date(editStartDate.value.year, editStartDate.value.month, editStartDate.value.day);
      }
    }
    return editStartDate;
  }

  // 利用期間終了日
  get editEndDate(){
    const editEndDate = this.editGroupForm.get('editEndDate');
    if (editEndDate.value) {
      if (editEndDate.value.year) {
        editEndDate.date = new Date(editEndDate.value.year, editEndDate.value.month, editEndDate.value.day);
      }
    }
    return editEndDate;
  }

  // ユーザー登録制限
  get editUserLimit(){
    return this.editGroupForm.get('editUserLimit');
  }

  // 監視対象登録制限
  get editMonitoringLimit(){
    return this.editGroupForm.get('editMonitoringLimit');
  }

  // シナリオ登録制限
  get editScenarioLimit(){
    return this.editGroupForm.get('editScenarioLimit');
  }

  // サイトURL制限数
  get editSiteLimit(){
    return this.editGroupForm.get('editSiteLimit');
  }

  get editLCPGood(){
    return this.editGroupForm.get('editLCPGood');
  }

  get editLCPBad(){
    return this.editGroupForm.get('editLCPBad');
  }

  get editCLSGood(){
    return this.editGroupForm.get('editCLSGood');
  }

  get editCLSBad(){
    return this.editGroupForm.get('editCLSBad');
  }

  get editFCPGood(){
    return this.editGroupForm.get('editFCPGood');
  }

  get editFCPBad(){
    return this.editGroupForm.get('editFCPBad');
  }

  get editTTFBGood(){
    return this.editGroupForm.get('editTTFBGood');
  }

  get editTTFBBad(){
    return this.editGroupForm.get('editTTFBBad');
  }

  get editSIGood(){
    return this.editGroupForm.get('editSIGood');
  }

  get editSIBad(){
    return this.editGroupForm.get('editSIBad');
  }

  get editTBTGood(){
    return this.editGroupForm.get('editTBTGood');
  }

  get editTBTBad(){
    return this.editGroupForm.get('editTBTBad');
  }

  get editTTIGood(){
    return this.editGroupForm.get('editTTIGood');
  }

  get editTTIBad(){
    return this.editGroupForm.get('editTTIBad');
  }

  get editLPSGood(){
    return this.editGroupForm.get('editLPSGood');
  }

  get editLPSBad(){
    return this.editGroupForm.get('editLPSBad');
  }

  // 備考
  get editRemarks(){
    return this.editGroupForm.get('editRemarks');
  }
}
