import { Component } from '@angular/core';

/**
 * ページコンポネント
 */
@Component({
  selector: 'ngx-scenario-summary',
  template: `
      <router-outlet></router-outlet>
  `,
})
export class ScenarioSummaryComponent {

}
