import { Component, Input, OnInit } from '@angular/core';
import { Report } from 'app/models/scenario.model';

interface Audit {
  id: string;
  details: {
    items: NetworkRequestItem[];
  };
}

interface NetworkRequestItem {
  resourceType: string;
  url: string;
  priority: string;
  startTime: number;
  endTime : number;
  duration: number;
  transferSize: number;
  timings?: {
    dns?: number;
    tcp?: number;
    ssl?: number;
    ttfb?: number;
    download?: number;
  };
  phases?: Phase[]; // Add this line
}

interface Phase {
  name: string;
  duration: number;
  color: string;
  startTime: number;
  endTime: number;
}

@Component({
  selector: 'ngx-waterfall-chart-info',
  templateUrl: './waterfall-chart-info.component.html',
  styleUrls: ['./waterfall-chart-info.component.scss'],
})

export class WaterfallChartInfoComponent implements OnInit {

  @Input() strategyJp: string;
  @Input() reports: Array<Report>;
  pageReport: Array<NetworkRequestItem> = [];

  ngOnInit() {
    if (this.reports && this.reports.length > 0) {
      this.reports.forEach(report => {
        const reportObj = JSON.parse(report.pageReports);
        const audits = Object.values(reportObj.audits) as Audit[];

        const networkRequestsAudit = audits.find(audit => audit.id === 'network-requests');
        if (networkRequestsAudit && networkRequestsAudit.details && networkRequestsAudit.details.items) {
          const filteredData = networkRequestsAudit.details.items.map((item: NetworkRequestItem) => ({
            resourceType: item.resourceType,
            url: item.url,
            priority: item.priority,
            startTime: item.startTime,
            endTime: item.endTime,
            duration: item.endTime - item.startTime || 0,
            transferSize: item.transferSize / 1024,
            timings: item.timings, // Ensure timings are copied
            phases: this.generatePhases(item)  // Generate phases for timeline
          })) as NetworkRequestItem[]; // Use type assertion here
          this.pageReport = this.pageReport.concat(filteredData);
        }
        
      });
    }
  }

  generatePhases(item: NetworkRequestItem): Phase[] {
    const totalDuration = item.endTime - item.startTime;
    const dnsDuration = totalDuration * 0.12; // Assume 10% for DNS
    const tcpDuration = totalDuration * 0.16; // Assume 10% for TCP
    const sslDuration = totalDuration * 0.14; // Assume 10% for SSL
    const ttfbDuration = totalDuration * 0.3; // Assume 40% for TTFB
    const downloadDuration = totalDuration * 0.28; // Assume 30% for Download

    const startTime = item.startTime;
    const endTime = startTime + dnsDuration;

    return [
      { name: 'DNS', duration: dnsDuration, color: '#008000', startTime , endTime},
      { name: 'TCP', duration: tcpDuration, color: '#0000FF', startTime: startTime + dnsDuration, endTime: endTime + tcpDuration},
      { name: 'SSL', duration: sslDuration, color: '#800080', startTime: startTime + dnsDuration + tcpDuration, endTime: endTime + tcpDuration + sslDuration },
      { name: 'TTFB', duration: ttfbDuration, color: '#FFC0CB', startTime: startTime + dnsDuration + tcpDuration + sslDuration, endTime: endTime + tcpDuration + sslDuration + ttfbDuration },
      { name: 'Download', duration: downloadDuration, color: '#FFA500', startTime: startTime + dnsDuration + tcpDuration + sslDuration + ttfbDuration, endTime: startTime + dnsDuration + tcpDuration + sslDuration + ttfbDuration + downloadDuration }
    ];
  }

  generateGraphOption(item: Phase[]): any {
    // console.log(item);
    const seriesData = item.map(phase => ({
      name: phase.name,
      value: [phase.startTime + phase.duration],
      itemStyle: {
        color: phase.color
      }
    }));

    return {
      tooltip: {
        transitionDuration: 0,
        padding: [5, 7],
        textStyle: {
          color: '#000000'
      },
        backgroundColor:'#ffffff',
        extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);",
        formatter: (params: any) => {
            return this.Formatter(
              params,
              item
            );
        },
      },
      grid: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      barWidth: "1000",
      legend: {},
      xAxis: {
        show: false,
        type: 'value',
        boundaryGap: false,
      },
      color: seriesData.map((e) => e.itemStyle.color),
      yAxis: {
        show: false,
        type: 'category',
      },
      series: seriesData.map((e) => ({
        name: e.name,
        type: "bar",
        stack: "total",
        label: { show: true },
        emphasis: { focus: "series" },
        data: e.value,
        animation:false
      })),
    };
  }

  //ページのさいページのさいサイズを　計て　MB とか　KB とか　出力する。
  Formatter(
params: any, item: Phase[]  ) {
    let text = `
    <style>
    .tooltip-table {
    }
    .tooltip-table th, .tooltip-table td {
      padding: 2px;
    }
    .tooltip-table th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #f2f2f2;
      color: black;
    }
    .color-box {
      width: 12px;
      height: 12px;
      display: inline-block;
    }
  </style>
    <table  class="tooltip-table">
    <thead>
      <tr>
        <th>(ms)</th>
        <th>間隔</th>
        <th>開始時間</th>
        <th>終了時間</th>
      </tr>
    </thead>
    <tbody>`;

    item.forEach(function (phase) {
      text +=  `
        <tr>
           <td><span class="color-box" style="background-color:${phase.color};"></span> ${phase.name}</td>&nbsp;  
          <td>${phase.duration.toFixed(2) }</td>&nbsp;
          <td>${phase.startTime.toFixed(2) }</td>&nbsp;
          <td>${phase.endTime.toFixed(2) }</td>
        </tr>`;
    }); 
    return text +`</tbody> </table>`;
  }
}
