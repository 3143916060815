export class ScenarioSummary {
  scenarioConfigId: number;
  scenarioName: string;
  siteName: string;
  category: string;
  strategies: Array<Scenario>;
  warningMetrics: WarningMetricsForCallouts;
  lastMeasureDateTime: { [key: string]: string };

  constructor(
    scenarioConfigId: number,
    scenarioName: string,
    siteName: string,
    category: string,
    strategies: Array<Scenario>,
    warningMetrics?: WarningMetricsForCallouts,
    lastMeasureDateTime?: { [key: string]: string },
  ) {
    this.scenarioConfigId = scenarioConfigId;
    this.scenarioName = scenarioName;
    this.siteName = siteName;
    this.strategies = strategies;
    this.category = category;
    this.warningMetrics = warningMetrics;
    this.lastMeasureDateTime = lastMeasureDateTime;
  }
}

export class Scenario {
  timestamp: Date;
  strategy: string;
  strategyJp: string;
  pageTransitions: Array<Page>;
  lighthouseReports: Array<Report>;
  pageContents: Array<Content>;
  scenarioSteps: Array<Step>;
  totalTime: number;

  constructor(
    timestamp?: Date,
    strategy?: string,
    pageTransitions?: Array<Page>,
    lighthouseReports?: Array<Report>,
    pageContents?: Array<Content>,
    scenarioSteps?: Array<Step>,
  ) {
    this.timestamp = timestamp;
    this.strategy = strategy;
    this.pageTransitions = pageTransitions;
    this.lighthouseReports = lighthouseReports;
    this.pageContents = pageContents;
    this.scenarioSteps = scenarioSteps;
    if (Array.isArray(scenarioSteps)) {
      this.totalTime = scenarioSteps[scenarioSteps.length - 1].elapsedTime;
    }
    if (strategy === 'desktop') {
      this.strategyJp = 'デスクトップ';
    } else if (strategy === 'mobile') {
      this.strategyJp = 'モバイル';
    } else {
      this.strategyJp = strategy;
    }
  }
}

export class Page {
  pageId: number;
  pageUrl: string;
  pageName: string;
  elapsedTime: number;
  screenshotUrl?: string;
  metrics?: Object;
  timestampEpochMillis: number;
  reports?: Object;

  constructor(
    pageId?: number,
    pageUrl?: string,
    pageName?: string,
    elapsedTime?: number,
    screenshotUrl?: string,
    metrics?: Object,
    timestampEpochMillis?: number,
    reports?: Object,
  ) {
    this.pageId = pageId;
    this.pageUrl = pageUrl;
    this.pageName = pageName;
    this.elapsedTime = elapsedTime;
    this.screenshotUrl = screenshotUrl;
    this.metrics = metrics;
    this.timestampEpochMillis = timestampEpochMillis;
    this.reports = reports;
  }
}

export class Report {
  pageId: number;
  pageName: string;
  pageReports?: string;
  elapsedTime: number;
  timestampEpochMillis: number;

  constructor(
    pageId?: number,
    pageName?: string,
    pageReports?: string,
    elapsedTime?: number,
    timestampEpochMillis?: number,
  ) {
    this.pageId = pageId;
    this.pageName = pageName;
    this.pageReports = pageReports;
    this.elapsedTime = elapsedTime;
    this.timestampEpochMillis = timestampEpochMillis;
  }
}

export class Content {
  pageId: number;
  pageUrl: string;
  pageName: string;
  elapsedTime: number;
  contents?: Object;
  timestampEpochMillis: number;
  constructor(
    pageId?: number,
    pageUrl?: string,
    pageName?: string,
    elapsedTime?: number,
    contents?: Object,
    timestampEpochMillis?: number,
  ) {
    this.pageId = pageId;
    this.pageUrl = pageUrl;
    this.pageName = pageName;
    this.elapsedTime = elapsedTime;
    this.contents = contents;
    this.timestampEpochMillis = timestampEpochMillis;
  }
}

export class Step {
  stepId: number;
  stepCode: string;
  elapsedTime: number;
  screenshotUrl: string;

  constructor(
    stepId?: number,
    stepCode?: string,
    elapsedTime?: number,
    screenshotUrl?: string,
  ) {
    this.stepId = stepId;
    this.stepCode = stepCode;
    this.elapsedTime = elapsedTime;
    this.screenshotUrl = screenshotUrl;
  }
}

export class TimelineMetric {
  metricName: string;
  devices: Array<TimelineDevice>;

  constructor(
    metricName: string,
    devices: Array<TimelineDevice>,
  ) {
    if (metricName === 'elapsedTime') {
      this.metricName = '経過時間';
    } else {
      this.metricName = metricName;
    }
    this.devices = devices;
  }
}

export class TimelineDevice {
  deviceJPName: string;
  pages: Array<TimelinePage>;

  constructor(
    deviceJPName: string,
    pages: Array<TimelinePage>,
  ) {
    if (deviceJPName === 'desktop') {
      this.deviceJPName = 'デスクトップ';
    } else if (deviceJPName === 'mobile') {
      this.deviceJPName = 'モバイル';
    } else {
      this.deviceJPName = deviceJPName;
    }
    this.pages = pages;
  }
}

export class TimelinePage {
  pageName: string;
  metrics: Array<TimelineValue>;

  constructor(
    pageName: string,
    metrics: Array<TimelineValue>,
  ) {
    this.pageName = pageName;
    this.metrics = metrics;
  }
}

export class TimelineValue {
  date: string;
  value: number;

  constructor(
    date: string,
    value: number,
  ) {
    this.date = date;
    this.value = value;
  }
}

// 指標の基準値を持つクラス
export class MetricStandardScore {
  name: string;
  goodVal: number;
  badVal: number;

  constructor(
    name: string,
    goodVal: number,
    badVal: number,
  ) {
    this.name = name;
    this.goodVal = goodVal;
    this.badVal = badVal;
  }
}

/*
シナリオ概要に表示させる吹き出しに
警告メッセージを表示させるための測定値、判断用真偽値を保持するためのクラス
*/
export class WarningMetricsForCallouts {
  badPageCounter: {[key: string]: number};    // 例　{['mobile']: 3}
  noGoodAndNoBadPageCounter: {[key: string]: number}; // 例　同上

  badPageCounterByWebVital: {[key: string]: {[key: string]: number}};  // 例　{['desktop']: ['CLS']: 2}
  noGoodAndNoBadPageCounterByWebVital: {[key: string]: {[key: string]: number}}; // 例　同上

  isMeasurementSubjectDesktop: boolean;
  isMeasurementSubjectMobile: boolean;

  badPage: {[key: string]: {[key: string]: Array<number>}}; // 例 {['mobile']: {[ttfb]: [全ページのメトリクスの配列]}}
  noGoodAndNoBadPage: {[key: string]: {[key: string]: Array<number>}};

  constructor() {
    this.badPageCounter = {'mobile': 0, 'desktop': 0};
    this.noGoodAndNoBadPageCounter = { 'mobile': 0, 'desktop': 0 };
    this.badPage = {
      'mobile': { 'CLS': [], 'FCP': [], 'LCP': [], 'TTFB': [], 'SI': [], 'TBT': [], 'CTS': [], 'CBS': [], 'PRN': [], 'LPS': [] },
       'desktop': {'CLS': [], 'FCP': [], 'LCP': [], 'TTFB': [], 'SI': [], 'TBT': [], 'CTS': [], 'CBS': [], 'PRN': [], 'LPS': [] },
     };
    this.noGoodAndNoBadPage = {
       'mobile': {'CLS': [], 'FCP': [], 'LCP': [], 'TTFB': [], 'SI': [], 'TBT': [], 'CTS': [], 'CBS': [], 'PRN': [], 'LPS': [] },
       'desktop': {'CLS': [], 'FCP': [], 'LCP': [], 'TTFB': [], 'SI': [], 'TBT': [], 'CTS': [], 'CBS': [], 'PRN': [], 'LPS': [] },
     };

    this.badPageCounterByWebVital = {
       'mobile': {'CLS': 0, 'FCP': 0, 'LCP': 0, 'TTFB': 0, 'SI' : 0, 'TBT' : 0, 'CTS': 0, 'CBS': 0, 'PRN': 0, 'LPS': 0 },
       'desktop': {'CLS': 0, 'FCP': 0, 'LCP': 0, 'TTFB': 0, 'SI' : 0, 'TBT' : 0, 'CTS': 0, 'CBS': 0, 'PRN': 0, 'LPS': 0 },
     };

    this.noGoodAndNoBadPageCounterByWebVital = {
       'mobile': {'CLS': 0, 'FCP': 0, 'LCP': 0, 'TTFB': 0, 'SI' : 0, 'TBT' : 0, 'CTS': 0, 'CBS': 0, 'PRN': 0, 'LPS': 0 },
       'desktop': {'CLS': 0, 'FCP': 0, 'LCP': 0, 'TTFB': 0, 'SI' : 0, 'TBT' : 0, 'CTS': 0, 'CBS': 0, 'PRN': 0, 'LPS': 0 },
     };
  }

}
