import { Component, Input, OnInit } from '@angular/core';
import { ScenarioSummary } from 'app/models/scenario.model';
import { ScenarioDetailService } from 'app/@core/data/scenario-detail.service';

@Component({
  selector: 'ngx-waterfall-chart',
  templateUrl: './waterfall-chart.component.html',
  styleUrls: ['./waterfall-chart.component.scss'],
})

export class WaterfallChartComponent implements OnInit {

  @Input() scenarioSummaries: ScenarioSummary;

  // 選択中のデバイスフィルター
  selectedDevices: Array<string> = [];

  constructor(
    private scenarioDetailService: ScenarioDetailService,
  ) { }

  ngOnInit() {
    this.scenarioDetailService.getSelectedDevices().subscribe(data => {
      this.selectedDevices = data;
    });
  }

  get devices() {
    return this.scenarioDetailService.getAllDevices();
  }
}
