import { Component } from '@angular/core';
import { MENU_ITEMS } from './pages-menu';
import { UserService } from '../@core/data/users.service';

import * as Chart from 'chart.js';

/**
 * ページコンポネント
 */
@Component({
  selector: 'ngx-pages',
  template: `
    <ngx-app-layout>
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-app-layout>
  `,
})
export class PagesComponent {

  // サイドメニューに表示するメニューのリスト
  menu: Array<{[key: string]: any}> = [];

  constructor(private userService: UserService) {
    // ログインユーザーの権限から表示するメニューを取得する
    this.userService.getUsers().subscribe(response => {
      const user = response;
      this.menu = MENU_ITEMS.filter(function(item){
        if (item.roleRestriction >= user.role_id || !item.roleRestriction) return true;
      });
    });

    // グラフのフォント・スタイル設定
    Chart.defaults.global.defaultFontFamily = 'Courier New';
    Chart.defaults.global.defaultFontStyle = 'Bold';
  }
}
