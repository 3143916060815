import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { ChartComponent } from 'angular2-chartjs';
import 'chartjs-plugin-annotation';
import { WebVitalsConst } from 'app/app-const';

@Component({
  selector: 'ngx-timeline-chart',
  templateUrl: './timeline-chart.component.html',
  styleUrls: ['./timeline-chart.component.scss'],
})
export class TimelineChartComponent implements OnInit {

  @Input() device: any;
  @Input() timelineData: any;

  @Input() measurementStandard: any;

  // Chartコンポネントオオブジェクト
  @ViewChild(ChartComponent, { static: false }) chart: ChartComponent;

  // 時系列推移グラフ用の設定オブジェクト
  options: Object;

  // 時系列推移グラフ用のデータオブジェクト
  data: Object;

  /** グラフの線、レブル等用色 */
  colors: any;

  /** テーマ用Subscribeオブジェクト */
  themeSubscription: any;

  constructor(
    private theme: NbThemeService,
  ) { }

  ngOnInit() {
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      this.colors = config.variables.temperature;
      this.options = this.setChartOptions();
      this.data = {
        datasets: this.getDatasets(),
      };
    });
  }

  setChartOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
        text: this.device.deviceJPName,
        fontSize: 18,
      },
      scales: {
        xAxes: [{
          id: 'x-axis',
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'M/D ah',
            },
          },
          distribution: 'series',
          // distribution: 'liniar',
        }],
        yAxes: [{
          id: 'y-axis',
          ticks: this.setScalesYAxes(),
        }],
      },
      legend: {
        display: true,
        align: 'end',
      },
      annotation: {
        annotations: this.setChartAnnotationGood(),
      },
    };
  }
  setScalesYAxes() {
    let ScalesYAxes: number = 0;
    // else if (this.timelineData.metricName === 'FID') {
    //   ScalesYAxes = WebVitalsConst.FID_BORDER_BAD * 3;
    // }//FIDを削除(RS-890)
    if (this.timelineData.metricName === 'LPS') {
      // ScalesYAxes = WebVitalsConst.LPS_BORDER_BAD * 3;
      ScalesYAxes = 100;
    } else if (this.timelineData.metricName === 'LCP') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.LCP_BORDER_BAD : WebVitalsConst.LCP_BORDER_BAD) * 3;
    } else if (this.timelineData.metricName === 'CLS') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.CLS_BORDER_BAD : WebVitalsConst.CLS_BORDER_BAD) * 3;
    } else if (this.timelineData.metricName === 'FCP') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.FCP_BORDER_BAD : WebVitalsConst.FCP_BORDER_BAD) * 3;
    } else if (this.timelineData.metricName === 'TTFB') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.TTFB_BORDER_BAD : WebVitalsConst.TTFB_BORDER_BAD) * 3;
    } else if (this.timelineData.metricName === 'SI') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.SI_BORDER_BAD : WebVitalsConst.SI_BORDER_BAD) * 3;
    } else if (this.timelineData.metricName === 'TBT') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.TBT_BORDER_BAD : WebVitalsConst.TBT_BORDER_BAD) * 3;
    } else if (this.timelineData.metricName === 'TTI') {
      ScalesYAxes = (this.measurementStandard !== null ? this.measurementStandard.TTI_BORDER_BAD : WebVitalsConst.TTI_BORDER_BAD) * 3;
    }
    return {
      min: 0,
      suggestedMax: ScalesYAxes,
    };
  }
  setChartAnnotationGood(): any {
    let borderGood: number = 0;
    let borderBad: number = 0;
    // FIDを削除(RS-890)
    // else if (this.timelineData.metricName === 'FID') {
    //   borderGood = WebVitalsConst.FID_BORDER_GOOD;
    //   borderBad = WebVitalsConst.FID_BORDER_BAD;
    // }
    if (this.timelineData.metricName === 'LPS') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.LPS_BORDER_GOOD : WebVitalsConst.LPS_BORDER_GOOD);
      // borderGood = 100;
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.LPS_BORDER_BAD : WebVitalsConst.LPS_BORDER_BAD);
    } else if (this.timelineData.metricName === 'LCP') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.LCP_BORDER_GOOD : WebVitalsConst.LCP_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.LCP_BORDER_BAD : WebVitalsConst.LCP_BORDER_BAD);
    } else if (this.timelineData.metricName === 'CLS') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.CLS_BORDER_GOOD : WebVitalsConst.CLS_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.CLS_BORDER_BAD : WebVitalsConst.CLS_BORDER_BAD);
    } else if (this.timelineData.metricName === 'FCP') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.FCP_BORDER_GOOD : WebVitalsConst.FCP_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.FCP_BORDER_BAD : WebVitalsConst.FCP_BORDER_BAD);
    } else if (this.timelineData.metricName === 'TTFB') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.TTFB_BORDER_GOOD : WebVitalsConst.TTFB_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.TTFB_BORDER_BAD : WebVitalsConst.TTFB_BORDER_BAD);
    } else if (this.timelineData.metricName === 'SI') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.SI_BORDER_GOOD : WebVitalsConst.SI_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.SI_BORDER_BAD : WebVitalsConst.SI_BORDER_BAD);
    } else if (this.timelineData.metricName === 'TBT') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.TBT_BORDER_GOOD : WebVitalsConst.TBT_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.TBT_BORDER_BAD : WebVitalsConst.TBT_BORDER_BAD);
    } else if (this.timelineData.metricName === 'TTI') {
      borderGood = (this.measurementStandard !== null ? this.measurementStandard.TTI_BORDER_GOOD : WebVitalsConst.TTI_BORDER_GOOD);
      borderBad = (this.measurementStandard !== null ? this.measurementStandard.TTI_BORDER_BAD : WebVitalsConst.TTI_BORDER_BAD);
    }

    if (!borderGood && !borderBad) {
      return [];
    }
    if (this.timelineData.metricName === 'LPS') {
      return [
        {
          type: 'box',
          id: 'y-axis-good',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMax: 100,
          yMin: borderGood,
          backgroundColor: 'rgba(0, 255, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(255, 0, 0, 0)',
        },
        {
          type: 'box',
          id: 'y-axis-safe',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: borderBad,
          yMax: borderGood,
          backgroundColor: 'rgba(255, 192, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(255, 192, 0, 0)',
        },
        {
          type: 'box',
          id: 'y-axis-bad',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: 0,
          yMax: borderBad,
          backgroundColor: 'rgba(255, 0, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(0, 176, 80, 0)',
        },
      ];
    } else {
      return [
        {
          type: 'box',
          id: 'y-axis-good',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: 0,
          yMax: borderGood,
          backgroundColor: 'rgba(0, 176, 80, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(0, 176, 80, 0)',
        },
        {
          type: 'box',
          id: 'y-axis-safe',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: borderGood,
          yMax: borderBad,
          backgroundColor: 'rgba(255, 192, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(255, 192, 0, 0)',
        },
        {
          type: 'box',
          id: 'y-axis-bad',
          xScaleID: 'x-axis',
          yScaleID: 'y-axis',
          yMin: borderBad,
          backgroundColor: 'rgba(255, 0, 0, 0.04)',
          borderWidth: 0,
          borderColor: 'rgba(255, 0, 0, 0)',
        },
      ];
    }
  }

  getDatasets() {
    return this.device.pages.map((timelinePage, pageIdx) => {
      const metricData = timelinePage.metrics.map(metric => {
        if (this.timelineData.metricName === 'CTS' || this.timelineData.metricName === 'CBS')
          return { x: metric.date, y: metric.value * WebVitalsConst.BYTE_TO_MB };
        else
          return { x: metric.date, y: metric.value };
      });
      return { label: timelinePage.pageName, data: metricData, borderColor: this.colors[pageIdx], fill: false };
    });
  }

}
