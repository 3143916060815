import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NbThemeService, NbColorHelper } from '@nebular/theme';
import { SearchService } from '../../../@core/data/search.service';
import { ElasticsearchService } from '../../../@core/data/elasticsearch.service';
import { LoadingService } from '../../../@core/data/loading.service';
import * as moment from 'moment';
import scenarioStepJson from 'assets/query/pages/charts/scenario_step.json';

/**
 * 下記グラフのレンダリングを行うコンポネント
 * - シナリオステップ毎の速度 時系列推移
 */
@Component({
  selector: 'ngx-chartjs-scenario-line',
  template: `
    <chart type="line" [hidden]="!isShow" *ngIf="isCompInit && !isNoData" [data]="data" [options]="options"></chart>
    <div *ngIf="isNoData"> 計測結果は0件です。条件を変更し再検索してください。 </div>
  `,
})
export class ChartjsLineScenarioComponent implements OnDestroy, OnInit {
  /** グラフタイプ(HTML入力) */
  @Input() type: string = '';
  /** グラフカテゴリ(HTML入力) */
  @Input() category: string = '';
  /** グラフ値の単位(HTML入力) */
  @Input() unit: string = '';
  /** グラフ値の丸める数値(HTML入力) */
  @Input() dpoint: number = 0;
  /** コンポネント初期化フラフ */
  isCompInit: boolean = false;
  /** グラフ表示用データ */
  data: any;
  /** グラフ設定 */
  options: any;
  /** 検索用Subscribeオブジェクト */
  searchSubscription: any;
  /** テーマ用Subscribeオブジェクト */
  themeSubscription: any;
  /** Elasticsearch用Subscribeオブジェクト */
  elasticSubscription: any;
  /** 検索条件 */
  condition: any = {};
  /** グラフのx-axisレブル */
  labelStr: any;
  /** グラフの線、レブル等用色 */
  colors: any;
  /** ChartJS用色 */
  chartjs: any;
  /** グラフのy-axis最大値 */
  axisMax: any;
  /** グラフの表示フラグ */
  isShow: boolean = false;
  /** 計測データの有無 */
  isNoData: boolean = false;

  constructor(
    private theme: NbThemeService,
    protected searchService: SearchService,
    protected elasticsearchService: ElasticsearchService,
    protected loadingService: LoadingService,
  ) { }

  /**
   * コンポネント初期処理
   */
  ngOnInit(): void {
    let init = false;
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      this.condition = this.searchService.getCondition();
      this.colors = config.variables.temperature;
      this.chartjs = config.variables.chartjs;
      this.axisMax = 0;

      if (init) {
        return;
      }
      init = true;

      // 検索条件が変わった際のイベントを登録
      this.searchSubscription = this.searchService.getConditionState().subscribe(res => {
        this.stopElasticRequest();
        this.isShow = false;
        // 条件の取得
        this.condition = this.searchService.getCondition();
        // ここで検索条件更新後の処理、メソッド呼び出しを書く
        this.search_draw(this.condition);
      });

      // ヘッダ初期化されていなければ初期化を待つ。
      const allScenarioTypeList = this.searchService.getAllScenarioNameList();
      if (Object.getOwnPropertyNames(allScenarioTypeList).length !== 0) {
        this.search_draw(this.condition);
      } else {
        setTimeout(() => {
          if (!this.loadingService.getLoadingStatus()) {
            this.loadingService.setLoadConditionStatus();
          }
        });
      }
    });
  }

  /**
   * コンポネント破棄時処理
   */
  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
    this.searchSubscription.unsubscribe();
    this.stopElasticRequest();
  }

  /**
   * Elasticsearchリクエストを中止
   */
  stopElasticRequest(): void {
    if (this.elasticSubscription) {
      this.elasticSubscription.unsubscribe();
    }
  }

  /**
   * サーバ側でクエリを検索する処理
   * @param condition 検索条件
   */
  private search_draw(condition) {
    // Elasticsearchにクエリを発行
    const comp = this;
    const jsonConfig = scenarioStepJson;

    if (jsonConfig && jsonConfig.length !== 0) {
      const query: any = jsonConfig[0];

      // 取得する期間を指定
      query.body.query.bool.must[0].range = {
        '@timestamp': {
          'gte': condition.start,
          'lte': condition.end,
          'format': 'epoch_millis',
        },
      };

      // 端末の指定
      query.body.query.bool.must[1] = {'term' : {'strategy': condition.device}};

      // 取得するシナリオの指定
      query.body.query.bool.must.splice( 2, 2 );
      if (condition.scenarioType.length !== 0) {
        query.body.query.bool.must[2] = {'term' : {'scenarioConfigId': condition.scenarioType[0]}};
      }

      // Elasticsearchに対して検索
      this.elasticSubscription = comp.elasticsearchService.searchScenarioLineChartWithProxy(query, null, null)
        .subscribe(response => {
          if (!response.aggregations || response.aggregations.length === 0) {
            if (!comp.loadingService.getLoadingStatus()) {
              comp.loadingService.setLoadConditionStatus();
            }
            this.isNoData = true;
            return;
          }
          comp.parseData(response);

          if ('datasets' in this.data && this.data.datasets.length === 0) {
            if (!comp.loadingService.getLoadingStatus()) {
              comp.loadingService.setLoadConditionStatus();
            }
            this.isNoData = true;
            return;
          }

          comp.isShow = true;
          comp.isNoData = false;
          comp.draw_line();

          if (!comp.loadingService.getLoadingStatus()) {
            comp.loadingService.setLoadConditionStatus();
          }
        });
    }
  }

  /**
   * 検索結果のデータを利用し、各グラフデータを作成
   * @param resp 検索結果レスポンス
   */
  private parseData(resp) {
    // aggregation結果
    const buckets = resp.aggregations.step_aggs.buckets;
    const all_display_data = [];
    const tooltip_labels = [];
    let date;
    let bucket;
    let each_label = [];
    let each_datasets;
    let time_x;
    let value_y;
    let dataset_color;
    let each_data;
    const xlabels = [];
    let each_display_data = [];

    // 各ステップの値を取得
    for (let i = 0; i < buckets.length; i++) {
      bucket = buckets[i];
      each_label = bucket.key;                        // ステップ名
      each_datasets = bucket.time_aggs.buckets;

      each_display_data = [];

      // 各時間ごとの計測値取得
      for (let j = 0; j < each_datasets.length; j++) {
        time_x = each_datasets[j].key;                // 時間
        value_y = each_datasets[j].value_aggs.value;  // 計測値

        each_data = { x: time_x, y: value_y };
        each_display_data.push(each_data);

        date = moment(time_x);
        xlabels.push(date.format('M/D HH:mm'));
      }

      // 各ステップの色設定
      if (i < this.colors.length) {
        dataset_color = this.colors[i];
      } else {
        dataset_color = NbColorHelper.hexToRgbA(this.colors[Math.floor(Math.random() * this.colors.length)], 0.8);
      }

      // チャートに表示するデータを設定
      const each_dataset = {
        data: each_display_data,
        label: each_label,
        backgroundColor: dataset_color,
        borderColor: dataset_color,
        fill: false,
      };

      all_display_data.push(each_dataset);
      tooltip_labels.push(xlabels);
    }

    this.labelStr = 'Date';
    this.data = {
      datasets: all_display_data,
      tooltip_labels: tooltip_labels,
    };
  }

  /**
   * レスポンスをもとにグラフを描画する。
   */
  private draw_line() {
    if (!this.options) {
      const unit = this.unit;
      const dpoint = this.dpoint;
      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        animation:
        {
            duration: 0,
        },
        tooltips: {
          callbacks: {
            title: (tooltipItems, data) => {
              const date = moment(tooltipItems[0].xLabel);
              const text = date.format('M/D HH:mm');
              return text;
            },
            label: function (tooltipItem, data) {
              return this._data.datasets[tooltipItem.datasetIndex].label + ':'
                     + Math.floor(tooltipItem.yLabel * Math.pow(10, dpoint)) / Math.pow(10, dpoint) + ' ' + unit;
            },
          },
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                displayFormats: {
                  'millisecond': 'M/D HH:mm',
                  'second': 'M/D HH:mm',
                  'minute': 'M/D HH:mm',
                  'hour': 'M/D HH:mm',
                  'day': 'M/D HH:mm',
                  'week': 'M/D HH:mm',
                  'month': 'M/D HH:mm',
                  'quarter': 'M/D HH:mm',
                  'year': 'M/D HH:mm',
                },
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.labelStr,
                fontColor: this.chartjs.textColor,
              },
              ticks: {
                fontColor: this.chartjs.textColor,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: this.chartjs.axisLineColor,
              },
              scaleLabel: {
                display: true,
                fontColor: this.chartjs.textColor,
                labelString: unit,
              },
              ticks: {
                fontColor: this.chartjs.textColor,
                beginAtZero: true,
                maxTicksLimit: 10,
                suggestedMax: this.axisMax,
              },
            },
          ],
        },
        legend: {
          labels: {
            fontColor: this.chartjs.textColor,
          },
        },
      };
      this.isCompInit = true;
    }
  }
}
