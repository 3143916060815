import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxGalleryImage } from 'ngx-gallery';
import screenshotFilesJson from 'assets/query/pages/dashboard/screen-capture/screenshot_files.json';
import { SearchService } from '../../@core/data/search.service';
import { ElasticsearchService } from '../../@core/data/elasticsearch.service';
import { ScreenCaptureFilterService } from '../../@core/data/screencapture.filter.service';
import { LoadingService } from '../../@core/data/loading.service';

import { ScreenCaptureModalComponent } from './screen-capture-modal/screen-capture-modal.component';

/**
 * スクリーンキャプチャのレンダリングを行うコンポネント
 */
@Component({
  selector: 'ngx-screen-capture',
  templateUrl: './screen-capture.component.html',
  styleUrls: ['./screen-capture.component.scss'],
})
export class ScreenCaptureComponent implements OnDestroy, OnInit {
  /** グラフタイプ(HTML入力) */
  @Input() type: string = '';
  /** 検索用Subscribeオブジェクト */
  searchSubscription: any;
  /** スクリーンキャプチャ用Subscribeオブジェクト */
  screenSubscription: any;
  /** テーマ用Subscribeオブジェクト */
  themeSubscription: any;
  /** Elasticsearch用Subscribeオブジェクト */
  elasticSubscription: any;
  /** 検索条件 */
  condition: any = {};
  /** 対象サイト一覧 */
  targetSites: any[] = [];
  /** 表示用対象サイト一覧 */
  displayTargetSites: any[] = [];
  /** 画像表示時系列 */
  seconds: any[] = [];
  /** 画像表示ステップ */
  step: number = 5;
  /** Thumbnails用スクリーンショットのサイズ */
  screenshotSize: string = 'small';
  /** 画像表示ステップ設定 */
  stepOptions: any[] = [{ 'value': 1, 'description': '0.1秒間隔' },
  { 'value': 5, 'description': '0.5秒間隔' },
  { 'value': 10, 'description': '1秒間隔' }];
  /** 選択後スクリーンショット */
  selectedScreenshot: any;

  constructor(
    protected theme: NbThemeService,
    protected modalService: NgbModal,
    protected searchService: SearchService,
    protected elasticsearchService: ElasticsearchService,
    protected screencaptureFilterService: ScreenCaptureFilterService,
    protected loadingService: LoadingService,
  ) { }

  /**
   * コンポネント初期処理
   */
  ngOnInit(): void {
    // 検索条件が変わった際のイベントを登録
    this.searchSubscription = this.searchService.getConditionState().subscribe(res => {
      this.stopElasticRequest();
      this.condition = this.searchService.getCondition();
      this.search(this.condition);
    });

    // 表示ボタンがクリックされた際のイベントを登録
    this.screenSubscription = this.screencaptureFilterService.getDisplayTargetSitesState()
      .subscribe(displayTargetSites => {
        this.stopElasticRequest();
        this.displayTargetSites = displayTargetSites;
        this.showScreenshots();
      });

    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      this.condition = this.searchService.getCondition();
      // ヘッダ初期化されていなければ初期化を待つ。
      const allSiteNameMap = this.searchService.getAllSiteNameMap();
      if (Object.getOwnPropertyNames(allSiteNameMap).length !== 0) {
        this.search(this.condition);
      } else {
        setTimeout(() => {
          if (!this.loadingService.getLoadingStatus()) {
            this.loadingService.setLoadConditionStatus();
          }
        });
      }
    });
  }

  /**
   * コンポーネント破棄時処理
   */
  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
    this.screenSubscription.unsubscribe();
    this.themeSubscription.unsubscribe();
    this.stopElasticRequest();
  }

  /**
   * Elasticsearchリクエストを中止
   */
  stopElasticRequest(): void {
    if (this.elasticSubscription) {
      this.elasticSubscription.unsubscribe();
    }
  }

  /**
   * スクリーンショットのレンダリング処理
   */
  showScreenshots(): void {
    let numScreenshots: number = 0;

    this.displayTargetSites.forEach((targetSite, targetSiteIndex) => {
      this.displayTargetSites[targetSiteIndex].displayedScreenshots = [];
      targetSite.screenshotSize = this.screenshotSize;
      targetSite.screenshots.forEach((screenshotUrl, screenshotIndex) => {
        if (screenshotIndex % this.step === 0) {
          this.displayTargetSites[targetSiteIndex].displayedScreenshots.push({ 'source': screenshotUrl.source });
        } else if (targetSite.numOfScreen - 1 === screenshotIndex) { // 最後のキャプチャが格納されるようにする。
          this.displayTargetSites[targetSiteIndex].displayedScreenshots.push({ 'source': screenshotUrl.source });
        }
      });
      this.displayTargetSites[targetSiteIndex].elapsedTime = (0.1 * (targetSite.numOfScreen - 1)).toFixed(1);
      numScreenshots = Math.max(numScreenshots, targetSite.displayedScreenshots.length);
    });

    this.seconds = [];
    let elapsedTime;
    for (let i = 0; i < numScreenshots; i++) {
      elapsedTime = (i * 0.1 * this.step).toFixed(1);
      this.seconds.push({ 'value': `${elapsedTime}s` });
    }
  }

  /**
   * スクリーンショットを選択した際、画像プリビューのダイアログを開く
   * @param siteIndex 対象サイトのインデクス
   * @param timeIndex 対象時系列インデクス
   */
  selectImg(siteIndex, timeIndex) {
    const galleryImages: NgxGalleryImage[] = [];
    this.displayTargetSites.forEach((targetSite, targetSiteIndex) => {
      if (targetSiteIndex === siteIndex) {
        targetSite.displayedScreenshots.forEach((displayedScreenshot, ssIndex) => {
          galleryImages.push({
            small: displayedScreenshot.source,
            medium: displayedScreenshot.source,
            big: displayedScreenshot.source,
          });
        });
      }
    });
    this.open(galleryImages, timeIndex);
  }

  /**
   * 画像プリビューのダイアログを表示
   * @param galleryImages 対象画像プリビュー
   * @param timeIndex 対象時系列インデクス
   */
  open(galleryImages, timeIndex) {
    const modalRef = this.modalService.open(ScreenCaptureModalComponent, {
      windowClass: 'myModal',
    });

    modalRef.componentInstance.galleryImages = galleryImages;
    modalRef.componentInstance.galleryOptions = [
      {
        'imageSize': 'contain',
        'thumbnailSize': 'contain',
        'width': '800px',
        'height': '600px',
        'thumbnailsArrows': false,
        'arrowPrevIcon': 'fa fa-chevron-circle-left fa-inverse',
        'arrowNextIcon': 'fa fa-chevron-circle-right fa-inverse',
        'preview': false,
        'startIndex': timeIndex,
      },
      { 'breakpoint': 500, 'width': '300px', 'height': '300px', 'thumbnailsColumns': 3 },
      { 'breakpoint': 300, 'width': '100%', 'height': '200px', 'thumbnailsColumns': 2 },
    ];
  }

  /**
   * サーバ側でクエリを検索する処理
   * @param condition 検索条件
   */
  private search(condition) {
    // ESにクエリを発行
    const comp = this;
    let jsonConfig;
    jsonConfig = screenshotFilesJson;
    if (jsonConfig.length !== 0) {
      // const query: any = jsonConfig[0];
      const query: any = JSON.parse(JSON.stringify(jsonConfig[0])); // deep copy
      query.body.query.bool.must[0].range = {
        '@timestamp': {
          'gte': condition.start,
          'lte': condition.end,
          'format': 'epoch_millis',
        },
      };
      const strategyQuery = { 'term': { 'strategy': condition.device } };
      query.body.query.bool.must.push(strategyQuery);
      this.elasticSubscription = comp.elasticsearchService.searchScreenshotsWithProxy(query)
        .subscribe(response => {
          // 結果がない場合は処理を行わない。
          if (!response) {
            if (!comp.loadingService.getLoadingStatus()) {
              comp.loadingService.setLoadConditionStatus();
            }
            return;
          }
          comp.parseSearchData(response);
          if (!comp.loadingService.getLoadingStatus()) {
            comp.loadingService.setLoadConditionStatus();
          }
        },
      );
    }
  }

  /**
   * 検索結果のデータを利用し、スクリーンショットを表示
   * @param resp 検索結果レスポンス
   */
  private parseSearchData(resp: any) {
    this.targetSites = [];
    let pageName: string = '';
    let pageTitle;
    let targetSite: any;
    for (const [url, strategies] of Object.entries(resp)) {
      for (const [strategy, source] of Object.entries(strategies)) {
        pageName = '';
        for (const [key, value] of Object.entries(source)) {
          if (key === 'additionals.pageName') {
            pageName = <string>value;
          }
        }
        pageTitle = `${pageName}(${strategy})`;
        targetSite = {
          'id': `${url}_${strategy}`,
          'pageName': pageName,
          'pageTitle': pageTitle,
          'numOfScreen': source['presignedUrls'].length,
          'screenshots': [],
        };
        source['presignedUrls'].forEach((screenshotUrl) => {
          targetSite.screenshots.push({ 'source': screenshotUrl });
        });
        this.targetSites.push(targetSite);
      }
    }

    // サイト一覧を昇順でソートする。
    const sortedTargetList = JSON.parse(JSON.stringify(this.targetSites));
    sortedTargetList.sort(function (target1, target2) {
      if (target1.numOfScreen < target2.numOfScreen) {
        return 1;
      } else if (target1.numOfScreen > target2.numOfScreen) {
        return -1;
      } else {
        return 0;
      }
    });

    this.screencaptureFilterService.setSortedTargetSitesList(sortedTargetList);
    this.screencaptureFilterService.setTargetSitesState(this.targetSites);
  }
}
