import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { color } from "d3-color";

export interface PageStats {
  name: string;
  color: string;
  size: number;
  count: number;
  short?: string;
}
type devices = "mobile" | "desktop";
type type = "size" | "reqcount";
@Component({
  selector: "ngx-page-detail",
  templateUrl: "./page-detail.component.html",
  styleUrls: ["./page-detail.component.scss"],
})
export class PageDetailComponent implements OnInit {
  @Input() pageDetailData: any = [];
  pageStats: Array<PageStats> = [
    { name: "HTML", color: "#CE92B6", size: 0, count: 0 },
    { name: "JS", color: "#718394", size: 0, count: 0 },
    { name: "CSS", color: "#88A5BE", size: 0, count: 0 },
    { name: "IMG", color: "#5883A9", size: 0, count: 0 },
    // { name: "Video", color: "#153D61", size: 0, count: 0 },
    { name: "Font", color: "#926A9B", size: 0, count: 0 },
    { name: "Other", color: "#A1A1A1", size: 0, count: 0 },
  ];
  mobiledata = true;
  desktopdata = true;
  nodata = false;
  strategies = {
    mobile: [],
    desktop: [],
  };
  selectedDevices = [];
  totalSize = 0;
  totalRequest = 0;
  loadtime = 0;
  devices = [];
  constructor() { }
  ngOnInit() {
    this.pageDetailData.map(
      (detail) => detail.lighthouseReports !== undefined && detail.lighthouseReports[0].pageDetailData !== undefined && this.selectedDevices.push(detail.strategy));
    this.devices = [...this.selectedDevices];
    if (this.pageDetailData.length > 0) {
      this.pageDetailData.forEach((detaildata) => {
        if (detaildata.lighthouseReports !== undefined) {
          if (detaildata.lighthouseReports[0].pageDetailData !== null && detaildata.lighthouseReports[0].pageDetailData !== undefined) {
            //pageStats オブジェクトのクローンを作成して　レファレンスエラーを回避します
            let pageStats = [...this.pageStats.map((stat) => ({ ...stat }))];
            Object.keys(detaildata.lighthouseReports[0].pageDetailData).forEach(
              (data) => {
                //グラフで使用するオブジェクトを再構築する
                this.separateCategory(detaildata, data, pageStats);
              }
            );
            //ページ戦略に従ってページ詳細データを保存する
            this.separateStrategy(detaildata.strategy, pageStats)
          } else {
            this.checkData(detaildata.strategy);
          }
        } else {
          this.checkData(detaildata.strategy);
        }
      });
      if (this.mobiledata === false && this.desktopdata === false) {
        this.nodata = true;
      }
      else {
        this.nodata = false;
      }
    }
  }
  separateStrategy(strategy: string, pageStats: Array<PageStats>) {
    switch (strategy) {
      case "mobile":
        this.strategies.mobile = [...pageStats.map((stat) => stat)];
        break;
      case "desktop":
        this.strategies.desktop = [...pageStats.map((stat) => stat)];
        break;
    }
  }
  separateCategory(detaildata: any, data: string, pageStats: Array<PageStats>) {

    let pageDetailData = detaildata.lighthouseReports[0].pageDetailData[data];
    switch (data) {
      case "HTML":
        pageStats[0].count = pageDetailData.count;
        pageStats[0].size = pageDetailData.size;
        break;
      case "CSS":
        pageStats[2].count = pageDetailData.count;
        pageStats[2].size = pageDetailData.size;
        break;
      case "JS":
        pageStats[1].count = pageDetailData.count;
        pageStats[1].size = pageDetailData.size;
        break;
      case "IMG":
        pageStats[3].count = pageDetailData.count;
        pageStats[3].size = pageDetailData.size;
        break;
      case "Font":
        pageStats[4].count = pageDetailData.count;
        pageStats[4].size = pageDetailData.size;
        break;
      case "Other":
        pageStats[5].count = pageDetailData.count;
        pageStats[5].size = pageDetailData.size;
        break;
      case "totalSize":
        this.totalSize = pageDetailData;
        break;
      case "totalDuration":
        this.loadtime = pageDetailData;
        break;
      case "totalCount":
        this.totalRequest = pageDetailData;
        break;
    }

  }
  //グラフのために　必要なオブジェクト　を出力する　機能。
  generateGraphOption(
    totalSize: number,
    totalCount: number,
    device: devices,
    type: type
  ) {
    let selectedDevice: PageStats[];
    if (device === "desktop") {
      selectedDevice = this.strategies.desktop;
    } else {
      selectedDevice = this.strategies.mobile;
    }
    return {
      tooltip: {
        transitionDuration: 0,
        padding: [5, 7],
        textStyle: {
          color: '#000000'
        },
        backgroundColor: '#ffffff',
        extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);",
        formatter: (params: any) => {
          return this.Formatter(
            params,
            type,
            selectedDevice,
            totalCount,
            totalSize
          );
        },
        // axisPointer: {
        //   // Use axis to trigger tooltip
        //   type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        // },
      },
      grid: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      barWidth: "1000",
      legend: {},
      xAxis: {
        show: false,
        type: "value",
        max: type === "size" ? totalSize : totalCount,
      },
      color: selectedDevice.map((e) => e.color),
      yAxis: {
        show: false,
        type: "category",
      },
      series: selectedDevice.map((e) => ({
        name: e.name,
        type: "bar",
        stack: "total",
        label: { show: true },
        emphasis: { focus: "series" },
        data: [type === "size" ? e.size : e.count],
        animation: false
      })),
    };
  }
  //グラフの　ツールチップ　を出力する　Formatter　機能。
  //ページのさいページのさいサイズを　計て　MB とか　KB とか　出力する。
  Formatter(
    params: any,
    option: type,
    selectedDevice: PageStats[],
    totalCount: number,
    totalSize: number
  ) {
    let stat: PageStats[] = selectedDevice.filter(
      (e) => e.name === params.seriesName
    );
    let text = `
    <b>${params.seriesName}</b><br>
    <b>${option === "size"
        ? params.data.toString().length > 6
          ? (params.data / 1000 / 1000).toFixed(2) + "MB"
          : (params.data / 1000).toFixed(2) + "KB"
        : stat[0].size.toString().length > 6
          ? (stat[0].size / 1000 / 1000).toFixed(2) + "MB"
          : (stat[0].size / 1000).toFixed(2) + "KB"
      }</b> of ${totalSize.toString().length > 6 ? (totalSize / 1000 / 1000).toFixed(2) + "MB" : (totalSize / 1000).toFixed(2) + "KB"}Size<br>
    <b>${option === "size"
        ? ((params.data / totalSize) * 100).toFixed(2)
        : ((stat[0].size / totalSize) * 100).toFixed(2)
      }%</b> of Total Page Size<br>
    <br>
    <b>${option === "size" ? stat[0].count : params.data
      }</b> of ${totalCount} requests<br>
    <b>${option === "size"
        ? ((stat[0].count / totalCount) * 100).toFixed(2)
        : ((params.data / totalCount) * 100).toFixed(2)
      }%</b> of Total Page Requests<br>
    `;
    return text;
  }
  trackByName(index: number, item: PageStats): string {
    return item.name;
  }
  get defaultdevices() {
    return this.devices;
  }
  checkData(type: devices) {
    switch (type) {
      case 'mobile':
        this.mobiledata = false;
        break;
      case 'desktop':
        this.desktopdata = false;
        break;
    }
  }
}