import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { ScenarioDetailService } from 'app/@core/data/scenario-detail.service';
import * as moment from 'moment';

@Component({
  selector: 'ngx-pageloadtime',
  templateUrl: './pageloadtime.component.html',
  styleUrls: ['./pageloadtime.component.scss']
})
export class PageloadtimeComponent implements OnInit {

  @Output() setLoading = new EventEmitter<boolean>();

  @Input() pageDetailData: any;

  @Input() selectedScenario: any;

  @Input() selectedDevices: any;

  defaultPeriodDifferencial='10080';

  selectedCondition: string = '';

  showDatepicker = 'close_datepicker';

  showPeriod: string = '期間test';

  constructor(private theme: NbThemeService, private scenarioDetailService: ScenarioDetailService
  ) { }
  onGetLoading(isLoading) {
    this.setLoading.emit(isLoading);
  }
  ngOnInit() {
    if (this.defaultPeriodDifferencial === 'custom') {
      const condition = JSON.parse(sessionStorage.getItem('condition'));
      if (condition === null) {
        this.displaySelectionPeriod({
          start: moment().subtract(parseInt('4320', 10), 'minute'),
          end: moment(),
        });
      } else {
        this.displaySelectionPeriod({
          start: moment(condition.start),
          end: moment(),
        });
      }
    } else {
      this.displaySelectionPeriod({
        start: moment().subtract(parseInt(this.defaultPeriodDifferencial, 10), 'minute'),
        end: moment(),
      });
    }
    this.selectedCondition = this.defaultPeriodDifferencial;
  }
  onConditionSelect() {
    this.onGetLoading(true);
    if (this.selectedCondition === 'custom') {
      this.showDatepicker = '';
    } else {
      this.showDatepicker = 'close_datepicker';
      const period = {
        start: moment().subtract(parseInt(this.selectedCondition, 10), 'minute').valueOf(),
        end: moment().valueOf(),
      };
      this.scenarioDetailService.setPageLoadTime(period, this.selectedScenario);
      this.displaySelectionPeriod({
        start: moment().subtract(parseInt(this.selectedCondition, 10), 'minute'),
        end: moment(),
      });
    }
    this.onGetLoading(false);
  }
  closeDatepicker() {
    this.showDatepicker = 'close_datepicker';
  }
  displaySelectionPeriod(selectionPeriod: any) {
    this.showPeriod = selectionPeriod.start.format('YYYY/MM/DD') + '~' + selectionPeriod.end.format('YYYY/MM/DD');
  }
  setTimelineMetrics(period: any) {
    this.showDatepicker = 'close_datepicker';
    this.onGetLoading(true);
    this.scenarioDetailService.setPageLoadTime(period, this.selectedScenario);
    this.onGetLoading(false);
  }
}
